import React, { useState, useEffect, useMemo, useRef } from 'react';
import Select from 'react-select';
import { Modal, Form, Table, Button, Dropdown, Pagination, Row, Col } from 'react-bootstrap';
import { useAuth } from './store/AuthContext';
import { useUserData } from './store/UserDataContext';
import { useNavigate } from 'react-router-dom';
import { backendURL, backendWS } from './IPaddress';
import './CSS/CRUDfiles.css';
import NavbarComponent from './Nav';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ErrorModalMessage from './store/ErrorMessage';
import { useSchoolYear } from './store/SchoolYearCalculator';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import EmailModal from './store/EmailModal';


const EventTemplateComponent = () => {
    const [formSubmissions, setFormSubmissions] = useState([]);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        student_name: '',
        teacher_status: false,
        cactus_number: '',
        school_id: '',
        district: '',
        event_program_id: '',
        event_date_id: '',
        grade_id: '',
        additional_contact: false,
        street: '',
        city: '',
        state: '',
        zip: '',
        names_to_sit_by: '',
        cactus_number_verified: null
    });
    const [showForm, setShowForm] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [submissionToDelete, setSubmissionToDelete] = useState(null);
    const { authState } = useAuth();
    const { userData } = useUserData();
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [grades, setGrades] = useState([]);
    const [schools, setSchools] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [eventPrograms, setEventPrograms] = useState([]);
    const [eventDates, setEventDates] = useState([]);
    const [ ticketStatus, setTicketStatus ] = useState([])
    const [loading, setLoading] = useState({
        grades: false,
        schools: false,
        eventPrograms: false,
        eventDates: false
    });
    const [error, setError] = useState({
        grades: null,
        schools: null,
        eventPrograms: null,
        eventDates: null
    }); 
    const [selectedEventProgram, setSelectedEventProgram] = useState(null);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [privateSchoolModal, setPrivateSchoolModal] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [homeSchoolModal, setHomeSchoolModal] = useState(false);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [ineligibleGradeModal, setIneligibleGradeModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [ recordStatuses, setRecordStatuses ] = useState([]);
    const [lastModifiedBy, setLastModifiedBy] = useState('');
    const [usersNames, setUsersNames] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [ showTicketInfoMod, setShowTicketInfoMod ] = useState(false);
    const [ showTicketsNotesMod, setShowTicketNotesMod ] = useState(false);
    const [ showTicketEmailMod, setShowTicketEmailMod ] = useState(false);
    const [ ticketToEdit, setTicketToEdit ] = useState(null);
    const [ ticketForm, setTicketForm ] = useState({
        app_user_id: '',
        event_date_id: '',
        notes: '',
        order_number: '',
        seat_number: '',
        status_type: '',
        ticket_id: '',
        ticket_mailed: false,
        ticket_pulled: false
    })
    const [ submissionLastModified, setSubmissionLastModified ] =useState(null);
    const [ws, setWs] = useState(null);
    const [ showInsufficientPrivileges, setShowInsufficientPrivileges ] = useState(false);
    const scrollableTableRef = useRef(null);
    const currentScrollPosition = scrollableTableRef.current?.scrollTop;
    const tableHeadings = [ 
        "Name",
        "Ticket Info",
        "School (District) Info",
        "Contact Info",
        "Ticket Contact Status",
        "Record Status",
        "Submitted Date",
        "Ticket Status",
        "Teachers",
        "Cactus Unverified"
        ]
    const [selectedSearchTerm, setSelectedSearchTerm] = useState('');
    const [USAstates, setUSAstates] = useState([]);
    const [errorMessage, setErrorMessage ] = useState(null);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const {currentYear, schoolYearListParent} = useSchoolYear();
    const [selectedSchoolYear, setSelectedSchoolYear] = useState(currentYear);
    const recordsPerPage = 100;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [allFormSubmissions, setAllFormSubmissions] = useState([]);
    const [displayedFormSubmissions, setDisplayedFormSubmissions] = useState([]);
    const [filteredSubmissions, setFilteredSubmissions] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [showEmailModal, setShowEmailModal ] = useState(false);
    const [emailList, setEmailList ] = useState([]);




    // Redirect to login if not authenticated
    useEffect(() => {
        if (!authState) {
            navigate('/login');
        }
    }, [authState, navigate]);

    useEffect(() => {
        if (currentYear && schoolYearListParent.length) {
            setSelectedSchoolYear(typeof currentYear === "object" && currentYear !== null && "id" in currentYear ? currentYear.id : currentYear);
            setSchoolYearList(schoolYearListParent);
        }
    }, [currentYear, schoolYearListParent]);

    useEffect(() => {
        if (selectedSchoolYear) {
            fetchEventPrograms();
            fetchEventDates();
            fetchTickets();
            fetchFormSubmissions();
        }
    }, [selectedSchoolYear]);

    useEffect(() => {
        fetchUSAStates();
        fetchRecordStatuses();
        fetchTicketStatus();
        fetchGrades();
        fetchSchools();
        fetchDistricts();
        fetchUserNames();
    }, []);

    useEffect(() => {
        updateDisplayedFormSubmissions(currentPage, filteredSubmissions);
        const emailList = [...new Set(filteredSubmissions.map(submission => submission.email))];
        setEmailList(emailList);
    }, [currentPage, filteredSubmissions]);

    useEffect(() => {
        filterAndPaginate(allFormSubmissions, searchQuery, 1, selectedSearchTerm, startDate, endDate);
    }, [searchQuery, selectedSearchTerm, allFormSubmissions, tickets, schools, recordStatuses, startDate, endDate]);

    const fetchGrades = async () => {
        const url = `${backendURL}/api/grades`
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();

          if (Array.isArray(data)) {
            setGrades(data);
        } else {
            console.error('Fetched grades data is not an array:', data);
        }}
      };
      const fetchTicketStatus = async () => {
        const url = `${backendURL}/api/ticket-statuses`
        const response = await fetch(url, {
          method: 'GET',
          credentials: 'include'
      })
        if (response.ok) {
          const data = await response.json();
          // console.log("Ticket data was fetched: ", data)

          if (Array.isArray(data)) {
            setTicketStatus(data);
        } else {
            console.error('Fetched ticket statuses data is not an array:', data);
        }}
      };

      const fetchDistricts = async () => {
        const url = `${backendURL}/api/districts`;
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          setDistricts(data);
        }
      }

      const fetchSchools = async () => {
        setLoading(prev => ({ ...prev, schools: true }));
        const response = await fetch(`${backendURL}/api/schools`);
        
        if (!response.ok) throw new Error('Failed to fetch schools.');
        const data = await response.json();
        
        if (Array.isArray(data)) {
          setSchools(data);
      } else {
          console.error('Fetched Schools data is not an array:', data);

      }}

      const fetchEventPrograms = async () => {
          try {
              setLoading(prev => ({ ...prev, eventPrograms: true }));
              const response = await fetch(`${backendURL}/api/events-programs/year/${selectedSchoolYear}`);
              if (!response.ok) throw new Error('Failed to fetch event programs.');
              let data = await response.json();

              // Filtering the data for event_type_id of 4
              data = data.filter(eventProgram => eventProgram.event_type_id === 4);
              setEventPrograms(data);
          } catch (err) {
              setError(prev => ({ ...prev, eventPrograms: err.message }));
          } finally {
              setLoading(prev => ({ ...prev, eventPrograms: false }));
          }
      };

      const fetchEventDates = async () => {
        try {
            const response = await fetch(`${backendURL}/api/event-dates/year/${selectedSchoolYear}`);
            if (!response.ok) throw new Error('Failed to fetch event dates.');
            const data = await response.json();
            setEventDates(data);

        } catch (err) {
            console.error(err.message);
        }
      };
      const fetchRecordStatuses = async () => {
          try {
              const response = await fetch (`${backendURL}/api/record-status/`, {
                  method: "GET",
                  credentials: "include"
              });
              if (!response.ok) throw new Error ('Failed to fetch record statuses');
              const data = await response.json();
              // console.log("record data: ", data);
              setRecordStatuses(data);
          } catch (error) {
              console.error(error.message);
          }
      }
      const fetchUserNames = async () => {
          try {
              const response = await fetch (`${backendURL}/api/app-users`,{
                  method: 'GET',
                  credentials: 'include'
                });
              const data = await response.json();
              if(!response.ok) throw new Error ('Failed to fetch user data')
              // console.log("Users Data: ",data)
              setUsersNames(data);
          } catch (err) {
              console.error(err.message);
          }
      }
      const fetchUSAStates = async () => {
          try {
              const response = await fetch(`${backendURL}/api/states`, {
                  method: 'GET'
              })
              if (!response.ok) {
                  setErrorMessage('Unable to retrieve list of USA states.')
              }
              const data = await response.json();
              setUSAstates(data);
          } catch{
              setErrorMessage('Failed to retrieve list of USA states.')
          }
        };

        const fetchFormSubmissions = async () => {
            try {
                const response = await fetch(`${backendURL}/api/form-submissions/year/${selectedSchoolYear}`,
                     { method: 'GET', credentials: 'include' });
                const data = response.ok ? await response.json() : [];
                setAllFormSubmissions(data);
                setFormSubmissions(data);
                filterAndPaginate(data, searchQuery, currentPage, selectedSearchTerm, startDate, endDate);
            } catch {
                console.error('Error fetching form submissions');
            }
        };
        
        
    
        const fetchTickets = async () => {
            try {
                // if(!selectedSchoolYear === undefined){
                const response = await fetch (`${backendURL}/api/tickets/year/${selectedSchoolYear}`,{
                    method: 'GET',
                    credentials: 'include',
                });
                const data = await response.json();
                if(!response.ok) throw new Error ('Failed to fetch ticket data');
                setTickets(data);
            // }
            } catch (error){
                console.error(error.message);
            }
        }

        const updateDisplayedFormSubmissions = (page, submissions) => {
            const startIndex = (page - 1) * recordsPerPage;
            const endIndex = startIndex + recordsPerPage;
            setDisplayedFormSubmissions(submissions.slice(startIndex, endIndex));
        };

    useEffect(() => {
        if (scrollableTableRef.current && currentScrollPosition !== undefined) {
            scrollableTableRef.current.scrollTop = currentScrollPosition;
        }
    }, [allFormSubmissions, tickets]);

    useEffect(() => {

        const webSocket = new WebSocket(`${backendWS}/ws`); // Adjust URL to your WebSocket endpoint
        setWs(webSocket);

        webSocket.onopen = () => {
            console.log('WebSocket connection established');
        };

        webSocket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            switch (message.type) {
                case 'create':
                    // Add the new item to the state
                    fetchTickets();
                    fetchFormSubmissions();
                    break;
                case 'update':
                    if (message.data.hasOwnProperty('submission_id')) {
                        // Update a form submission
                        setAllFormSubmissions(prevSubmissions => prevSubmissions.map(submission => {
                            if (submission.submission_id === message.data.submission_id) {
                                // Found the submission to update
                                return { ...submission, ...message.data };
                            }
                            return submission;
                        }));
                    } else if (message.data.hasOwnProperty('ticket_id')) {
                        // Update a ticket
                        setTickets(prevTickets => prevTickets.map(ticket => {
                            if (ticket.ticket_id === message.data.ticket_id) {
                                // Found the ticket to update
                                return { ...ticket, ...message.data };
                            }
                            return ticket;
                        }));
                    }
                    break;
                case 'delete':
                    // Remove the item from the state
                    fetchTickets();
                    fetchFormSubmissions();
                    break;
              default:
                console.error('Unhandled message type:', message.type);
            }
          };

        webSocket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        webSocket.onclose = () => {
            console.log('WebSocket connection closed');
        };

        return () => {
            webSocket.close(); // Clean up connection on component unmount
        };
    }, []);

    useEffect(() => {
        // console.log("selected event and is editing")
        if (selectedEventProgram && isEditing) {
            // Find the event date from the formData and set it
            const eventDate = eventDates.find(date => date.event_dates_id === formData.event_date_id);

            if (eventDate) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    event_date_id: eventDate.event_dates_id
                }));
            }
        }
    }, [selectedEventProgram, eventDates, formData.event_date_id, isEditing]);
    
    
    const schoolOptions = schools.map(school_id => {
        const district = districts.find(d => d.district_id === school_id.district_id);
        const districtName = district ? district.name : 'Unknown District';
        
        return {
          label: `${school_id.name} (${districtName})`,
          value: school_id.school_id
        };
    });

    const relevantEventDates = useMemo(() => {
        if (!selectedEventProgram) return [];
  
        return eventDates
          .filter(event_date_id => event_date_id.event_id === Number(selectedEventProgram.value))
          .map(event_date_id => {
              // Parse the start_time and create a Date object. Assuming start_time is in 'HH:mm:ss' format
              const timeParts = event_date_id.start_time.split(':');
              const date = new Date();
              date.setHours(parseInt(timeParts[0], 10), parseInt(timeParts[1], 10), parseInt(timeParts[2], 10));
            
              // Format the date object to a 12-hour time with AM/PM
              const formattedTime = date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              });
                      
              return {
                label: `${event_date_id.date} ${formattedTime}`,
                value: event_date_id.event_dates_id
              };
            });
            
      }, [eventDates, selectedEventProgram]);
    // console.log("relevantEventDates list: ", relevantEventDates)

    const handleEventProgramChange = selectedOption => {
        setSelectedEventProgram(selectedOption);
        setFormData(prevFormData => ({
          ...prevFormData,
          event_program_id: selectedOption ? parseInt(selectedOption.value, 10) : null,
          event_date_id: isEditing ? parseInt(prevFormData.event_date_id, 10) : null // Reset only if not editing
        }));
    };

    const handleShow = () => setShowEmailModal(true);
    const handleClose = () => {
        setShowEmailModal(false)
        setErrorMessage('');
    };

    const handleEventDateChange = (selectedOption) => {
        setFormData({
            ...formData,
            event_date_id: selectedOption ? selectedOption.value : null
        });
    };

    const handleSchoolChange = selectedOption => {
        setSelectedSchool(selectedOption);
        let schoolId = null;
        if (selectedOption) {
            schoolId = parseInt(selectedOption.value, 10);
            if (isNaN(schoolId)) {
                console.warn(`Warning: could not parse school_id value "${selectedOption.value}" as an integer`);
                schoolId = null;
                };
            };
        
        setFormData({
            ...formData,
            school_id: schoolId
            });

        const selectedSchoolObj = schools.find(school_id => school_id.school_id === schoolId);

        if (selectedOption && selectedOption.label.includes("(Private)")) {
            setPrivateSchoolModal(true);
            setDisableSubmit(true);
            // Optionally, you may clear the form and selections here
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                teacher_status: false,
                cactus_number: '',
                school_id: '',
                district: '',
                event_program_id: '',
                event_date_id: '',
                grade_id: '',
                additional_contact: false,
                street: '',
                city: '',
                state: '',
                zip: '',
                names_to_sit_by: '',
                cactus_number_verified: null
            });
            setSelectedSchool(null);
            setSelectedEventProgram(null);}

        else if (selectedSchoolObj && selectedSchoolObj.name === "Home-School") {
            setHomeSchoolModal(true);
            setDisableSubmit(true);
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                teacher_status: false,
                cactus_number: '',
                school_id: '',
                district: '',
                event_program_id: '',
                event_date_id: '',
                grade_id: '',
                additional_contact: false,
                street: '',
                city: '',
                state: '',
                zip: '',
                names_to_sit_by: '',
                cactus_number_verified: null
            });
            setSelectedSchool(null);
            setSelectedEventProgram(null);
        } else {
            setDisableSubmit(false);
        }
    
        if (selectedSchoolObj)  {
            // Find the corresponding district object
            const correspondingDistrict = districts.find(district => district.district_id === selectedSchoolObj.district_id);
        
        if (correspondingDistrict) {
            // Update the district in the state
            setSelectedDistrict(correspondingDistrict);

            // Update the district in the formData
            setFormData(prevFormData => ({
                ...prevFormData,
                district: correspondingDistrict.district_id
            }));
        }
      }
    };

    const handleGradeChange = (e) => {
        const gradeValueStr = e.target.value;
        let gradeValue = parseInt(gradeValueStr, 10);
        
        // Check if the parsed value is NaN and handle accordingly
        if (isNaN(gradeValue)) {
            console.warn(`Warning: could not parse grade value "${gradeValueStr}" as an integer`);
            gradeValue = null; // or handle differently based on your use-case
        }
        setFormData({
            ...formData,
            grade_id: gradeValue
        });
    };

    const handleFormChange = (e) => {
        setFormData({
             ...formData, [e.target.name]: e.target.value,
            school_year_id: selectedSchoolYear, });
    };
    const handleTicketFormChange = (e) => {
        setTicketForm({ ...ticketForm, [e.target.name]: e.target.value });
    };

    const handleTicketUpdate = async (e) => {
        e.preventDefault();
        if (ticketForm.ticket_id) {
           await updateTicketForm(ticketForm.ticket_id, ticketForm, submissionLastModified);
        } else {
            console.error("Failed to update ticket data.");
        }

        setTicketForm({
            app_user_id: '',
            event_date_id: '',
            notes: '',
            order_number: '',
            seat_number: '',
            status_type: '',
            ticket_id: '',
            ticket_mailed: false,
            ticket_pulled: false
        });
        setShowTicketInfoMod(false);
        setShowTicketNotesMod(false);
        setShowTicketEmailMod(false);
        setTicketToEdit(null);
        setLastModifiedBy(userData.user_id)
        fetchTickets();
        fetchFormSubmissions();
    };

    const updateTicketForm = async (ticketId, ticketForm, submission) => {
        await fetch (`${backendURL}/api/tickets/${ticketId}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(ticketForm),
            credentials: 'include'
        })
        const updatedSubmission = { 
            ...submission,
            last_modified_by: userData.user_id
         };
        try {
            const res = await fetch(`${backendURL}/api/form-submissions/${submission.submission_id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedSubmission),
                credentials: 'include'
            });
            if (!res.ok) {
                throw new Error('Failed to update last modified by');
            }
        } catch(error) {
            console.error("Error updating last modified by on submission")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.submission_id) {
            const updatedSubmission = { 
                ...formData,
                last_modified_by: userData.user_id
             };
            await updateFormSubmission(formData.submission_id, updatedSubmission);
        } else {
            await createFormSubmission(formData);
        }
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            teacher_status: false,
            cactus_number: '',
            school_id: '',
            district: '',
            event_program_id: '',
            event_date_id: '',
            grade_id: '',
            additional_contact: false,
            street: '',
            city: '',
            state: '',
            zip: '',
            names_to_sit_by: '',
            cactus_number_verified: null
        });
        // console.log("form data cleared")
        setShowForm(false);
        setSelectedSchool(null);
        setIsEditing(false);
        fetchFormSubmissions();
        fetchTickets();
    };

    const filterEventDates = (eventDates, startDate, endDate, startTime, endTime) => {
        return eventDates.filter(eventDate => {
            const eventDateTime = new Date(eventDate.date);
            const isWithinDateRange = (!startDate || eventDateTime >= new Date(startDate)) && (!endDate || eventDateTime <= new Date(endDate));
            const isWithinTimeRange = (!startTime || eventDate.start_time >= startTime) && (!endTime || eventDate.end_time <= endTime);
            return isWithinDateRange && isWithinTimeRange;
        });
    };
            
    const filterAndPaginate = (allSubmissions, searchQuery, page, selectedSearchTerm, startDate, endDate) => {
        const searchQueryLowercase = searchQuery.toLowerCase();
        const filteredEventDates = filterEventDates(eventDates, startDate, endDate, startTime, endTime);

        const filteredSubmissions = allSubmissions.filter(submission => {
            const isEventDateMatched = filteredEventDates.some(
                eventDate => eventDate.event_dates_id === submission.event_date_id);

            const matchesSearchTerm = (() => {
                switch (selectedSearchTerm) {
                    case "Name":
                        return (submission.first_name?.toLowerCase().includes(searchQueryLowercase) ?? false) || (submission.last_name?.toLowerCase().includes(searchQueryLowercase) ?? false);
                    case "Ticket Info":
                        return tickets.some(ticket => (ticket.seat_number?.toLowerCase().includes(searchQueryLowercase) ?? false) || (ticket.order_number?.toLowerCase().includes(searchQueryLowercase) ?? false) && ticket.ticket_id === submission.ticket_id);
                    case "School (District) Info":
                        return schools.some(school => (
                            school.name?.toLowerCase().includes(searchQueryLowercase) ?? false) &&
                             school.school_id === submission.school_id);
                    case "Contact Info":
                        return (submission.email?.toLowerCase().includes(searchQueryLowercase) ?? false) || (submission.phone_number?.toLowerCase().includes(searchQueryLowercase) ?? false);
                    case "Record Status":
                        return recordStatuses.some(status => (
                            status.status?.toLowerCase().includes(searchQueryLowercase) ?? false) && 
                            status.status_id === submission.record_status_id);
                    case "Submitted Date":
                        return (submission.submitted_date?.toLowerCase().includes(searchQueryLowercase) ?? false);
                    case "Ticket Status":
                        return tickets.some(ticket => (
                            String(ticket.ticket_pulled)?.toLowerCase().includes(searchQueryLowercase) || 
                            String(ticket.ticket_mailed)?.toLowerCase().includes(searchQueryLowercase)) &&
                            ticket.ticket_id === (submission.ticket_id));
                    case "Ticket Contact Status":
                        const ticketStatusObj = lookupTicketStatus(submission.ticket_id)
                        return ticketStatus.some(status => (                           
                            (status.name?.toLowerCase().includes(searchQueryLowercase)) ?? false)
                            &&
                            status.ticket_status_id === ticketStatusObj.status_type);
                    case "Teachers":
                        return submission.teacher_status === true && ((submission.first_name?.toLowerCase().includes(searchQueryLowercase) ?? false) || (submission.last_name?.toLowerCase().includes(searchQueryLowercase) ?? false));
                    case "Cactus Unverified":
                        return submission.cactus_number_verified == null && submission.teacher_status === true && ((submission.first_name?.toLowerCase().includes(searchQueryLowercase) ?? false) || (submission.last_name?.toLowerCase().includes(searchQueryLowercase) ?? false));
                    default:
                        return true;
                }
            })();

            return matchesSearchTerm && isEventDateMatched;

        }).sort((a, b) => a.record_status_id !== 5 && b.record_status_id === 5 ? -1 : b.submission_id - a.submission_id);
        setFilteredSubmissions(filteredSubmissions);
        updateDisplayedFormSubmissions(page, filteredSubmissions);
        setTotalPages(Math.ceil(filteredSubmissions.length / recordsPerPage));
    };
        
    
    

        const createFormSubmission = async (formData) => {
            await fetch(`${backendURL}/api/form-submissions`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
                credentials: 'include'
            });
        };

    const updateFormSubmission = async (submissionId, formData) => {

        await fetch(`${backendURL}/api/form-submissions/${submissionId}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
            credentials: 'include'
        });
    };

    const deleteEventTemplate = async () => {
        if (submissionToDelete) {
            try {
                const response = await fetch(`${backendURL}/api/form-submissions/${submissionToDelete}`, {
                    method: 'DELETE',
                    credentials: 'include'
                });
                if (!response.ok){
                    // console.log("insufficient credentials")
                    setShowInsufficientPrivileges(true);
                }
                
            }
            catch{
                console.error("Deleting the form submission failed.")
            }
            
            setSubmissionToDelete(null);
            setShowModal(false);
            fetchFormSubmissions();
            fetchTickets();
        }
    };
    
    const editSubmission = (submission) => {
        setIsEditing(true);
        setFormData(submission);

        //finds the proper event program object from the select list in the form and then sets it if it is true. 
        const programObject = eventPrograms.find(program => program.event_id === submission.event_program_id);
        if (programObject) {
        setSelectedEventProgram({ value: programObject.event_id, label: programObject.name });
        }
    
        const schoolObject = schoolOptions.find(school => school.value === submission.school_id);
        setSelectedSchool(schoolObject);

        setShowForm(true);
    }; 

    const editTicketOrder = (ticket, submission) => {
        setShowTicketInfoMod(true);
        setSubmissionLastModified(submission);
        setTicketToEdit(ticket);
        setTicketForm(ticket);
    } 

    const editTicketNotes = (ticket, submission) => {
        setShowTicketNotesMod(true);
        setSubmissionLastModified(submission);
        setTicketToEdit(ticket);
        setTicketForm(ticket);
    } 

    const editTicketEmailInfo = (ticket, submission) => {
        setShowTicketEmailMod(true);
        setSubmissionLastModified(submission);
        setTicketToEdit(ticket);
        setTicketForm(ticket);
    }

    const handleCancel = () => {
        setIsEditing(false);
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            teacher_status: false,
            cactus_number: '',
            school_id: '',
            district: '',
            event_program_id: '',
            event_date_id: '',
            grade_id: '',
            additional_contact: false,
            street: '',
            city: '',
            state: '',
            zip: '',
            names_to_sit_by: '',
            cactus_number_verified: null
        });
        setSelectedSchool(null);
        setShowForm(false);
    };

    const handleEdit = async (submissionId, field, value, submission) => {
        // Update the submission object with the new field value
        const updatedSubmission = { 
            ...submission, 
            [field]: value,
            last_modified_by: userData.user_id
         };
        //  console.log("Submission ID: ", submissionId, "Field: ", field, "Value: ", value, "Submission: ", submission)
    
        try {
            const response = await fetch(`${backendURL}/api/form-submissions/${submissionId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedSubmission),
                credentials: 'include'
            });
            
            if (!response.ok) {
                throw new Error('Failed to update submission');
            }
    
            // Handle the response, maybe refetch data or show success message
        } catch (error) {
            console.error('Error updating submission: ', error);
            // Handle error, show error message
        }
        // fetchFormSubmissions();
    };

    const handleTicketStatusEdit = async (ticketStatusId, ticket, submission) => {
        const updatedTicket = {
            ...ticket,
            status_type: parseInt(ticketStatusId, 10) // Convert ticketStatusId to an integer
        };
        const updatedSubmission = { 
            ...submission,
            last_modified_by: userData.user_id
         };
        try {
            const res = await fetch(`${backendURL}/api/form-submissions/${submission.submission_id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatedSubmission),
                credentials: 'include'
            });
            if (!res.ok) {
                throw new Error('Failed to update last modified by');
            }
        } catch(error) {
            console.error("Error updating last modified by on submission")
        }
        try {
            const response = await fetch(`${backendURL}/api/tickets/${ticket.ticket_id}`, { // Make sure to use ticket.ticket_id instead of ticket
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(updatedTicket),
                credentials: 'include'
            });
            if (!response.ok) throw new Error('Failed to update ticket status');
        } catch (error) {
            console.error('Error updating the ticket status: ', error);
        }
        // fetchFormSubmissions();
        // fetchTickets();
    };

    const HandleUpdateCactusVerification = async (submission, submissionId) => {
        let newCactusNumberVerified;
        
        if (submission.cactus_number_verified === null) {
            newCactusNumberVerified = true;
        } else if (submission.cactus_number_verified === true) {
            newCactusNumberVerified = false;
        } else if (submission.cactus_number_verified === false) {
            newCactusNumberVerified = null;
        }
        
        const updatedSubmission = {
            ...submission,
            cactus_number_verified: newCactusNumberVerified
        };
        
        try {
            const response = await fetch(`${backendURL}/api/form-submissions/${submissionId}`, {
                method: "PUT",
                headers: { 'Content-Type' : 'application/json' },
                body: JSON.stringify(updatedSubmission),
                credentials: "include"
            });
            const data = await response.json();
        } catch {
            console.log("something broke...");
        }
        fetchFormSubmissions();
    };
    
    
    const getLastModifiedName = (userId) => {
        const nameObject = usersNames.find(user => user.user_id === userId);
        return nameObject ? (`${nameObject.first_name} ${nameObject.last_name}`) : 'Unclaimed';
    }

    const getSchoolName = (schoolId) => {
        const schoolObject = schoolOptions.find(school => school.value === schoolId); 
        return schoolObject ? schoolObject.label : 'Unknown';
    }

    const getRecordStatusColor = (recordId) => {
        const recordObject = recordStatuses.find(record => record.status_id === recordId);
        // Log the color if the recordObject is found
        if (recordObject) {
            return recordObject.color; // Return only the color value
        }
        return 6; // Return a default color if not found
    };
    
    const getTicketStatusColor = (ticketId) => {
        try {const ticketObj = getTicketObj(ticketId);
        const ticketStatObj = ticketStatus.find(tStatus => tStatus.ticket_status_id === ticketObj.status_type);
        // Log the color if the ticketStatus is found
        if (ticketStatObj) {
            return ticketStatObj.color; // Return only the color value
        }}
        catch{
        return 'White';}
    };

    const getSubmissionRecordStatus = (submissionObj) => {
        if (submissionObj.record_status_id) {
            return submissionObj.record_status_id;
        }
        return 6;
    }

    const getGradeType = (gradeId) => {
        const grade = grades.find(g => g.grade_id === gradeId)
        if (grade) {
          return grade.grade
        }
        return "Unknown Grade";
      }
    const getEventName = (eventID) => {
        const event = eventPrograms.find(e => e.event_id === eventID)
        if (event) {
          return event.name
        }
        return "Unknown Program";
    }
    const getEventDate = (eventDateId) => {
        const event = eventDates.find(e => e.event_dates_id === eventDateId);
        
        if (event) {
            // Assuming event.start_time is in 'HH:MM:SS' format
            const timeString = event.start_time;
            const timeParts = timeString.split(':');
            const date = new Date();
            date.setHours(timeParts[0], timeParts[1], timeParts[2]);
    
            // Convert to 12-hour time format
            const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    
            return `${event.date} at ${formattedTime}`;
        }
    
        return "Unknown Event Date";
    };
    const getTicketInfo = (ticketId, submission) => {
        const ticketObj = tickets.find(e => e.ticket_id === ticketId);
        return ticketObj ? (
            <div className="ticket-info-container">
                <Button onClick={() => editTicketOrder(getTicketObj(ticketId), submission)} className="edit-ticket-button">
                    <i className="bi bi-pencil-square"></i>
                </Button>
                <span>
                    Seat: {ticketObj.seat_number}
                    <br />
                    Order: {ticketObj.order_number}
                </span>
            </div>
        ) : 'Ticket Not Assigned';
    }
    const getTicketNotes = (ticketId, submission) => {
        const ticketObj = tickets.find(e => e.ticket_id === ticketId);
        return ticketObj ? (
            <div className="ticket-notes-container">
                <Button onClick={() => editTicketNotes(getTicketObj(ticketId), submission)} className="ticket-notes-button">
                    Notes
                </Button>
                <span>
                    {ticketObj.notes}
                </span>
            </div>
        ) : 'Ticket Not Assigned';
    }
    const getTicketEmailInfo = (ticketId, submission) => {
        const ticketObj = tickets.find(e => e.ticket_id === ticketId);
        return ticketObj ? (
            <div className="ticket-info-container">
                <Button onClick={() => editTicketEmailInfo(getTicketObj(ticketId), submission)} className="edit-ticket-button">
                <i className="bi bi-pencil-square"></i>
                </Button>
                <span>
                    Reminder Email: 
                        <i 
                            className={`bi ${ticketObj.ticket_mailed ? 'bi-check-circle-fill icon-true' : 'bi-x-circle-fill icon-false'}`}
                        ></i>
                    <br />
                    Ticket Picked up: 
                        <i 
                            className={`bi ${ticketObj.ticket_pulled ? 'bi-check-circle-fill icon-true' : 'bi-x-circle-fill icon-false'}`}
                        ></i>
                </span>

            </div>
        ) : 'Ticket Not Assigned';
    }


    const getTicketObj = (ticketId) => {
        try{{const ticketObj = tickets.find(e => e.ticket_id === ticketId);
        if(ticketObj)
            {return ticketObj ? ticketObj : 'Ticket Not Assigned';}
        else {
            // console.log('no ticket assigned. ')
        }}}
        catch{
            setErrorMessage('no ticket')
        }
    }
    const getTicketStatus = (ticketId) => {
        const ticketObj = tickets.find(e => e.ticket_id === ticketId);
        return ticketObj ? (ticketObj.status_type) : 8;
    }    

    const lookupTicketStatus = (ticketId) => {
        const ticketObj = tickets.find(e => e.ticket_id === ticketId);
        if (ticketObj){
            return ticketObj;
        }
        
    }    

    const getStateObj = (stateId) => {
        const id = Number(stateId);
        const stateObj = USAstates.find(state => state.state_id === id);
        return stateObj ? (stateObj.state_name) : <span style={{color: 'red'}}>failed to load</span>;
    }

    const resetFormAndState = () => {
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            teacher_status: false,
            cactus_number: '',
            school_id: '',
            district: '',
            event_program_id: '',
            event_date_id: '',
            grade_id: '',
            additional_contact: false,
            street: '',
            city: '',
            state: '',
            zip: '',
            names_to_sit_by: '',
            cactus_number_verified: null
        });
        setIsEditing(false);
        setSelectedSchool(null);
        // Reset any other state as necessary
    };

    const toggleFormView = () => {
        setShowForm(!showForm);
        if (showForm) {
            // If we are hiding the form, reset the state
            resetFormAndState();
        }
        // Optionally, handle else case if you need to do anything specific when showing the form
    };

    const TableRowComponent = React.memo(({
        submission,
        getEventName,
        getEventDate,
        getSchoolName,
        getGradeType,
        getTicketStatusColor,
        getTicketStatus,
        handleTicketStatusEdit,
        getTicketObj,
        getTicketNotes,
        getRecordStatusColor,
        getStateObj,
        getSubmissionRecordStatus,
        handleEdit,
        getLastModifiedName,
        getTicketEmailInfo,
        editSubmission,
        setSubmissionToDelete,
        setShowModal,
        ticketStatus
      }) => {
        return (
          <tr key={submission.submission_id}>
                {/* Name */}
            <td>
                <span className='first-last-name-ticket-office bold-text'>
                    {submission.first_name} {submission.last_name}
                </span><br></br>
                <p className='contact-requested'> 
                    {/*The client originally wanted the field to be "Contact Requested"
                     but has since asked for "Purchase Extra Tickets" */}
                    Purchase Extra Tickets: <i className={`bi ${submission.additional_contact ? 'bi-check-circle-fill icon-true' : 'bi-x-circle-fill icon-false'}`}> </i>
                </p>
            </td>
                {/* Ticket Info */}
            <td >
                    <div className='event-date-ticket-office'>
                        <span className='bold-text'>{'Event: '}</span>{getEventName(submission.event_program_id)}<br />
                        <span className='bold-text'>{'Date: '}</span>{getEventDate(submission.event_date_id)}<br />
                        <div>{getTicketInfo(submission.ticket_id, submission)}</div>
                    </div>
            </td>
                {/* School Info */}
            <td>
                <span className='bold-text'>{'School: '}</span>{getSchoolName(submission.school_id)}
                <br />
                <span className='bold-text'>{'Grade: '}</span>{getGradeType(submission.grade_id)}
                <br />
                <span className='bold-text'>{'Teacher: '}<i className={`bi ${submission.teacher_status ? 'bi-check-circle-fill icon-true' : 'bi-x-circle-fill icon-false'}`}> </i></span>
                {submission.teacher_status ? 
                <span><br></br><span className='bold-text'>{'Cactus Number: '}</span> {submission.cactus_number}</span> : ''}
                {submission.teacher_status ?
                    <span className='cactus-verification-hover' onClick={() => HandleUpdateCactusVerification(submission, submission.submission_id)}>
                        <br></br>
                        <span className='bold-text'>{'Cactus Verified: '}</span>
                        <span>
                            {submission.cactus_number_verified === null ?
                                <i className="bi bi-question-circle-fill icon-unknown"></i> : // This line is for null values
                                <i className={`bi ${submission.cactus_number_verified ? 'bi-check-circle-fill icon-true' : 'bi-x-circle-fill icon-false'}`}></i>
                            }
                        </span>
                    </span> : ''
                }
            </td>
            {/* Contact Info */}
            <td>
                <span className='bold-text'>{'Email: '}</span>{submission.email}
                <br />
                <span className='bold-text'>{'Phone: '}</span>{submission.phone_number}
                <br></br>
                <span className='bold-text'>{'Address: '}</span>
                {submission.street ? `${submission.street}, ${submission.city}, ${getStateObj(submission.state, submission)}, ${submission.zip}`: <span style={{color:'maroon'}}>Address wasn't added.</span>}
                <br></br>
                <span className='bold-text'>{'Would like to sit by: '}</span>{submission.names_to_sit_by}
            </td>
            {/* Ticket Contact Status */}
            {/* <td>{submission.additional_contact ? '\u2713' : ''}</td> */}
            <td className='ticket-office-main-ticket-contact-status' style={{backgroundColor: getTicketStatusColor(submission.ticket_id)}}>
                <Form.Control
                as='select'
                value={getTicketStatus(submission.ticket_id)}
                name='ticket_id'
                onChange={(e) => handleTicketStatusEdit(
                    e.target.value,
                    getTicketObj(submission.ticket_id),
                    submission
                )}>
                    {ticketStatus.map(tStatus => (
                        <option key={tStatus.ticket_status_id} value={tStatus.ticket_status_id}>{tStatus.name}</option>
                    ))}

                </Form.Control>
                {getTicketNotes(submission.ticket_id, submission)}
            </td>
            {/* Record Status */}
            <td style={{ backgroundColor: getRecordStatusColor(submission.record_status_id) }}>
                <Form.Control
                    as="select"
                    value={getSubmissionRecordStatus(submission)}
                    name="record_status_id"
                    id={submission.submission_id}
                    onChange={(e) => handleEdit(
                        submission.submission_id,
                            "record_status_id",
                            e.target.value,
                            submission)
                            }>
                    {recordStatuses.map(record => (
                        <option key={record.status_id} value={record.status_id}>{record.status}</option>
                    ))}
                </Form.Control>
                Last modified by: {getLastModifiedName(submission.last_modified_by)}
            </td>   

            {/* Submitted date */}
            <td>{submission.submitted_date}</td>
            {/* Email Status */}
            <td>
                <span className='bold-text'>{getTicketEmailInfo(submission.ticket_id, submission)}</span>
            </td>

            <td>
                <Button onClick={() => editSubmission(submission)} className="m-1"><i className="bi bi-pencil-square"></i></Button>
                <Button variant="danger" onClick={() => {
                    setSubmissionToDelete(submission.submission_id);
                    setShowModal(true);
                }}><i className="bi bi-trash3"></i></Button>
            </td>                        
          </tr>
        );
      });
    
    const DropDownFilterSelection = ({tableHeadings}) => (
        <div className='search-section-ticket-office'>
            <span className='search-title-ticket-office'>Select Your Search Term</span>
            <Form.Control
            as='select'
            value={selectedSearchTerm}
            name='searchQueryValue'
            onChange = {(e) => setSelectedSearchTerm(e.target.value)}
            className='search-selection-ticket-office'
            >
            <option> Choose Search Term </option>
            {tableHeadings.map((head, index) => (
                <option key={index} value={head}>{head}</option>
            ))}
            </Form.Control>
        </div>
    );
    
    const handleYearChange = (event) => {
        setSelectedSchoolYear(event.target.value);
    };

  const getPaginationItems = () => {
    const items = [];
    const MAX_PAGES_DISPLAYED = 5;
    let startPage = Math.max(currentPage - Math.floor(MAX_PAGES_DISPLAYED / 2), 1);
    let endPage = Math.min(startPage + MAX_PAGES_DISPLAYED - 1, totalPages);

    if (endPage - startPage + 1 < MAX_PAGES_DISPLAYED) {
        startPage = Math.max(endPage - MAX_PAGES_DISPLAYED + 1, 1);
    }

    items.push(
        <Pagination.Prev key="prev" disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)} />
    );

    for (let number = startPage; number <= endPage; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => setCurrentPage(number)}>
                {number}
            </Pagination.Item>
        );
    }

    items.push(
        <Pagination.Next key="next" disabled={currentPage === totalPages} onClick={() => setCurrentPage(currentPage + 1)} />
    );

    return items;
};
    
    return (
        <div>
            {authState && <NavbarComponent />}
            <Button onClick={toggleFormView} className="m-1">
                {showForm ? 'Show Ticket Submissions List' : 'Add New Ticket Submission'}
            </Button>
            <div>
                <Dropdown>
                <span className='fw-bold'>Select School Year</span>
                <select value={selectedSchoolYear} onChange={handleYearChange}>
                    <option value="" disabled>Select School Year</option>
                    {schoolYearList.map((year) => (
                        <option key={year.id} value={year.id}>
                        {year.school_year}
                        </option>
                    ))}
                    </select>
                </Dropdown>
            </div>
            <p>
                {'Displaying '} {displayedFormSubmissions.length}
                {' of '} {formSubmissions.length}
                {' Total Submissions.'} 
            </p>
            {!showForm ? 
            <div>
                <div className='search-container'>
                    <DropDownFilterSelection tableHeadings={tableHeadings} />
                    <Form.Group controlId="search" className='search-bar-ticket-office'>
                        <Form.Control
                            type="text"
                            placeholder={`Search by typing the ${selectedSearchTerm}...`}
                            onChange={(e) => setSearchQuery(e.target.value)}
                    /></Form.Group>
                </div>
                <div className="date-range-filters mb-3">
                    <Row className="d-flex justify-content-start">
                        <Col xs="auto">
                            Search by Performance Date
                        </Col>
                        <Col xs="auto">
                            <Form.Group controlId="startDate">
                                <Form.Label>Start Date</Form.Label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    className="custom-datepicker"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Form.Group controlId="endDate">
                                <Form.Label>End Date</Form.Label>
                                <DatePicker
                                    selected={endDate}
                                    onChange={date => setEndDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    className="custom-datepicker"
                                />
                            </Form.Group>
                        </Col>
                        <Col xs="auto">
                            <Button onClick={() => (setStartDate(null), setEndDate(null))}>Clear Dates</Button>
                        </Col>
                        <Col>
                            <Button variant="primary" onClick={handleShow}>Generate Email List for current selection</Button>
                        </Col>
                    </Row>
                </div>

            </div>
         : <></>}
            
            {showForm ? (
                <Form onSubmit={handleSubmit}>
                    <Form.Group className='ticket-office-groups' controlId="first_name">
                        <Form.Label className={'ticket-office-form-labels'}>First Name</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter first name"
                            name="first_name"
                            onChange={handleFormChange}
                            value={formData.first_name}
                        />
                    </Form.Group>
                    <Form.Group className='ticket-office-groups' controlId="last_name">
                        <Form.Label className={'ticket-office-form-labels'}>Last Name</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter last name"
                            name="last_name"
                            onChange={handleFormChange}
                            value={formData.last_name}
                        />
                    </Form.Group>
                    <Form.Group className='ticket-office-groups' controlId="email">
                        <Form.Label className={'ticket-office-form-labels'}>Email</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter email"
                            name="email"
                            onChange={handleFormChange}
                            value={formData.email}
                        />
                    </Form.Group>
                    <Form.Group className='ticket-office-groups' controlId="phone_number">
                        <Form.Label className={'ticket-office-form-labels'}>Phone Number</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter phone_number"
                            name="phone_number"
                            onChange={handleFormChange}
                            value={formData.phone_number}
                        />
                    </Form.Group>
                    <Form.Group className='ticket-office-groups' controlId="street">
                        <Form.Label className={'ticket-office-form-labels'}>Street</Form.Label>
                        <Form.Control
                        type='text'
                        placeholder='Enter Street'
                        name='street'
                        required
                        onChange={handleFormChange}
                        value={formData.street}>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='ticket-office-groups' controlId='city'>
                        <Form.Label className={'ticket-office-form-labels'}>City</Form.Label>
                        <Form.Control
                        type='text'
                        placeholder='Enter City'
                        name='city'
                        required
                        onChange={handleFormChange}
                        value={formData.city}>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='ticket-office-groups' controlId='state'>
                        <Form.Label className={'ticket-office-form-labels'}>State</Form.Label>
                        <Form.Control
                        as='select'
                        required
                        name='state'
                        onChange={handleFormChange}
                        value={formData.state}>
                            <option value={''}>Choose State</option>
                            {USAstates.map(state => (
                                <option value={state.state_id} key={state.state_id}>{state.state_name}, {state.abbreviation}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='ticket-office-groups' controlId='zip'>
                        <Form.Label className={'ticket-office-form-labels'}>Zip</Form.Label>
                        <Form.Control
                        type='text'
                        placeholder='Enter Zip'
                        name='zip'
                        required
                        onChange={handleFormChange}
                        value={formData.zip}>
                        </Form.Control>
                    </Form.Group>
                    {/* <Form.Group className='ticket-office-groups' controlId="student_name">
                        <Form.Label className={'ticket-office-form-labels'}>Student Name</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter student name"
                            name="student_name"
                            onChange={handleFormChange}
                            value={formData.student_name}
                        />
                    </Form.Group> */}
                    <Form.Group className='ticket-office-groups' controlId="teacher_status">
                        <Form.Label className={'ticket-office-form-labels'}>Teacher Status</Form.Label>
                        <Form.Check 
                            type="checkbox"
                            label="Is a teacher"
                            name="teacher_status"
                            onChange={e => handleFormChange({target: {name: e.target.name, value: e.target.checked}})}
                            checked={formData.teacher_status}
                        />
                    </Form.Group>
                    <Form.Group className='ticket-office-groups' controlId="cactus_number">
                        <Form.Label className={'ticket-office-form-labels'}>Cactus Number</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter cactus number"
                            name="cactus_number"
                            onChange={handleFormChange}
                            value={formData.cactus_number}
                        />
                    </Form.Group>
                    <Form.Group className='ticket-office-groups' controlId="grade_id">
                        <Form.Label className={'ticket-office-form-labels'}>Grade</Form.Label>
                        <Form.Control 
                            as="select"
                            name="grade_id"
                            onChange={handleGradeChange}
                            value={formData.grade_id}
                        ><option value="">Select Grade</option>
                        {Array.isArray(grades) && grades.map(grade => (
                            <option key={grade.grade_id} value={grade.grade_id}>{grade.grade}</option>
                        ))}
                        </Form.Control> 
                    </Form.Group>
                    <Form.Group className='ticket-office-groups' controlId="school_id">
                        <Form.Label className={'ticket-office-form-labels'}>School</Form.Label>
                        <Select 
                            isSearchable={true}
                            options={schoolOptions}
                            value={selectedSchool}
                            onChange={handleSchoolChange}
                            inputId="school_id"
                        />
                    </Form.Group>
                    {/* <Form.Group className='ticket-office-groups' controlId="email">
                        <Form.Label className={'ticket-office-form-labels'}>District</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter district"
                            name="district"
                            onChange={handleFormChange}
                            value={formData.district}
                        />
                    </Form.Group> */}
                    <Form.Group className='ticket-office-groups' controlId="event_program_id">
                    <Form.Label className={'ticket-office-form-labels'} >Event Program</Form.Label>
                        <Form.Control 
                        as="select"
                        name="event_program_id"
                        value={formData.event_program_id}
                        onChange={e => handleEventProgramChange({ value: e.target.value, label: e.target.options[e.target.selectedIndex].text })}
                        >
                        <option value="">Select Event Program</option>
                        {eventPrograms.map(program => (
                            <option key={program.event_id} value={program.event_id}>{program.name}</option>
                        ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='ticket-office-groups' controlId="event_date_id">
                        <Form.Label className={'ticket-office-form-labels'} >Event Date</Form.Label>
                        <Select 
                            options={relevantEventDates}
                            onChange={handleEventDateChange}
                            value={relevantEventDates.find(date => date.value === formData.event_date_id) || null}
                            placeholder="Select Event Date"
                            isDisabled={!selectedEventProgram }
                            inputId="event_date_id"
                        />
                    </Form.Group>
                    <Form.Group className='ticket-office-groups' controlId="additional_contact">
                        <Form.Label className={'ticket-office-form-labels'}>Would you like to be contacted for additional tickets?</Form.Label>
                        <Form.Check 
                            type="checkbox"
                            label="Yes"
                            name="additional_contact"
                            onChange={e => handleFormChange({target: {name: e.target.name, value: e.target.checked}})}
                            checked={formData.additional_contact}
                        />
                    </Form.Group>

                    <Button type="submit" className="m-1">{ isEditing ? "Update" : "Submit"}</Button>
                    <Button variant="secondary" onClick={handleCancel} className="ml-2">Cancel</Button>
                </Form>
            ) : (
                <>
                {/* <FixedHeader/> */}
            <div ref={scrollableTableRef} className="scrollable-table">
            <Table striped bordered hover>
                    <thead>
                        <tr className='table-heading'>
                            <th className='name-office-ticket-table'>Name</th>
                            <th className='ticket-office-ticket-table'>Ticket Info</th>
                            <th className='school-office-ticket-table'>School (District) Info</th>
                            <th className='contact-info-office-ticket-table'>Contact Info</th>
                            <th className='ticket-status-office-ticket-table'>Ticket Contact Status</th>
                            <th className='record-status-office-ticket-table'>Record Status</th>
                            <th className='submit-date-office-ticket-table'>Submitted Date</th>
                            <th className='email-status-office-ticket-table'>Ticket Status</th>
                            <th className='actions-office-ticket-table'>Actions</th>
                        </tr>
                    </thead>
                    <tbody className='text-left main-ticket-office-table'>
                        {displayedFormSubmissions.map((submission) => (
                            <TableRowComponent
                            key={submission.submission_id}
                            submission={submission}
                            getEventName={getEventName}
                            getEventDate={getEventDate}
                            getSchoolName={getSchoolName}
                            getGradeType={getGradeType}
                            getTicketStatusColor={getTicketStatusColor}
                            getTicketStatus={getTicketStatus}
                            handleTicketStatusEdit={handleTicketStatusEdit}
                            getTicketObj={getTicketObj}
                            getStateObj={getStateObj}
                            getTicketNotes={getTicketNotes}
                            getRecordStatusColor={getRecordStatusColor}
                            getSubmissionRecordStatus={getSubmissionRecordStatus}
                            handleEdit={handleEdit}
                            getLastModifiedName={getLastModifiedName}
                            getTicketEmailInfo={getTicketEmailInfo}
                            editSubmission={editSubmission}
                            setSubmissionToDelete={setSubmissionToDelete}
                            setShowModal={setShowModal}
                            ticketStatus={ticketStatus}
                          />
                          ))}
                    </tbody>
            </Table>
        </div>
        {showForm ? "" : (
            <Pagination className="justify-content-center">
        {getPaginationItems()}
      </Pagination> )}
                </>
            )}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this ticket submission?</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={deleteEventTemplate}>
                        Delete
                    </Button>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showTicketInfoMod} onHide={() => setShowTicketInfoMod(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Ticket Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId='order_number'>
                            <Form.Label>Order Number</Form.Label>
                            <Form.Control
                            type='text'
                            name='order_number'
                            value={ticketForm.order_number}
                            onChange={e => handleTicketFormChange({target: {name: e.target.name, value: e.target.value}})}
                            />
                        </Form.Group>
                        <Form.Group controlId='seat_number'>
                            <Form.Label>Seat Number</Form.Label>
                            <Form.Control
                            type='text'
                            name='seat_number'
                            value={ticketForm.seat_number}
                            onChange={e => handleTicketFormChange({target: {name: e.target.name, value: e.target.value}})}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleTicketUpdate}>Update</Button>
                    <Button variant='secondary' onClick={() => {setShowTicketInfoMod(false)}}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showTicketsNotesMod} onHide={() => setShowTicketNotesMod(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Ticket Notes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId='notes'>
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                            as='textarea'
                            name='notes'
                            value={ticketForm.notes}
                            rows={15}
                            className='ticket-notes-field-mod'
                            onChange={e => handleTicketFormChange({target: {name: e.target.name, value: e.target.value}})}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleTicketUpdate}>Update</Button>
                    <Button variant='secondary' onClick={() => {setShowTicketNotesMod(false)}}>Close</Button>
                </Modal.Footer>
            </Modal>            
            <Modal show={showTicketEmailMod} onHide={() => setShowTicketEmailMod(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Ticket Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId='ticket_mailed' className="left-aligned-checkbox">
                            {/* <Form.Label>Ticket Mailed</Form.Label> */}
                            <Form.Check
                            label='Ticket Mailed'
                            type='checkbox'
                            name='ticket_mailed'
                            checked={ticketForm.ticket_mailed}
                            className='ticket-email-field-mod'
                            onChange={e => handleTicketFormChange({target: {name: e.target.name, value: e.target.checked}})}
                            />
                        </Form.Group>
                        <Form.Group controlId='ticket_pulled' className="left-aligned-checkbox">
                            {/* <Form.Label>Ticket Pulled</Form.Label> */}
                            <Form.Check
                            label='Ticket Pulled'
                            type='checkbox'
                            name='ticket_pulled'
                            checked={ticketForm.ticket_pulled}
                            className="ticket-email-field-mod"
                            onChange={e => handleTicketFormChange({target: {name: e.target.name, value: e.target.checked}})}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleTicketUpdate}>Update</Button>
                    <Button variant='secondary' onClick={() => {setShowTicketEmailMod(false)}}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showInsufficientPrivileges} onHide={() => setShowInsufficientPrivileges(false)}>
                <Modal.Header>
                    <Modal.Title>Insufficient Privileges</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your account doesn't have the right privileges to perform this action.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => {setShowInsufficientPrivileges(false)}}>Close</Button>
                </Modal.Footer>
            </Modal>
            <EmailModal show={showEmailModal} handleClose={handleClose} emails={emailList}/>
            <ErrorModalMessage errorMessage={errorMessage} handleClose={handleClose} clearErrorMessage={() => setErrorMessage('')}></ErrorModalMessage>
        </div>
    );
    

};

export default EventTemplateComponent;
