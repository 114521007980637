import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table, Dropdown, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../store/AuthContext';
import { backendURL } from '../IPaddress';
import '../CSS/CRUDfiles.css';
import Collapse from 'react-bootstrap/Collapse';
import Select from 'react-select';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useSchoolYear } from '../store/SchoolYearCalculator';
import ErrorModalMessage from '../store/ErrorMessage';

const WorkshopStudentsOBC = () => {
    const live = 12; // set this to 14 for development and 12 for live frontend.
    const [eventsPrograms, setEventsPrograms] = useState([]);
    const [currentEventProgram, setCurrentEventProgram] = useState({
      event_template_id: null,
      name: null,
      description: '',
      location_id: null,
      coordinator_id: null,
      event_type_id: live,
      student_performance: false
    });
    const [currentEventDate, setCurrentEventDate] = useState({
      event_dates_id: null,
      event_id: null, 
      date: null,
      start_time: null, 
      end_time: null,
      total_seats: null,
      sold_out: false,
    });
    const [dateSchools, setDateSchools] = useState([{ school_id: null, number_of_students: null }]);
    const [eventDates, setEventDates] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [showModalDateEvent, setShowModalDateEvent] = useState(false);
    const { authState } = useAuth();
    const [openStates, setOpenStates] = useState({});
    const navigate = useNavigate();
    const [eventTypes, setEventTypes] = useState([]);
    const [locations, setLocations] = useState([]);
    const [userNames, setUsersNames] = useState([]);
    const [newDateModal, setNewDateModal] = useState(false);
    const [showEventDateModal, setShowEventDateModal] = useState(false);
    const [showDeletionError, setShowDeletionError] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [profileEvents, setProfileEvents] = useState([]);
    const [obcWorkshopTypes, setOBCWorkshopTypes] = useState([]);
    const [teacherProfiles, setTeacherProfiles] = useState([]);
    const [selectedTeachers, setSelectedTeachers] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState([]);
    const [displayedSelectionTeachers, setDisplayedSelectionTeachers] = useState([]);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [selectedSchoolYear, setSelectedSchoolYear] = useState();
    const { currentYear, schoolYearListParent } = useSchoolYear();
    const [selectedSchool, setSelectedSchool] = useState([]);
    const [selectedSchools, setSelectedSchools] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [schools, setSchools] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [datesSchools, setDatesSchools] = useState([]);
    const [itemsToDelete, setItemsToDelete] = useState([]);

    useEffect(() => {
      if (!authState) {
        navigate('/login');
      }
    }, [authState, navigate]);

    useEffect(() => {
      if (currentYear && schoolYearListParent.length) {
        setSelectedSchoolYear(currentYear);
        setSchoolYearList(schoolYearListParent);
      }
    }, [currentYear, schoolYearListParent]);

    useEffect(() => {
      if (selectedSchoolYear) {
        fetchEventsPrograms();
        fetchEventDates();
        fetchProfileEvents();
        fetchDatesSchool();
      }
    }, [selectedSchoolYear]);
  
    useEffect(() => {
      fetchEventTypes();
      fetchLocations();
      fetchUserNames();
      fetchOBCWorkshopTypes();
      fetchProfiles();
      fetchSchools();
      fetchDistricts();
    }, []);
  
    const fetchEventsPrograms = async () => {
      try {
        const response = await fetch(`${backendURL}/api/events-programs/year/${selectedSchoolYear}`, {
          method: 'GET',
        });
        const data = await response.json();
        const filteredEvents = data.filter(event => event.event_type_id === live);
        setEventsPrograms(filteredEvents);
      } catch {
        ErrorModalMessage('unable to fetch the program events');
      }
    };

    const fetchProfiles = async () => {
      const response = await fetch(`${backendURL}/api/profiles`, {
        method: 'GET',
        credentials: 'include'
      });
      if (!response) {
        console.error('failed to fetch profiles');
      };
      const data = await response.json();
      setTeacherProfiles(data);
    }

    const fetchDatesSchool = async () => {
      const response = await fetch(`${backendURL}/api/dates-schools/year/${selectedSchoolYear}`, {
        method: 'GET',
        credentials: 'include'
      });
      if (!response) {
        console.error('failed to fetch dates schools links');
      };
      const data = await response.json();
      setDatesSchools(data);
    }

    const fetchProfileEvents = async () => {
      const response = await fetch(`${backendURL}/api/profile-events/year/${selectedSchoolYear}`, {
        method: 'GET',
        credentials: 'include'
      });
      if (!response) {
        console.error('failed to fetch profile events');
      }
      const data = await response.json();
      setProfileEvents(data);
    };

    const fetchOBCWorkshopTypes = async () => {
      const response = await fetch(`${backendURL}/api/workshop_types`, {
        method: 'GET',
        credentials: 'include'
      });
      if (!response) {
        console.error('failed to fetch the obc workshop types');
      }
      const data = await response.json();
      setOBCWorkshopTypes(data);
    };
  
    const fetchEventTypes = async () => {
      const response = await fetch(`${backendURL}/api/event-types`, {
        method: 'GET',
        credentials: 'include'
      });
      try {
        const data = await response.json();
        setEventTypes(data);
      } catch (error) {
        console.error("Error fetching the event types: ", error.message);
      }
    };

    const fetchUserNames = async () => {
      try {
        const response = await fetch(`${backendURL}/api/app-users`, {
          method: 'GET',
          credentials: 'include'
        });
        const data = await response.json();
        if (!response.ok) throw new Error('Failed to fetch user data');
        setUsersNames(data);
      } catch (err) {
        console.error(err.message);
      }
    };

    const fetchLocations = async () => {
      const response = await fetch(`${backendURL}/api/locations`, {
        method: 'GET',
        credentials: 'include'
      });
      try {
        const data = await response.json();
        setLocations(data);
      } catch (error) {
        console.error("Error fetching locations: ", error.message);
      }
    };
  
    const fetchEventDates = async () => {
      try {
        const response = await fetch(`${backendURL}/api/event-dates/year/${selectedSchoolYear}`, {
          method: 'GET',
          credentials: 'include'
        });
        const data = await response.json();
        const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
        setEventDates(sortedData);
      } catch (error) {
        console.error("Error fetching event dates: ", error.message);
      }
    };

    const fetchSchools = async () => {
      const response = await fetch(`${backendURL}/api/schools`);
      if (!response.ok) {
        setErrorMessage('Unable to retrieve school information');
      }
      const data = await response.json();
      if (Array.isArray(data)) {
        setSchools(data);
      } else {
        setErrorMessage('Fetched Schools data is not an array:', data);
      }
    };

    const fetchDistricts = async () => {
      const url = `${backendURL}/api/districts`;
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        setDistricts(data);
      } else {
        setErrorMessage('Unable to retrieve districts');
      }
    };
  
    const handleEventFormChange = (field, value, type = null) => {
      switch(type){

        case null:
          setCurrentEventProgram({
            ...currentEventProgram,
            [field]: value,
            school_year_id: parseInt(selectedSchoolYear, 10)
          });
          break;
        case 'check':
          setCurrentEventProgram({
            ...currentEventProgram,
            [field]: value.target.checked,
            school_year_id: parseInt(selectedSchoolYear, 10)
          });
          break;
        default:
          break;
      }
    };

    const handleTeacherChange = selectedOptions => {
      setSelectedTeachers(selectedOptions);
      const teacherIds = selectedOptions.map(option => parseInt(option.value, 10)).filter(id => !isNaN(id));
      setSelectedTeacher(teacherIds);
    };
  
    const handleEventDateFormChange = (event) => {
      const { name, type, checked, value } = event.target;
  
      if (type === "checkbox") {
        setCurrentEventDate(prevState => ({
          ...prevState,
          [name]: checked,
          school_year_id: parseInt(selectedSchoolYear, 10),
        }));
      } else if (type === "number") {
        setCurrentEventDate(prevState => ({
          ...prevState,
          [name]: parseInt(value, 10),
          school_year_id: parseInt(selectedSchoolYear, 10),
        }));
      } else {
        setCurrentEventDate(prevState => ({
          ...prevState,
          [name]: value,
          school_year_id: parseInt(selectedSchoolYear, 10),
        }));
      }
    };

    const handleDateSchoolFormChange = (index, field, value) => {
      const newDateSchools = [...dateSchools];
      newDateSchools[index][field] = value;
      setDateSchools(newDateSchools);
    }

    const addDateSchool = () => {
      setDateSchools([...dateSchools, { school_id: null, number_of_students: null }]);
    }

    const removeDateSchool = (index) => {
      const newDateSchools = [...dateSchools];
      newDateSchools.splice(index, 1);
      setDateSchools(newDateSchools);
    };
    
  
    const handleEventSubmit = async (e) => {
      e.preventDefault();
      const method = currentEventProgram.event_id ? 'PUT' : 'POST';
      const url = currentEventProgram.event_id ? `${backendURL}/api/events-programs/${currentEventProgram.event_id}` : `${backendURL}/api/events-programs`;
      await fetch(url, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(currentEventProgram),
        credentials: 'include',
      });
  
      setCurrentEventProgram({
        event_template_id: null,
        name: null,
        description: '',
        location_id: null,
        coordinator_id: null,
        event_type_id: live,
        student_performance: false
      });
      setShowForm(false);
      fetchEventsPrograms();
    };
  
    const handleNewEventDate = async (eventProgramId) => {
      setCurrentEventDate({
        event_dates_id: null,
        event_id: eventProgramId, 
        date: null, 
        start_time: null, 
        end_time: null,
        total_seats: null,
        sold_out: false,
      });
      setDateSchools([{ school_id: null, number_of_students: null }]);
      setShowEventDateModal(true);
    };

    const handleMarkForDeletion = (index) => {
      const itemToDelete = dateSchools[index];
      setItemsToDelete([...itemsToDelete, itemToDelete]);
      removeDateSchool(index); // Optionally, you can immediately remove the item from the list displayed in the form
    };
    
  
    const handleEventDateSubmit = async (e) => {
      e.preventDefault();
      try {
        const method = currentEventDate.event_dates_id ? 'PUT' : 'POST';
        const url = currentEventDate.event_dates_id ? `${backendURL}/api/event-dates/${currentEventDate.event_dates_id}` : `${backendURL}/api/event-dates`;
        const dateResponse = await fetch(url, {
          method: method,
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(currentEventDate),
          credentials: 'include',
        });
        if (!dateResponse.ok) {
          throw new Error('Failed to create event date for the related program.');
        }
        const newDateResponse = await dateResponse.json();
    
        if (newDateResponse && newDateResponse.event_dates_id) {
          const payloadDatesSchools = dateSchools.map(ds => ({
            event_date_id: newDateResponse.event_dates_id,
            school_id: ds.school_id,
            number_of_students: parseInt(ds.number_of_students, 10),
            school_year_id: parseInt(selectedSchoolYear, 10),
          }));
    
          const eventDateSchoolPromises = payloadDatesSchools.map(payload => {
            const schoolUrl = newDateResponse.event_dates_id
              ? `${backendURL}/api/dates-schools/${newDateResponse.event_dates_id}/${payload.school_id}`
              : `${backendURL}/api/dates-schools/${newDateResponse.event_dates_id}/${payload.school_id}`;
            return fetch(schoolUrl, {
              method: method,
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(payload),
              credentials: 'include'
            });
          });
    
          await Promise.all(eventDateSchoolPromises);
    
          const deletePromises = itemsToDelete.map(item => {
            const deleteUrl = `${backendURL}/api/dates-schools/${newDateResponse.event_dates_id}/${item.school_id}`;
            return fetch(deleteUrl, {
              method: 'DELETE',
              credentials: 'include'
            });
          });
    
          await Promise.all(deletePromises);
    
          const eventProfileUrl = currentEventDate.event_dates_id ? `${backendURL}/api/profile-events/${currentEventDate.event_dates_id}` : `${backendURL}/api/profile-events`;
          const payload = {
            event_id: newDateResponse.event_dates_id,
            profile_id: selectedTeacher,
            school_year_id: parseInt(selectedSchoolYear, 10),
          };
          const eventProfileResponse = await fetch(eventProfileUrl, {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
            credentials: 'include'
          });
          if (!eventProfileResponse.ok) {
            setErrorMessage('Failed to link teachers to date.');
          }
        }
    
        setCurrentEventDate({
          event_dates_id: null,
          event_id: null,
          date: null,
          start_time: null,
          end_time: null,
          total_seats: null,
          sold_out: false,
        });
        setDateSchools([{ school_id: null, number_of_students: null }]);
        setShowEventDateModal(false);
        setShowModalDateEvent(false);
        setNewDateModal(false);
        fetchEventDates();
        fetchDatesSchool();
        setSelectedSchool([]);
        setSelectedSchools([]);
        fetchProfileEvents();
        setSelectedTeachers([]);
        setSelectedTeacher([]);
        setItemsToDelete([]); // Clear the items to delete list after successful submission
      } catch {
        setErrorMessage('Unable to create event date with schools.');
      }
    };
    
    
    
    
  
    const editEventProgram = (eventProgram) => {
      setCurrentEventProgram(eventProgram);
      setShowForm(true);
    };

    const teacherOptions = teacherProfiles.map(teacherProfile => ({
      label: `${teacherProfile.first_name} ${teacherProfile.last_name}`,
      value: teacherProfile.profile_id
    }));

    const schoolOptions = schools.map(school_id => {
      const district = districts.find(d => d.district_id === school_id.district_id);
      const districtName = district ? district.name : 'Unknown District';
      return {
        label: `${school_id.name} (${districtName})`,
        value: school_id.school_id
      };
    });

    const getTeachersForDate = (eventDateId) => {
      const eventDateObjs = eventDates.filter(eventDate => eventDate.event_id === eventDateId);
      if (eventDateObjs.length > 0) {
        const eventDatesIds = eventDateObjs.map(eventDate => eventDate.event_dates_id);
        const profileEventObjs = profileEvents.filter(profileEvent => eventDatesIds.includes(profileEvent.event_id));
        if (profileEventObjs.length > 0) {
          const profileIds = profileEventObjs.flatMap(profileEvent => profileEvent.profile_id);
          const listOfTeachers = teacherProfiles.filter(teacher => profileIds.includes(teacher.profile_id));
          return (
            <ul>
              {listOfTeachers.map(teacher => <li key={teacher.profile_id}>{teacher.first_name} {teacher.last_name}</li>)}
            </ul>
          );
        } else {
          return <p>No Teachers assigned to dates.</p>;
        }
      } else {
        return <p>No dates found.</p>;
      }
    };
  
    const editEventDate = (eventDate) => {
      const eventDateTeachersObj = profileEvents.filter(
        eventProfile => eventProfile.event_id === eventDate.event_dates_id
      );
    
      const datesSchoolsObjs = datesSchools.filter(
        dateSchool => dateSchool.event_date_id === eventDate.event_dates_id
      );
    
      const listOfTeachers = eventDateTeachersObj.flatMap(eventProfile =>
        teacherOptions.filter(teacherOption => eventProfile.profile_id.includes(teacherOption.value))
      );
    
      if (listOfTeachers.length) {
        const teacherIds = listOfTeachers.map(option => parseInt(option.value, 10)).filter(id => !isNaN(id));
        setSelectedTeachers(listOfTeachers);
        setSelectedTeacher(teacherIds);
      } else {
        setSelectedTeachers([]);
        setSelectedTeacher([]);
      }
    
      const listOfSchools = datesSchoolsObjs.flatMap(eventDateSchool =>
        schoolOptions.filter(schoolOption => eventDateSchool.school_id === schoolOption.value)
      );
      if (listOfSchools.length) {
        const schoolIds = listOfSchools.map(option => parseInt(option.value, 10)).filter(id => !isNaN(id));

        setSelectedSchools(listOfSchools);
        setSelectedSchool(schoolIds);
      } else {
        setSelectedSchool([]);
        setSelectedSchools([]);
      }
    
      setCurrentEventDate({ ...eventDate });
      setDateSchools(datesSchoolsObjs.map(ds => ({
        school_id: ds.school_id,
        number_of_students: ds.number_of_students
      })));
      setShowEventDateModal(true);
    };
    
  
    const handleCancel = () => {
      setCurrentEventDate({
        event_dates_id: null,
        event_id: null, 
        date: null,
        start_time: null, 
        end_time: null,
        total_seats: null,
        sold_out: false,
      });
      setCurrentEventProgram({
        event_template_id: null,
        name: null,
        description: '',
        location_id: null,
        coordinator_id: null,
        event_type_id: live,
        student_performance: false
      });
      setDateSchools([{ school_id: null, number_of_students: null }]);
      setSelectedSchool([]);
      setSelectedSchools([]);
      setShowForm(false);
      setShowEventDateModal(false);
      setShowModalDateEvent(false);
      setNewDateModal(false);
      setSelectedTeachers([]);
      setSelectedTeacher([]);
      setItemsToDelete([]);
    };
    
  
    const handleShowForm = () => {
      setCurrentEventProgram({
        event_template_id: null,
        name: null,
        description: '',
        location_id: null,
        coordinator_id: null,
        event_type_id: live,
        student_performance: false
      });
      setShowForm(!showForm);
    };
  
    const deleteEventProgram = async (event_id) => {
      try {
        const response = await fetch(`${backendURL}/api/events-programs/${event_id}`, {
          method: 'DELETE',
          credentials: 'include',
        });
        if (response.ok) {
          fetchEventsPrograms();
        } else {
          setErrorMessage("Unable to delete the associated event, check for dates associated with this event");
        }
      } catch (error) {
        setShowDeletionError(true);
        console.error("Please delete associated event dates before deleting an event.");
      }
      setSelectedTeacher([]);
    };
  
    const handleDeleteEventDate = () => {
      setConfirmationMessage('Are you sure you want to delete this event date?')
      setShowConfirmationModal(true);
    }
    
    const deleteEventDate = async (eventDateId) => {
      try {
        const deleteDateSchool = await fetch(`${backendURL}/api/dates-schools/${eventDateId}`, {
          method: 'DELETE',
          credentials: 'include',
        });        
        if (deleteDateSchool.ok) {
          const response = await fetch(`${backendURL}/api/event-dates/${eventDateId}`, {
            method: 'DELETE',
            credentials: 'include'
          });
          if (response.ok) {
            setShowEventDateModal(false);
            setShowConfirmationModal(false);
            setSelectedTeachers([]);
            setSelectedTeacher([]);
            fetchProfileEvents();
            fetchEventDates();    
          } else {
            throw new Error('Failed to delete the event date');
          }
        }
      } catch (error) {
        console.error("Deletion failed:", error);
      }
    };
  
    const formatTime = (timeString) => {
      if (timeString === null) {
        return `no time set`;
      }
      const [hours, minutes] = timeString.split(':');
      const hoursInt = parseInt(hours, 10);
      const suffix = hoursInt >= 12 ? 'PM' : 'AM';
      const formattedHours = ((hoursInt + 11) % 12 + 1); // Convert 24hr to 12hr format
      return `${formattedHours}:${minutes} ${suffix}`;
    };
  
    const getEventDates = (eventDateId) => {
      const eventDateObj = eventDates.filter(eventDate => eventDate.event_id === eventDateId);
      if (eventDateObj.length > 0) {
        return (
          <ul>
            {eventDateObj.map(eventDate => (
              <li key={eventDate.event_dates_id} className='event-dates-list' onClick={() => editEventDate(eventDate)}>
                {`Date: `}{eventDate.date ? eventDate.date : 'No date set'} <br/>
                {'From: '}{formatTime(eventDate.start_time)}{' to '}{formatTime(eventDate.end_time)}
              </li>
            ))}
          </ul>
        );
      }
      return eventDateObj ? (<>{eventDateObj.date}</>) : 'Date failed to load';
    };

    const getSchoolName = (schoolId) => {
      const schoolObj = schoolOptions.find(school => school.value === schoolId);
      return schoolObj ? schoolObj.label : 'School name failed to load';
    };
  
    const getSchoolObj = (eventId) => {
      // Filter eventDates to get the matching eventDateObj
      const eventDateObj = eventDates.filter(date => date.event_id === eventId);
    
      // Filter datesSchools to get the list of schools based on matching event_date_id
      const listOfSchools = datesSchools.filter(dateSchool => 
          eventDateObj.some(eventDate => dateSchool.event_date_id === eventDate.event_dates_id)
      );
    
      // Use a Set to track unique school IDs
      const uniqueSchoolIds = new Set();
    
      // Collect unique schools
      const uniqueSchools = [];
      listOfSchools.forEach(dateSchool => {
          if (!uniqueSchoolIds.has(dateSchool.school_id)) {
              uniqueSchoolIds.add(dateSchool.school_id);
              uniqueSchools.push(dateSchool.school_id);
          }
      });
    
      return (
          <>
              {uniqueSchools.map(schoolId => 
                  <li key={schoolId}>{getSchoolName(schoolId)}</li>
              )}
          </>
      );
    };
    

    const getNumberOfStudents = (eventId) => {
      const eventDateObjs = eventDates.filter(eventDate => eventDate.event_id === eventId);
      if (eventDateObjs.length > 0) {
        const totalNumberOfStudents = eventDateObjs.reduce((total, eventDate) => {
          const dateSchoolsObjs = datesSchools.filter(dateSchool => dateSchool.event_date_id === eventDate.event_dates_id);
          const eventDateTotal = dateSchoolsObjs.reduce((subTotal, dateSchool) => subTotal + (dateSchool.number_of_students || 0), 0);
          return total + eventDateTotal;
        }, 0);
        return <>{totalNumberOfStudents}</>;
      }
      return null;
    };
    
  
    const toggleOpenState = (eventId) => {
      setOpenStates(prevOpenStates => ({
        ...prevOpenStates,
        [eventId]: !prevOpenStates[eventId],
      }));
    };

    const handleYearChange = (event) => {
      setSelectedSchoolYear(event.target.value);
    };
  
    return (
      <div>
        <Button onClick={() => handleShowForm()} className="m-1">
          {showForm ? 'Show OBC Workshops List' : 'Add New Workshop'}
        </Button>
        <div>
          <Dropdown>
            <span className='fw-bold'>Select School Year</span>
            <select value={selectedSchoolYear} onChange={handleYearChange}>
              <option value="" disabled>Select School Year</option>
              {schoolYearList.map((year) => (
                <option key={year.id} value={year.id}>
                  {year.school_year}
                </option>
              ))}
            </select>
          </Dropdown>
        </div>
        {showForm ? (
          <Form onSubmit={handleEventSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter event name"
                name="name"
                value={currentEventProgram.name}
                onChange={e => handleEventFormChange('name', e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter event description"
                name="description"
                value={currentEventProgram.description}
                onChange={e => handleEventFormChange('description', e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='student_performance'>
              <Form.Label>Is this a student performance?</Form.Label>
              <Form.Check
              checked={currentEventProgram.student_performance}
              onChange={e => handleEventFormChange('student_performance', e, 'check')}
              name='student_performance'
              ></Form.Check>
            </Form.Group>
            <Form.Group controlId="location_id">
              <Form.Label>Location ID</Form.Label>
              <Form.Control
                as={'select'}
                name="location_id"
                value={currentEventProgram.location_id}
                onChange={e => handleEventFormChange('location_id', e.target.value)}
              >
                <option value={null}>{"Select Location"}</option>
                {locations.map(location => (
                  <option key={location.location_id} value={location.location_id}>{location.name}</option>
                ))}
              </Form.Control>
            </Form.Group>
            <Button type="submit" className="m-1">Submit</Button>
            <Button variant="secondary" className="m-1" onClick={handleCancel}>Cancel</Button>
          </Form>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Event Name</th>
                <th colSpan={1}>Description</th>
                <th colSpan={3}>Schools</th>
                <th>Teachers</th>
                <th>Number of Students</th>
                <th>Dates Listed:</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {eventsPrograms.map(eventProgram => (
                <tr key={eventProgram.event_id}>
                  <td>{eventProgram.name}</td>
                  <td colSpan={1}>{eventProgram.description}</td>
                  <td colSpan={3}>{getSchoolObj(eventProgram.event_id)}</td>
                  <td>{getTeachersForDate(eventProgram.event_id)}</td>
                  <td>{getNumberOfStudents(eventProgram.event_id)}</td>
                  <td>
                    <Button
                      onClick={() => toggleOpenState(eventProgram.event_id)}
                      aria-controls="example-collapse-text"
                      aria-expanded={openStates[eventProgram.event_id] || false}
                    >
                      {'Expand to show dates'}
                    </Button>
                    <Collapse in={openStates[eventProgram.event_id] || false}>
                      <div>
                        <Button style={{ margin: 5 + 'px' }} onClick={() => handleNewEventDate(eventProgram.event_id)}>{'New Date'}</Button>
                        {getEventDates(eventProgram.event_id)}
                      </div>
                    </Collapse>
                  </td>
                  <td>
                    <Button onClick={() => editEventProgram(eventProgram)} className="m-1"><i className="bi bi-pencil"></i></Button>
                    <Button variant="danger" onClick={() => deleteEventProgram(eventProgram.event_id)} className="m-1"><i className="bi bi-trash3"></i></Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Modal size="lg" show={showEventDateModal} onHide={() => handleCancel()}>
          <Modal.Header>{currentEventDate.event_dates_id ? 'Edit Event Date' : 'Create New Event Date'}</Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Select Date</Form.Label>
                <Form.Control
                  type={'date'}
                  id='date'
                  name='date'
                  value={currentEventDate.date}
                  onChange={handleEventDateFormChange}>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Start Time</Form.Label>
                <Form.Control
                  type={'time'}
                  id='start_time'
                  name='start_time'
                  value={currentEventDate.start_time}
                  onChange={handleEventDateFormChange}>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>End Time</Form.Label>
                <Form.Control
                  id='end_time'
                  value={currentEventDate.end_time}
                  type={'time'}
                  name='end_time'
                  onChange={handleEventDateFormChange}>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="teacherId">
                <Form.Label>Teacher(s)</Form.Label>
                <Select
                  isMulti
                  isSearchable={true}
                  options={teacherProfiles.map(profile => ({ value: profile.profile_id, label: (`${profile.first_name} ${profile.last_name}`) }))}
                  value={selectedTeachers}
                  onChange={handleTeacherChange}
                  classNamePrefix="select"
                />
              </Form.Group>
              {dateSchools.map((ds, index) => (
                <Row key={index} className="mb-3 align-items-center">
                  <Col md={6}>
                    <Form.Group controlId={`school_${index}`}>
                      <Form.Label>School</Form.Label>
                      <Select
                        options={schoolOptions}
                        value={ds.school_id ? schoolOptions.find(s => s.value === ds.school_id) : null}
                        onChange={selectedOption => handleDateSchoolFormChange(index, 'school_id', selectedOption ? [selectedOption.value] : [])}
                        classNamePrefix="select"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId={`number_of_students_${index}`}>
                      <Form.Label>Number of Students</Form.Label>
                      <Form.Control
                        type="number"
                        value={ds.number_of_students || ''}
                        onChange={e => handleDateSchoolFormChange(index, 'number_of_students', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2} className="text-right">
                    {index > 0 && (
                      <Button variant="danger" onClick={() => handleMarkForDeletion(index)} className="mt-4">Remove</Button>
                    )}
                  </Col>
                </Row>
              ))}
              <Button variant="primary" onClick={addDateSchool}>Add Another School</Button>
            </Form>
            <Modal.Footer className='modal-footer-buttons'>
              {currentEventDate.event_dates_id ? 
              <Button variant='danger' onClick={() => handleDeleteEventDate()}>Delete</Button>
              : 
              ''}
              <Button onClick={handleEventDateSubmit}>{currentEventDate.event_dates_id ? 'Update' : 'Create'}</Button>
              <Button variant='secondary' onClick={() => handleCancel()}>Cancel</Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>
        <Modal show={showDeletionError} onHide={() => setShowDeletionError(false)}>
          <Modal.Header>Unable to process Request</Modal.Header>
          <Modal.Body>Please be sure to delete all associated dates with the event before deleting the event</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setShowDeletionError(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
          <Modal.Header closeButton>{'Please Confirm!'}</Modal.Header>
          <Modal.Body>{confirmationMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={() => deleteEventDate(currentEventDate.event_dates_id)}>{'Delete'}</Button>
            <Button variant='secondary' onClick={() => setShowConfirmationModal(false)}>{'Cancel'}</Button>
          </Modal.Footer>
        </Modal>
        <ErrorModalMessage errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage('')} />
      </div>
    );
};

export default WorkshopStudentsOBC;
