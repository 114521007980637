import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Table, Alert } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { backendURL } from '../IPaddress'; // Ensure this points to your backend server
import '../CSS/CRUDfiles.css'; // Adjust the path as necessary
import ErrorMessage from '../store/ErrorMessage';
import { useAuth } from '../store/AuthContext';
import Select from 'react-select';



function TeacherProfile() {
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]); // To store filtered profiles
  const [searchTerm, setSearchTerm] = useState(''); // To store search input
  const [showForm, setShowForm] = useState(false);
  const [editProfileData, setEditProfileData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const {authState} =useAuth();
  const [ teacherStatuses, setTeacherStatuses] = useState([]);
  const [schools, setSchools] = useState([]);
  const [districts, setDistricts ] = useState([]);
  


  useEffect(() => {
    if (!authState) {
        navigate('/login');
    }
    // Dependency array includes authState to react to its changes
}, [authState, navigate]);


  // Fetch Profiles
  useEffect(() => {
    fetchProfiles();
    fetchTeacherStatuses();
    fetchSchools();
    fetchDistricts();
  }, []);

  const fetchProfiles = async () => {
    try {
      const response = await fetch(`${backendURL}/api/profiles`, {
        method: 'GET',
        credentials: 'include', // Make sure to send credentials if your backend requires authentication
      });
      if (!response.ok) throw new Error('Failed to fetch profiles');
      const data = await response.json();
      const teachersProfilesFiltered = data.filter(profile => profile.teacher_status === true);
      if (!teachersProfilesFiltered){
        setErrorMessage('There are no profiles that are set to teachers.');
        return;
      }
      setProfiles(teachersProfilesFiltered);
      setFilteredProfiles(teachersProfilesFiltered); // Set the filtered profiles initially
    } catch (error) {
      setErrorMessage('Error fetching profiles');
    }
  };
  const fetchTeacherStatuses = async () => {
    const response = await fetch(`${backendURL}/api/teacherstatuses`, {
      method: 'GET',
      credentials: 'include',
    });
    const data = await response.json();
    setTeacherStatuses(data);
  };
  const fetchSchools = async () => {
    const response = await fetch(`${backendURL}/api/schools`, {
      method: 'GET',
      credentials: 'include',
    });
    const data = await response.json();
    setSchools(data);
  };
  const fetchDistricts = async () => {
    const response = await fetch(`${backendURL}/api/districts`, {
      method: 'GET',
      credentials: 'include',
    });
    const data = await response.json();
    setDistricts(data);
  };

  const handleShowFormToggle = () => {
    if (!showForm) { // Reset form only when opening the form
      resetForm();
    }
    setShowForm(!showForm);
  };
  const resetForm = () => {
    setEditProfileData(null); // Clear any existing edit data
    setErrorMessage(''); // Clear any error messages
  };

  const handleEditProfile = (profile) => {
    setEditProfileData(profile);
    setShowForm(true);
  };

  const handleDeleteConfirmation = (profileId) => {
    setProfileToDelete(profileId);
    setShowModal(true);
  };

  const handleDeleteProfile = async () => {
    try {
      const response = await fetch(`${backendURL}/api/profiles/${profileToDelete}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (!response.ok) throw new Error('Failed to delete profile');
      setShowModal(false);
      fetchProfiles(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting profile:", error);
      setErrorMessage('This teacher is associated with an event program and all instances of their association must be deleted before this teacher can be deleted.');
      setShowModal(false);
    }
  };

  const getTeacherStatus = (statusId) => {
    const statusObj = teacherStatuses.find(t => t.teacher_status_id === statusId)    
    if (statusObj) {
      return `${statusObj.status}`
      }
  }
  const getSchoolName = (schoolId) => {
    const schoolObj = schools.find(school => school.school_id === schoolId)
    if (schoolObj) {
      return (
        <>{schoolObj.name}</>
      )
    }
  }

  const schoolOptions = schools.map(school_id => {
    const district = districts.find(d => d.district_id === school_id.district_id);
    const districtName = district ? district.name : 'Unknown District';
    
    return {
      label: `${school_id.name} (${districtName})`,
      value: school_id.school_id
    };
  });



  function formatPhoneNumber(number) {

    if(number === null) {
      return 'No number';
    }
    const numStr = number.toString();
  
    // This example formats the string as a US phone number
    // Adjust the substring indices based on your specific needs
    if (numStr.length === 10) { // Standard 10-digit phone number
      return `(${numStr.substring(0,3)}) ${numStr.substring(3,6)}-${numStr.substring(6)}`;
    } else if (numStr.length > 10) { // International format might need different handling
      // Example for an 11-digit number, adjust as necessary
      return `+${numStr.substring(0,1)} (${numStr.substring(1,4)}) ${numStr.substring(4,7)}-${numStr.substring(7)}`;
    }
    return numStr; // Return original string if it's not 10 or 11 digits long
  };
  

  const renderProfileRows = () => filteredProfiles.map(profile => (
    <tr key={profile.profile_id}>
      <td>{profile.first_name}</td>
      <td>{profile.last_name}</td>
      <td>{formatPhoneNumber(profile.cell_phone_number)}</td>
      <td>{formatPhoneNumber(profile.home_phone_number)}</td>
      <td>{profile.cactus_number}</td>
      <td>{profile.email}</td>
      <td>{profile.secondary_email}</td>
      <td>{getSchoolName(profile.school_id)}</td>
      <td>{getTeacherStatus(profile.teacher_status_id)}</td>
      <td>
        <Button variant="primary" onClick={() => handleEditProfile(profile)}><i className="bi bi-pencil-square"></i></Button>
        <Button variant="danger" onClick={() => handleDeleteConfirmation(profile.profile_id)}><i className="bi bi-trash3"></i></Button>
      </td>
    </tr>
  ));

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = profiles.filter(profile =>
      profile.first_name.toLowerCase().includes(value) ||
      profile.last_name.toLowerCase().includes(value)
    );
    setFilteredProfiles(filtered);
  };

  


function ProfileForm({ editData, setErrorMessage, setShowForm, fetchProfiles }) {
  const [ selectedSchool, setSelectedSchool ] = useState(null);
  const [ selectedDistrict, setSelectedDistrict] = useState(null);
  const [formData, setFormData] = useState({
    first_name: null,
    last_name: null,
    email: null,
    secondary_email: null,
    cell_phone_number: null,
    home_phone_number: null,
    cactus_number: null,
    street: null,
    city: null,
    state: null,
    zip: null,
    teacher_status: true,
    judge_status: null,
    school_id: null,
    district_id: null, 
  });

  useEffect(() => {
    if (editData) {
      setFormData({
        first_name: editData.first_name || null,
        last_name: editData.last_name || null,
        email: editData.email || null,
        secondary_email: editData.secondary_email || null,
        cell_phone_number: editData.cell_phone_number || null,
        home_phone_number: editData.home_phone_number || null,
        cactus_number: editData.cactus_number || null,
        street: editData.street || null,
        city: editData.city || null,
        state: editData.state || null,
        zip: editData.zip || null,
        teacher_status_id: editData.teacher_status_id || null,
        teacher_status: editData.teacher_status || null,
        judge_status: editData.judge_status || null,
        school_id: editData.school_id || null,
        district_id: editData.district_id || null,
        requires_email: true
      });
      let school = schoolOptions.find(school => school.value === editData.school_id)
      setSelectedSchool(school);
    }
  }, [editData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "cactus_number" || name === "cell_phone_number"){
      let validInt = parseInt(value, 10);
      if (isNaN(validInt)){
        validInt = null;
      }

      return setFormData(prev => ({...prev,
         [name]: validInt
        }));

    }
    setFormData(
      prev => ({ ...prev,
         [name]: value,
        requires_email: true }));
  };

  const handleSchoolChange = selectedOption => {
    setSelectedSchool(selectedOption);
    let schoolId = null;
    if (selectedOption) {
        schoolId = parseInt(selectedOption.value, 10);
    if (isNaN(schoolId)) {
      setErrorMessage(`Warning: could not parse school_id value "${selectedOption.value}" as an integer`);
      schoolId = null;
      }};
    setFormData({
      ...formData,
      school_id: schoolId
      });
    const selectedSchoolObj = schools.find(school_id => school_id.school_id === schoolId);
    if (selectedSchoolObj)  {
      // Find the corresponding district object
      const correspondingDistrict = districts.find(district => district.district_id === selectedSchoolObj.district_id);
  
    if (correspondingDistrict) {
        // Update the district in the state
        setSelectedDistrict(correspondingDistrict);

        // Update the district in the formData
        setFormData(prevFormData => ({
            ...prevFormData,
            district: correspondingDistrict.district_id
        }));
      };
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = editData ? `${backendURL}/api/profiles/${editData.profile_id}` : `${backendURL}/api/profiles`;
    const method = editData ? 'PUT' : 'POST';

    try {
      const response = await fetch(endpoint, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to submit the form');
      }

      fetchProfiles(); // Refresh the profiles list
      setShowForm(false); // Close the form
      setErrorMessage(''); // Clear any previous error messages
    } catch (error) {
      console.error("Form submission error:", error);
      setErrorMessage(error.message || 'Error submitting form');
    }
  };

  const handleCancel = () => {
    setFormData({ // Reset form data to initial state
      first_name: null,
      last_name: null,
      email: null,
      secondary_email: null,
      cell_phone_number: null,
      home_phone_number: null,
      cactus_number: null,
      street: null,
      city: null,
      state: null,
      zip: null,
      teacher_status_id: null,
      teacher_status: null,
      judge_status: null,
      school_id: null,
      district_id: null
    });
    setErrorMessage(''); // Clear any error messages
    setShowForm(false); // Close the form
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="bold-label" controlId="first_name">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter first name"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className="bold-label" controlId="last_name">
        <Form.Label>Last Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter last name"
          name="last_name"
          value={formData.last_name}
          onChange={handleChange}
          required
        />
      </Form.Group>

      <Form.Group className='bold-label' controlId='cell_phone_number'>
        <Form.Label>Cell Phone Number</Form.Label>
        <Form.Control
        type='number'
        placeholder='Enter cell phone number'
        name='cell_phone_number'
        value={formData.cell_phone_number}
        onChange={handleChange}
        >
        </Form.Control>
      </Form.Group>

      <Form.Group className='bold-label' controlId='home_phone_number'>
        <Form.Label>home Phone Number</Form.Label>
        <Form.Control
        type='number'
        placeholder='Enter home phone number'
        name='home_phone_number'
        value={formData.home_phone_number}
        onChange={handleChange}
        >
        </Form.Control>
      </Form.Group>

      <Form.Group className='bold-label' controlId='cactus_number'>
        <Form.Label>Cactus Number</Form.Label>
        <Form.Control
        type='number'
        name='cactus_number'
        value={formData.cactus_number}
        onChange={handleChange}>
        </Form.Control>
      </Form.Group>

      <Form.Group className="bold-label" controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter "
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group className="bold-label" controlId="secondary_email">
        <Form.Label>Secondary Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter "
          name="secondary_email"
          value={formData.secondary_email}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="school_id">
          <Form.Label className="bold-label">School (District) </Form.Label>
          <Select 
              isSearchable={true}
              options={schoolOptions}
              value={selectedSchool}
              onChange={handleSchoolChange}
              inputId="school_id"
          />
      </Form.Group>
      <Form.Group>
          <Form.Label className="bold-label">Teacher Status</Form.Label>
          <Form.Control
          as={'select'}
          name='teacher_status_id'
          onChange={handleChange}
          value={formData.teacher_status_id}
          >
            <option value={''}>Select Teacher Status</option>
            {teacherStatuses.map(status => (
              <option 
              key={status.teacher_status_id}
              value={status.teacher_status_id}
               >{status.status}</option>
            ))}
          </Form.Control>
        </Form.Group>

      {/* Repeat for other fields as necessary... */}

      <Button variant="primary" type="submit">
        Submit
      </Button>
      <Button variant="secondary" onClick={handleCancel} className="ml-2">
        Cancel
      </Button>
    </Form>
  );
}


  return (
    <div className="profile-management">
      <Button onClick={handleShowFormToggle}>
        {showForm ? 'Close Form' : 'Add New Profile'}
      </Button>

      {showForm ? (
        <ProfileForm
          editData={editProfileData}
          setErrorMessage={setErrorMessage}
          setShowForm={setShowForm}
          fetchProfiles={fetchProfiles}
        />
      ): 

      <div>
        <Form.Control
            type="text"
            placeholder="Search by name"
            value={searchTerm}
            onChange={handleSearch}
            className="my-3"
          />
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Cell Phone Number</th>
              <th>Home Phone Number</th>
              <th>Cactus Number</th>
              <th>Email</th>
              <th>Secondary Email</th>
              <th>School</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {renderProfileRows()}
          </tbody>
        </Table>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this profile?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
            <Button variant="danger" onClick={handleDeleteProfile}>Delete</Button>
          </Modal.Footer>
        </Modal>
      </div>
      }


      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <ErrorMessage errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage()} />
    </div>
  );
}

export default TeacherProfile;
