import React, { useEffect, useState } from "react";
import NavbarComponent from "../Nav";
import { useAuth } from "../store/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import ErrorModalMessage from "../store/ErrorMessage";
import { backendURL } from "../IPaddress";
import { Button, Modal, Form, Table, Dropdown} from "react-bootstrap";
import Select from 'react-select';
import { useSchoolYear } from "../store/SchoolYearCalculator";


const AdjudicationEvent = () => {
    const { authState } = useAuth();
    const navigate = useNavigate();
    const [ errorMessage, setErrorMessage] = useState(null);
    const [ eventPrograms, setEventPrograms ] = useState([]);
    const [ eventDates, setEventDates ] = useState([]);
    const [ eventTypes, setEventTypes ] = useState([]);
    const [ adjudicationTypes, setAdjudicationTypes ] = useState([]);
    const [ currentProgram, setCurrentProgram ] = useState({
        event_id: null,
        event_template_id: null,
        name: '',
        description: '',
        location_id: null,
        coordinator_id: null,
        event_type_id: null,
        classroom_id: null,
        teacher_profile_id: null,
        judge_1_id: null,
        judge_2_id: null,
        school_id: null,
        district_id: null,
        students_in_cast: null,
        double_cast: false,
        judge_1_report_submitted: false,
        judge_2_report_submitted: false,
        is_one_on_one: false,
        number_of_students: null,
        number_of_teachers: null,
        number_of_events: null,
        adjudication_type_id: null,
        max_free_seats: null,
        uhsmta_teacher_id: null
    });
    const [ currentEventDate, setCurrentEventDate] = useState({
        event_dates_id: null,
        event_id: null,
        date: null,
        ticket_price: null,
        start_time: null,
        end_time: null,
        judge_attendance_date: null,
        date_adjudicated: null
    });
    const [ adjudicationFeedback, setAdjudicationFeedback ] = useState([]);
    const [ adjudicationModal, setAdjudicationModal ] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [ schools, setSchools] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [ teachers, setTeachers ] = useState([]);
    const [ judges, setJudges ] = useState([]);
    const [programToDelete, setProgramToDelete] = useState({});
    const [eventDateToDelete, setEventDateToDelete] = useState({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [selectedSchoolYear, setSelectedSchoolYear] = useState();
    const {currentYear, schoolYearListParent} = useSchoolYear();



    useEffect(() => {
        if(!authState){
            navigate('/login')
        }
    }, [authState, navigate]);


    useEffect(() => {
        if (currentYear && schoolYearListParent.length) {
          setSelectedSchoolYear(currentYear);
          setSchoolYearList(schoolYearListParent);
        }
    }, [currentYear, schoolYearListParent]);
      
    useEffect(() => {
        if (selectedSchoolYear) {
            fetchEventPrograms();
            fetchEventDates();
        }
    }, [selectedSchoolYear]);

    useEffect(() => {
        fetchEventTypes();
        fetchAdjudicationTypes();
        fetchSchools();
        fetchDistricts();
        fetchJudges();
        fetchUhsmtaTeachers();
    }, []);


    const fetchEventPrograms = async () => {
        const response = await fetch(`${backendURL}/api/events-programs/year/${selectedSchoolYear}`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok){
            const data = await response.json();
            setEventPrograms(data)
            filterAdjudicationFeedback(data);
        } else {
            setErrorMessage('Failed to retrieve event program information');
            return;
        }
    };
    const fetchEventDates = async () => {
        const response = await fetch(`${backendURL}/api/event-dates/year/${selectedSchoolYear}`, {
            method: 'GET',
            credentials: 'include'
        });
        if (response.ok){
            const data = await response.json();
            const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
      setEventDates(sortedData);
        } else {
            setErrorMessage('Failed to retrieve the event date information');
            return;
        };
    };
    const fetchEventTypes = async () => {
        const response = await fetch(`${backendURL}/api/event-types`, {
            method: 'GET',
            credentials: 'include'
        });
        if (response.ok) {
            const data = await response.json();
            setEventTypes(data);
        } else {
            setErrorMessage('Failed to retrieve the event type data.');
            return;
        }
    };
    const fetchAdjudicationTypes = async () => {
        const response = await fetch(`${backendURL}/api/adjudication-types`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const data = await response.json();
            setAdjudicationTypes(data);
        }
    };

    const fetchSchools = async () => {
    // setLoading(prev => ({ ...prev, schools: true }));
    const response = await fetch(`${backendURL}/api/schools`);
    
    if (!response.ok) {
        setErrorMessage('Unable to retrieve school information');
    };
    const data = await response.json();
    
    if (Array.isArray(data)) {
        setSchools(data);

    } else {
        setErrorMessage('Fetched Schools data is not an array:', data);
    }};
    const fetchDistricts = async () => {
        const url = `${backendURL}/api/districts`;
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          setDistricts(data);
        } else {
          setErrorMessage('Unable to retrieve districts');
        }
    };
    const fetchJudges = async() => {
        const response = await fetch(`${backendURL}/api/profiles`, {
          method:'GET',
          credentials: 'include'
        });
        if (!response.ok) {
          setErrorMessage('Unable to fetch Judges.');
        }
        const data = await response.json();
        const judgeProfiles = data.filter(profile => profile.judge_status === true);
        setJudges(judgeProfiles);
    };
    const fetchUhsmtaTeachers = async () => {
        const response = await fetch (`${backendURL}/api/uhsmta-teachers`, {
            method: 'GET',
            credentials: 'include'
        });
        if (!response) {
            setErrorMessage('Unable to fetch UHSMTA Teachers');
        }
        const data = await response.json();
        setTeachers(data);
    }
    
    const filterAdjudicationFeedback = (programs) => {
        const filteredFeedback = (programs.filter( program => program.event_type_id === 5));
        if (filteredFeedback){
            setAdjudicationFeedback(filteredFeedback);
        } else {
            setAdjudicationFeedback([]);
        }
    }

    const schoolOptions = schools.map(school_id => {
        const district = districts.find(d => d.district_id === school_id.district_id);
        const districtName = district ? district.name : 'Unknown District';
        
        return {
          label: `${school_id.name} (${districtName})`,
          value: school_id.school_id
        };
      });



    const handleProgramChange = (field, value, type = 'text') => {

        if(type === 'adjudication_type_id' || type==='students_in_cast'|| type === 'judge_1_id' || type === 'judge_2_id' || type === 'uhsmta_teacher_id') {
            // let validValue = parseInt(value, 10);

            // if (isNaN(validValue)){
            //     validValue = null;
            // }

            setCurrentProgram(prevState => ({
                ...prevState, 
                [field]:parseInt(value, 10) || null,
                school_year_id: parseInt(selectedSchoolYear, 10),
            }));
        } else {
            setCurrentProgram(prevState => ({
                ...prevState,
                [field]: type === 'checkbox' ? value.checked : value,
                school_year_id: parseInt(selectedSchoolYear, 10),
            }));
        }
    };


    const handleEventDateChange = (field, value, type = 'text') => {
        setCurrentEventDate(prevState => ({
            ...prevState,
            [field]: type === 'checkbox' ? value.checked : value,
            school_year_id: parseInt(selectedSchoolYear, 10),
        }));
    };

    const handleSchoolChange = selectedOption => {

        setSelectedSchool(selectedOption);
        let schoolId = null;
        if (selectedOption) {

            schoolId = parseInt(selectedOption.value, 10);
            
            if (isNaN(schoolId)) {
                console.warn(`Warning: could not parse school_id value "${selectedOption.value}" as an integer`);
                schoolId = null;
            };
        };
        const school = schools.find(school => school.school_id === selectedOption.value)
        const district = districts.find(d => d.district_id === school.district_id);

        setCurrentProgram({
        ...currentProgram,
        school_id: schoolId,
        district_id: district.district_id,
        school_year_id: parseInt(selectedSchoolYear, 10),
        });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check for null values in school_id and date
        if (currentProgram.school_id == null) {
            setErrorMessage("School selection cannot be empty.");
            return;
        }
    
        if (currentEventDate.judge_attendance_date == null) {
            setErrorMessage("Date Attending for Judges cannot be empty.");
            return;
        }
    
        // Reset the error message if validation passes
        setErrorMessage(null);
    
        // Handle creating or updating the adjudication
        if (!currentProgram.event_id) {
            await createAdjudication(currentProgram, currentEventDate);
        } else {
            await updateAdjudication(currentProgram, currentEventDate);
        }
        setCurrentProgram({
            event_id: null,
            event_template_id: null,
            name: '',
            description: '',
            location_id: null,
            coordinator_id: null,
            event_type_id: null,
            classroom_id: null,
            teacher_profile_id: null,
            judge_1_id: null,
            judge_2_id: null,
            school_id: null,
            district_id: null,
            students_in_cast: null,
            double_cast: false,
            judge_1_report_submitted: false,
            judge_2_report_submitted: false,
            is_one_on_one: false,
            number_of_students: null,
            number_of_teachers: null,
            number_of_events: null,
            adjudication_type_id: null,
            max_free_seats: null,
            uhsmta_teacher_id: null
        });
        setCurrentEventDate({
            event_dates_id: null,
            event_id: null,
            date: null,
            ticket_price: null,
            start_time: null,
            end_time: null,
            judge_attendance_date: null,
            date_adjudicated: null
        });
        fetchEventPrograms();
        fetchEventDates();
    };

    const editEventProgram = async (programEvent, eventDate)=> {
        const schoolObj = schoolOptions.find(school => school.value === programEvent.school_id)
        if (schoolObj) {
            setSelectedSchool(schoolObj)
        };
        setCurrentProgram(programEvent);
        // console.log("event Date: ", eventDate)
        setCurrentEventDate(eventDate);
        setAdjudicationModal(true);       

    }

    const createAdjudication = async (programData, eventDateData) => {
  
        // Step 1: Create the events_program
        try {
            const programResponse = await fetch(`${backendURL}/api/events-programs`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(programData),
                credentials: 'include'
            });
    
            if (!programResponse.ok) {
                throw new Error('Failed to create program event');
            }
    
            const programDataEvent = await programResponse.json();
    
            // Step 2: Create the event_date using the event_id from the created events_program
            
            if (programDataEvent && programDataEvent.event_id) {
                eventDateData.event_id = programDataEvent.event_id; // Set the event_id for the event date

                const dateResponse = await fetch(`${backendURL}/api/event-dates`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(eventDateData),
                    credentials: 'include'
                });
    
                if (!dateResponse.ok) {
                    throw new Error('Failed to create event date for the related program.');
                }
    
                const dateData = await dateResponse.json();
                handleCancel();
                fetchEventPrograms();
                fetchEventDates();
                return { programData, dateData }; // Return both data objects if needed
            } else {
                throw new Error('Missing event_id from the created events program.');
            }
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error:', error);
            return null; // Return null or appropriate value indicating failure
        }
    };
    

    const updateAdjudication = async (programData, eventDateData) => {
        try {
            const response = await fetch(`${backendURL}/api/events-programs/${programData.event_id}`, {
                method: 'PUT',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(programData)
            });
            const response2 = await fetch(`${backendURL}/api/event-dates/${eventDateData.event_dates_id}`, {
                method: 'PUT',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(eventDateData)
            });
            handleCancel();
        }
        catch {
            setErrorMessage("Something broke while trying to update the program and event date")
        }
    }

    const deleteAdjudication = async () => {
        try {

            await fetch(`${backendURL}/api/event-dates/${eventDateToDelete.event_dates_id}`, {
                method: 'DELETE',
                credentials: 'include'
            });

            await fetch(`${backendURL}/api/events-programs/${programToDelete.event_id}`, {
                method: 'DELETE',
                credentials: 'include'
            });            
            fetchEventPrograms();
            fetchEventDates();
            handleCancel();
        }
        catch {
            setErrorMessage('There was an error deleting the program event or event date.')
        }
    }


    const handleCancel = () => {
        setAdjudicationModal(false);
        setCurrentProgram({
            event_id: null,
            event_template_id: null,
            name: '',
            description: '',
            location_id: null,
            coordinator_id: null,
            event_type_id: null,
            classroom_id: null,
            teacher_profile_id: null,
            judge_1_id: null,
            judge_2_id: null,
            school_id: null,
            district_id: null,
            students_in_cast: null,
            double_cast: false,
            judge_1_report_submitted: false,
            judge_2_report_submitted: false,
            is_one_on_one: false,
            number_of_students: null,
            number_of_teachers: null,
            number_of_events: null,
            adjudication_type_id: null,
            max_free_seats: null,
            uhsmta_teacher_id: null
        });
        setCurrentEventDate({
            event_dates_id: null,
            event_id: null,
            date: null,
            ticket_price: null,
            start_time: null,
            end_time: null,
            judge_attendance_date: null,
            date_adjudicated: null,
            rehearsal_begin_time: null,
            rehearsal_end_time: null,
            performance_begin_time: null,
            performance_end_time: null
        });
        setEventDateToDelete({});
        setProgramToDelete({});
        setSelectedSchool(null);
        setShowDeleteConfirmation(false);
    }

    const getJudgeName = (judgeId) => {
        const judgeObj = judges.find(profile => profile.profile_id === judgeId)
        if (judgeObj) {
            return (
                `${judgeObj.first_name} ${judgeObj.last_name}`
            )
        } else {
            return(
                <></>
            );
        }
    }
    const getTeacherName = (teacherId) => {
        const teacherObj = teachers.find(profile => profile.uhsmta_teachers_id === teacherId)
        if (teacherObj) {
            return (
                `${teacherObj.first_name} ${teacherObj.last_name}`
            )
        } else {
            return(
                <></>
            );
        }
    }
    const getSchoolName = (schoolId) => {
        const schoolObj = schoolOptions.find(school => school.value === schoolId)
        if (schoolObj) {

            return (
                <div>{schoolObj.label}</div>
            )
        } 
    }
    const getAdjudicationType = (typeId) => {
        const typeObj = adjudicationTypes.find(type => type.type_id === typeId)
        if (typeObj) {
            return (
                `${typeObj.type}`
            )
        } else {
            return(
                <></>
            );
        }
    }
    const getEventDateAdjudication = (eventProgramId) => {
        const adjudicationDateObj = eventDates.find(date => date.event_id === eventProgramId);
        if (adjudicationDateObj) {
            return (
            <>{adjudicationDateObj.date_adjudicated}</>);
        };
    };
    const getEventDateJudgeAttendance = (eventProgramId) => {
        const adjudicationDateObj = eventDates.find(date => date.event_id === eventProgramId);
        if (adjudicationDateObj) {
            return (
            <>{adjudicationDateObj.judge_attendance_date}</>);
        };
    };

    const getEventDateObj = (eventProgramId) => {
        const adjudicationDateObj = eventDates.find(date => date.event_id === eventProgramId);
        if (adjudicationDateObj) {
            return (adjudicationDateObj);
        };
    };

    const handleYearChange = (event) => {
        setSelectedSchoolYear(event.target.value);
      };


    const AdjudicationFeedbackComponent = () => {
        
        return (
            <div>
                <Button onClick={() => 
                    {setAdjudicationModal(true)
                    setCurrentProgram(prevState => ({
                        ...prevState,
                        'event_type_id': 5 // A. UHSMTA Adjudication/Feedback in the database. 
                    }))}
                    }>
                Record Adjudication
                </Button>
                <div>
                    <Dropdown>
                        <span className='fw-bold'>Select School Year</span>
                    <select value={selectedSchoolYear} onChange={handleYearChange}>
                        <option value="" disabled>Select School Year</option>
                        {schoolYearList.map((year) => (
                            <option key={year.id} value={year.id}>
                            {year.school_year}
                            </option>
                        ))}
                        </select>
                    </Dropdown>
                </div>

                <h4>Adjudication List</h4>
            <div>
    {adjudicationFeedback && adjudicationFeedback.length > 0 ? (
        <div className="scrollable-table">
            
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {/* <th>Event Type</th> */}
                        {/* <th>Description</th> */}
                        <th>School</th>
                        <th>Judge 1</th>
                        <th>Judge 2</th>
                        <th>Written Report Submitted</th>
                        <th>Date Judges Attended</th>
                        <th>Date Adjudicated</th>
                        <th>Number of Students in Cast</th>
                        <th>Double Cast</th>
                        <th>Teacher Contact</th>
                        <th>Adjudication Type</th>
                        
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {adjudicationFeedback.map(event => (
                        <tr key={event.event_id}>{/* Make sure each event has a unique `event_id` or similar identifier */}
                            {/* <td>{event.event_type_id}</td> */}
                            {/* <td>{event.description}</td> */}
                            <td>{getSchoolName(event.school_id)}</td>
                            <td>{getJudgeName(event.judge_1_id)}</td>
                            <td>{getJudgeName(event.judge_2_id)}</td>
                            <td>
                                <div>Judge 1: {event.judge_1_report_submitted ? 'Yes' : 'No'}</div>
                                <div>Judge 2: {event.judge_2_report_submitted ? 'Yes' : 'No'}</div>
                            </td>
                            <td>{getEventDateJudgeAttendance(event.event_id)}</td>
                            <td>{getEventDateAdjudication(event.event_id)}</td>
                            <td>{event.students_in_cast}</td>
                            <td>{event.double_cast ? 'Yes' : 'No'}</td>
                            <td>{getTeacherName(event.uhsmta_teacher_id)}</td>
                            <td>{getAdjudicationType(event.adjudication_type_id)}</td>
                            
                            <td>
                                <Button className="m-1" onClick={() => editEventProgram(event, getEventDateObj(event.event_id))}><i className="bi bi-pencil"></i></Button>
                                <Button variant="danger" className="m-1" onClick={() => {
                                    setProgramToDelete(event);
                                    setEventDateToDelete(getEventDateObj(event.event_id));
                                    setShowDeleteConfirmation(true);
                                    }}><i className="bi bi-trash3"></i></Button> 
                            </td>

                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    ) : 'No Adjudication reports submitted.'}
</div>

            </div>
        )
    }

    return (
        <div>
            <div>
                <AdjudicationFeedbackComponent/>
            </div>
            <Modal show={adjudicationModal} onHide={handleCancel}>
                <Modal.Header closeButton>
                <Modal.Title>{currentProgram.event_id ? 'Update Adjudication' : 'Create Adjudication'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* Judge 1 */}
                        <Form.Group controlId="judge1Id">
                            <Form.Label>Judge 1</Form.Label>
                            <Form.Control
                            as={'select'}
                            value={currentProgram.judge_1_id}
                            onChange={e => handleProgramChange('judge_1_id', e.target.value, 'judge_1_id')}
                            >
                            <option value={null}>Select a Judge</option>
                            {judges.map(judge =>(
                                <option value={judge.profile_id} key={judge.profile_id}>{judge.first_name} {judge.last_name}</option>
                            ))}
                            </Form.Control>
                        </Form.Group>

                        {/* Judge 2 */}
                        <Form.Group controlId="judge2Id">
                            <Form.Label>Judge 2</Form.Label>
                            <Form.Control
                            as={'select'}
                            value={currentProgram.judge_2_id}
                            onChange={e => handleProgramChange('judge_2_id', e.target.value, 'judge_2_id')}
                            >
                            <option value={null}>Select a Judge</option>
                            {judges.map(judge =>(
                                <option value={judge.profile_id} key={judge.profile_id}>{judge.first_name} {judge.last_name}</option>
                            ))}
                            </Form.Control>
                        </Form.Group>

                        {/* Date attending for judges */}
                        <Form.Group controlId="judgeAttendanceDate">
                            <Form.Label>Date Attending for Judges</Form.Label>
                            <Form.Control
                            type="date"
                            value={currentEventDate.judge_attendance_date || ''}
                            onChange={e => handleEventDateChange('judge_attendance_date', e.target.value)}
                            />
                        </Form.Group>
                        {/* Written report submitted */}
                        <Form.Group controlId="judge_1_report_submitted">
                            <Form.Label>
                                <Form.Check
                                type="checkbox"
                                label='Judge 1 report submitted'
                                checked={currentProgram.judge_1_report_submitted}
                                onChange={e => handleProgramChange('judge_1_report_submitted', e.target.checked)}
                                />
                            </Form.Label>
                        </Form.Group>
                        {/* Written report submitted */}
                        <Form.Group controlId="judge_2_report_submitted">
                            <Form.Label>
                                <Form.Check
                                type="checkbox"
                                label='Judge 2 report submitted'
                                checked={currentProgram.judge_2_report_submitted}
                                onChange={e => handleProgramChange('judge_2_report_submitted', e.target.checked)}
                                />
                            </Form.Label>
                        </Form.Group>

                        {/* School */}
                        <Form.Group controlId="school_id">
                            <Form.Label>School</Form.Label>
                            <Select 
                                isSearchable={true}
                                options={schoolOptions}
                                value={selectedSchool}
                                onChange={handleSchoolChange}
                                inputId="school_id"
                                required
                                />
                        </Form.Group>

                        {/* Date adjudicated */}
                        <Form.Group controlId="dateAdjudicated">
                            <Form.Label>Date Adjudication Report Submitted</Form.Label>
                            <Form.Control
                            type="date"
                            value={currentEventDate.date_adjudicated || ''}
                            onChange={e => handleEventDateChange('date_adjudicated', e.target.value)}
                            />
                        </Form.Group>

                        {/* Number of students in cast */}
                        <Form.Group controlId="studentsInCast">
                            <Form.Label>Number of Students in Cast</Form.Label>
                            <Form.Control
                            type="number"
                            value={currentProgram.students_in_cast || ''}
                            onChange={e => handleProgramChange('students_in_cast', e.target.value, 'students_in_cast')}
                            />
                        </Form.Group>

                        {/* Double Cast */}
                        <Form.Group controlId="doubleCast">
                            <Form.Label>
                                <Form.Check
                                type="checkbox"
                                label='Double Cast'
                                checked={currentProgram.double_cast}
                                onChange={e => handleProgramChange('double_cast', e.target.checked)}
                                />
                            </Form.Label>
                        </Form.Group>

                        {/* Teacher's contact info */}
                        <Form.Group controlId="uhsmta_teacher_id">
                            <Form.Label>Teacher Contact</Form.Label>
                            <Form.Control 
                            as={'select'}
                            value={currentProgram.uhsmta_teacher_id}
                            onChange={e => handleProgramChange('uhsmta_teacher_id', e.target.value, 'uhsmta_teacher_id')}
                            >
                            <option value={''}>Select a Teacher</option>
                            {teachers.map(teacher =>(
                                <option value={teacher.uhsmta_teachers_id} key={teacher.uhsmta_teachers_id}>{teacher.first_name} {teacher.last_name}</option>
                            ))}
                            </Form.Control>
                        </Form.Group>

                        {/* Adjudication Type */}
                        <Form.Group controlId="adjudicationTypeId">
                            <Form.Label>Adjudication Type</Form.Label>
                            <Form.Select
                            value={currentProgram.adjudication_type_id || ''}
                            onChange={e => handleProgramChange('adjudication_type_id', e.target.value, 'adjudication_type_id')}>
                                <option value="">Select Type</option>
                                {adjudicationTypes.map( type => (
                                    <option value={type.type_id} key={type.type_id}>{type.type}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>{currentProgram.event_id ? 'Update' : 'Create'}</Button>
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                    {/* {currentProgram.event_id ? <Button variant="danger">Delete</Button> : ''} */}
                </Modal.Footer>
                </Modal>
                <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
                    <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this adjudication for {getSchoolName(programToDelete.school_id)}?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={deleteAdjudication}>
                        Delete
                    </Button>
                    </Modal.Footer>
                </Modal>
            <ErrorModalMessage errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage('')}></ErrorModalMessage>
        </div>
    );
}

export default AdjudicationEvent;
