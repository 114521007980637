import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { Form, Button, Alert, Modal, Dropdown } from 'react-bootstrap';
import './CSS/FormSubmission.css';
import { backendURL } from './IPaddress';
import { useAuth } from './store/AuthContext';
import NavbarComponent from './Nav';
import ErrorModalMessage from './store/ErrorMessage';
import { useSchoolYear } from './store/SchoolYearCalculator';


const FormSubmission = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        student_name: '',
        teacher_status: false,
        cactus_number: '',
        school_id: '',
        district: '',
        event_program_id: '',
        event_date_id: '',
        grade_id: '',
        additional_contact: false,
        street: '',
        city: '',
        state: '',
        zip: '',
        names_to_sit_by: '',
        cactus_number_verified: null,
        school_year_id: ''
    });
    const [loading, setLoading] = useState({
      grades: false,
      schools: false,
      eventPrograms: false,
      eventDates: false
  });
  const [error, setError] = useState({
      grades: null,
      schools: null,
      eventPrograms: null,
      eventDates: null
  });
    
    const [grades, setGrades] = useState([]);
    const [schools, setSchools] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [eventPrograms, setEventPrograms] = useState([]);
    const [eventDates, setEventDates] = useState([]);
    const [selectedEventProgram, setSelectedEventProgram] = useState(null);
    const [homeSchoolModal, setHomeSchoolModal] = useState(false);
    const [privateSchoolModal, setPrivateSchoolModal] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [ineligibleGradeModal, setIneligibleGradeModal] = useState(false);
    const [submitSuccess, setSubmitSuccess ] = useState(false);
    const { authState } = useAuth();
    const [ teacherStatus, setTeacherStatus ] = useState(false);
    const [formCommit, setFormCommit ] = useState(false);
    const [errorMessage, setErrorMessage ] = useState(null);
    const [USAstates, setUSAstates] = useState([]);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [selectedSchoolYear, setSelectedSchoolYear] = useState();
    const {currentYear, schoolYearListParent} = useSchoolYear();

    // Fetch data from APIs


    useEffect(() => {
        if (currentYear && schoolYearListParent.length) {
            setSelectedSchoolYear(currentYear);
            setSchoolYearList(schoolYearListParent);
        }
      }, [currentYear, schoolYearListParent]);
      
    useEffect(() => {
        if (selectedSchoolYear) {
            fetchEventPrograms();
            fetchEventDates();
            setFormData({
                ...formData,
                school_year_id: currentYear
            });
        }
    }, [selectedSchoolYear]);

    useEffect(() => {
      fetchUSAStates();
      fetchGrades();
      fetchSchools();
      fetchDistricts();
  }, []);


  const fetchGrades = async () => {
    const url = `${backendURL}/api/grades`
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();

      if (Array.isArray(data)) {
        setGrades(data);
    } else {
        setErrorMessage('Error fetching grade information. Please try again later!')
        console.error('Fetched grades data is not an array:', data);
        return;
    }}
  };
  const fetchDistricts = async () => {
    const url = `${backendURL}/api/districts`;
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      setDistricts(data);
    }
  }

  const fetchSchools = async () => {
    setLoading(prev => ({ ...prev, schools: true }));
    const response = await fetch(`${backendURL}/api/schools`);
    
    if (!response.ok) throw new Error('Failed to fetch schools.');
    const data = await response.json();
    
    if (Array.isArray(data)) {
      setSchools(data);
  } else {
    setErrorMessage('Error fetching school data. Please try again later!')
    console.error('Fetched Schools data is not an array:', data);
    return;

  }}

  const fetchEventPrograms = async () => {
      try {
          setLoading(prev => ({ ...prev, eventPrograms: true }));
          const response = await fetch(`${backendURL}/api/events-programs/year/${currentYear}`);
          if (!response.ok) throw new Error('Failed to fetch event programs.');
          let data = await response.json();

          // Filtering the data for event_type_id of 4
          data = data.filter(eventProgram => eventProgram.event_type_id === 4);
          setEventPrograms(data);
      } catch (err) {
          setError(prev => ({ ...prev, eventPrograms: err.message }));
      } finally {
          setLoading(prev => ({ ...prev, eventPrograms: false }));
      }
  };

  const fetchEventDates = async () => {
    try {
        const response = await fetch(`${backendURL}/api/event-dates/year/${currentYear}`);
        if (!response.ok) throw new Error('Failed to fetch event dates.');
        const data = await response.json();
        setEventDates(data);

    } catch (err) {
        setErrorMessage('Error fetching dates data. Please try again later!')
        console.error(err.message);
        return;
    }
  };

  const fetchUSAStates = async () => {
    try {
        const response = await fetch(`${backendURL}/api/states`, {
            method: 'GET'
        })
        if (!response.ok) {
            setErrorMessage('Unable to retrieve list of USA states.')
        }
        const data = await response.json();
        setUSAstates(data);
    } catch{
        setErrorMessage('Failed to retrieve list of USA states.')
    }
  };

    const schoolOptions = schools.map(school_id => {
      const district = districts.find(d => d.district_id === school_id.district_id);
      const districtName = district ? district.name : 'Unknown District';
      
      return {
        label: `${school_id.name} (${districtName})`,
        value: school_id.school_id
      };
    });

    const relevantEventDates = useMemo(() => {
      if (!selectedEventProgram) return [];

      return eventDates
        .filter(event_date_id => event_date_id.event_id === Number(selectedEventProgram.value))
        .map(event_date_id => {
            // Parse the start_time and create a Date object. Assuming start_time is in 'HH:mm:ss' format
            const timeParts = event_date_id.start_time.split(':');
            const date = new Date();
            date.setHours(parseInt(timeParts[0], 10), parseInt(timeParts[1], 10), parseInt(timeParts[2], 10));
          
            // Format the date object to a 12-hour time with AM/PM
            const formattedTime = date.toLocaleTimeString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true
            });
                    
            return {
              label: `${ event_date_id.sold_out ? `${event_date_id.date} ${formattedTime} is sold out`:`${event_date_id.date} ${formattedTime}`}`,
              value: event_date_id.event_dates_id,
              isDisabled: event_date_id.sold_out
            };
          });
          
    }, [eventDates, selectedEventProgram]);
    

    const handleEventProgramChange = selectedOption => {
      setSelectedEventProgram(selectedOption);
      setFormData({
        ...formData,
        event_program_id: selectedOption ? parseInt(selectedOption.value, 10) : null,
        event_date_id: null  // resetting event date when event program changes
    });
    };

    const handleEventDateChange = (selectedOption) => {
      setFormData({
          ...formData,
          event_date_id: selectedOption ? selectedOption.value : null
      });
  };  


  const handleSchoolChange = selectedOption => {
        setSelectedSchool(selectedOption);
        let schoolId = null;
        if (selectedOption) {
            schoolId = parseInt(selectedOption.value, 10);
        if (isNaN(schoolId)) {
            setErrorMessage(`Warning: could not parse school_id value "${selectedOption.value}" as an integer`);
            schoolId = null;
            }}
            setFormData({
            ...formData,
            school_id: schoolId
            });

            const selectedSchoolObj = schools.find(school_id => school_id.school_id === schoolId);
        if (selectedOption && selectedOption.label.includes("(Private)")) {
            setPrivateSchoolModal(true);
            setDisableSubmit(true);
            // Optionally, you may clear the form and selections here
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                student_name: '',
                teacher_status: false,
                cactus_number: '',
                school_id: '',
                district: '',
                event_program_id: '',
                event_date_id: '',
                grade_id: '',
                additional_contact: false,
                street: '',
                city: '',
                state: '',
                zip: '',
                names_to_sit_by: '',
                cactus_number_verified: null
            });
            setSelectedSchool(null);
            setSelectedEventProgram(null);} 
        else if (selectedSchoolObj && selectedSchoolObj.name === "Home-School") {
            setHomeSchoolModal(true);
            setDisableSubmit(true);
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                student_name: '',
                teacher_status: false,
                cactus_number: '',
                school_id: '',
                district: '',
                event_program_id: '',
                event_date_id: '',
                grade_id: '',
                additional_contact: false,
                street: '',
                city: '',
                state: '',
                zip: '',
                names_to_sit_by: '',
                cactus_number_verified: null
            });
            setSelectedSchool(null);
            setSelectedEventProgram(null);
        } else {
            setDisableSubmit(false);
        }
    
        if (selectedSchoolObj)  {
            // Find the corresponding district object
            const correspondingDistrict = districts.find(district => district.district_id === selectedSchoolObj.district_id);
        
        if (correspondingDistrict) {
            // Update the district in the state
            setSelectedDistrict(correspondingDistrict);

            // Update the district in the formData
            setFormData(prevFormData => ({
                ...prevFormData,
                district: correspondingDistrict.district_id
            }));
        }
      }
    };
  
    const handleGradeChange = (e) => {
        const gradeValueStr = e.target.value;
        let gradeValue = parseInt(gradeValueStr, 10);
        
        // Check if the parsed value is NaN and handle accordingly
        if (isNaN(gradeValue)) {
            console.warn(`Warning: could not parse grade value "${gradeValueStr}" as an integer`);
            gradeValue = null; // or handle differently based on your use-case
        }
            // Find the selected grade object
            const selectedGrade = grades.find(grade => grade.grade_id === gradeValue);

        // Check if selected grade is one of the ineligible grades
        if (selectedGrade && ["College", "Community", "Preschool"].includes(selectedGrade.grade)) {
            setIneligibleGradeModal(true);
            setDisableSubmit(true);
            
            // Optionally, you may clear the form and selections here
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                student_name: '',
                teacher_status: false,
                cactus_number: '',
                school_id: '',
                district: '',
                event_program_id: '',
                event_date_id: '',
                grade_id: '',
                additional_contact: false,
                street: '',
                city: '',
                state: '',
                zip: '',
                names_to_sit_by: '',
                cactus_number_verified: null
            });
            setSelectedSchool(null);
            setSelectedEventProgram(null);
            
        } else {
            setDisableSubmit(false);
            setFormData({
                ...formData,
                grade_id: gradeValue
            });
        } 
    };

    const handleChange = (e) => {
        const { name, checked, type } = e.target;

        setFormData({
            ...formData,
            school_year_id: currentYear
        });
    
        // Check if the changed element is the checkbox for formCommit
        if (type === 'checkbox' && name === 'formCommit') {
            setFormCommit(checked);
        } else {
            // Handle other form data updates
            setFormData({
                ...formData,
                [name]: e.target.value
            });
        }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      setFormData({
        ...formData,
        school_year_id: currentYear
        });

        console.log('form data: ', currentYear)

      try {
          // Post formData to API
          const response = await fetch(`${backendURL}/api/form-submissions`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(formData)
          });
        //   console.log("Form Data: ",formData)
          // Check if post was successful
          if (!response.ok) {
              setErrorMessage("Failed to submit the form, please check for errors.")
              return;
          }
          setSubmitSuccess(true);
        //   console.log("form data: ", formData)
          // Log success and clear form
          setFormData({
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            student_name: '',
            teacher_status: false,
            cactus_number: '',
            school_id: '',
            district: '',
            event_program_id: '',
            event_date_id: '',
            grade_id: '',
            additional_contact: false,
            street: '',
            city: '',
            state: '',
            zip: '',
            names_to_sit_by: '',
            cactus_number_verified: null
          });
          setSelectedSchool(null);
          setSelectedEventProgram(null);
          setFormCommit(false);
          // [Add other state reset actions as needed]
          
      } catch {
          // Log error and potentially inform the user
          setErrorMessage('Failed to submit form');
      }
    };

    function getMissingFields () {
        const missingFields = [];
        if (!formData.first_name) missingFields.push("First Name");
        if (!formData.last_name) missingFields.push("Last Name");
        if (!formData.email) missingFields.push("Email");
        if (!formData.phone_number) missingFields.push("Phone Number");
        if (!formData.grade_id) missingFields.push("Grade");
        if (!formData.event_program_id) missingFields.push("Event Program");
        if (!formData.event_date_id) missingFields.push("Event Date");
        if (!formData.school_id) missingFields.push("School"); 
        if (!formData.street) missingFields.push("Street");   
        if (!formData.city) missingFields.push("City");   
        if (!formData.state) missingFields.push("State");   
        if (!formData.zip) missingFields.push("Zip");   

        if (!formCommit) missingFields.push("Form Commitment");
        if (formData.teacher_status) {
            if (!formData.cactus_number) missingFields.push("Cactus Number");
        }

    // ... continue for other fields ...

        return missingFields;
    }

    const missingFields = getMissingFields();

  
    return (
        <div  className='center-form'>
            {authState && <NavbarComponent />}
        <Form onSubmit={handleSubmit} className="my-form">
            <h3>{formData.teacher_status ? `Teacher's` : `Guardian's`} Information</h3>
        {error.grades && <Alert variant="danger">{error.grades}</Alert>}
        {error.schools && <Alert variant="danger">{error.schools}</Alert>}
        {error.eventPrograms  && <Alert variant="danger">{error.eventPrograms }</Alert>}
        {error.eventDates  && <Alert variant="danger">{error.eventDates }</Alert>}
            <Form.Group controlId="isTeacher">
                <Form.Label className="bold-label d-flex justify-content-between">
                    <span>Are you a Teacher?</span>
                    <Form.Check 
                    className='.form-check'
                    type="checkbox"
                    label="Yes"
                    name="teacher_status"
                    checked={formData.teacher_status}
                    onChange={e => handleChange({target: {name: e.target.name, value: e.target.checked}})}
                    />
                </Form.Label>
                
            </Form.Group>

            <Form.Group controlId="first_name">
                <Form.Label className="bold-label">{formData.teacher_status ? `Teacher's` : `Guardian's`} First Name</Form.Label>
                <Form.Control 
                    type="text"
                    placeholder="Enter first name"
                    name="first_name"
                    onChange={handleChange}
                    required
                    value={formData.first_name}
                />
            </Form.Group>

            <Form.Group controlId="last_name">
                <Form.Label className="bold-label">{formData.teacher_status ? `Teacher's` : `Guardian's`} Last Name</Form.Label>
                <Form.Control 
                    type="text"
                    placeholder="Enter last name"
                    name="last_name"
                    onChange={handleChange}
                    required
                    value={formData.last_name}
                />
            </Form.Group>
            <Form.Group controlId="email">
                <Form.Label className="bold-label">{formData.teacher_status ? `Teacher's` : `Guardian's`} Email</Form.Label>
                <Form.Control 
                    type="text"
                    placeholder="Enter Email"
                    name="email"
                    onChange={handleChange}
                    autoComplete='email'
                    required
                    value={formData.email}
                />
            </Form.Group>
            <Form.Group controlId="phone_number">
                <Form.Label className="bold-label">{formData.teacher_status ? `Teacher's` : `Guardian's`} Phone number</Form.Label>
                <Form.Control 
                    type="text"
                    placeholder="Enter Phone number"
                    name="phone_number"
                    onChange={handleChange}
                    required
                    value={formData.phone_number}
                />
            </Form.Group>
            <Form.Group controlId="street">
                <Form.Label className='bold-label'>Street</Form.Label>
                <Form.Control
                type='text'
                placeholder='Enter Street'
                name='street'
                required
                onChange={handleChange}
                value={formData.street}>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId='city'>
                <Form.Label className='bold-label'>City</Form.Label>
                <Form.Control
                type='text'
                placeholder='Enter City'
                name='city'
                required
                onChange={handleChange}
                value={formData.city}>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId='state'>
                <Form.Label className='bold-label'>State</Form.Label>
                <Form.Control
                as='select'
                required
                name='state'
                onChange={handleChange}
                value={formData.state}>
                    <option value={''}>Choose State</option>
                    {USAstates.map(state => (
                        <option value={state.state_id} key={state.state_id}>{state.state_name}, {state.abbreviation}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId='zip'>
                <Form.Label className='bold-label'>Zip</Form.Label>
                <Form.Control
                type='text'
                placeholder='Enter Zip'
                name='zip'
                required
                onChange={handleChange}
                value={formData.zip}>
                </Form.Control>
            </Form.Group>
            


            {formData.teacher_status && (
                <Form.Group controlId="cactus_number">
                    <Form.Label className="bold-label">Cactus Number</Form.Label>
                    <Form.Control 
                        type="text"
                        placeholder="Enter Cactus Number"
                        name="cactus_number"
                        value={formData.cactus_number}
                        onChange={handleChange}
                    />
                </Form.Group>
            )}
             {/* : (
                <Form.Group controlId="student_name">
                    <Form.Label className="bold-label">Student's Name</Form.Label>
                    <Form.Control 
                        type="text"
                        placeholder="Enter Student's Name"
                        name="student_name"
                        value={formData.student_name || ''}
                        onChange={handleChange}
                    />
                </Form.Group>
            )} */}

            <Form.Group controlId="grade_id">
                <Form.Label className="bold-label">Student Grade</Form.Label>
                <Form.Control 
                    as="select"
                    name="grade_id"
                    onChange={handleGradeChange}
                    value={formData.grade_id}
                >
                    <option value="">Select Grade</option>
                    {Array.isArray(grades) && grades.map(grade => (
                        <option key={grade.grade_id} value={grade.grade_id}>{grade.grade}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="school_id">
                <Form.Label className="bold-label">School (District) </Form.Label>
                <Select 
                    isSearchable={true}
                    options={schoolOptions}
                    value={selectedSchool}
                    onChange={handleSchoolChange}
                    inputId="school_id"
                />
            </Form.Group>
            <Form.Group controlId="event_program_id">
                <Form.Label className="bold-label">Select Event Program</Form.Label>
                <Form.Control 
                  as="select"
                  name="event_program_id"
                  value={formData.event_program_id}
                  onChange={e => handleEventProgramChange({ value: e.target.value, label: e.target.options[e.target.selectedIndex].text })}
                >
                  <option key={''} value="">Select Event Program</option>
                  {eventPrograms.map(program => (
                    <option key={program.event_id} value={program.event_id}>{program.name}</option>
                  ))}
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="event_date_id">
                <Form.Label className="bold-label">Event Date</Form.Label>
                <Select 
                    options={relevantEventDates}
                    onChange={handleEventDateChange}
                    value={relevantEventDates.find(date => date.value === formData.event_date_id) || null}
                    placeholder="Select Event Date"
                    isDisabled={!selectedEventProgram}
                    inputId="event_date_id"
                />
            </Form.Group>
            <Form.Group controlId="additional_contact">
                <Form.Label className="bold-label d-flex justify-content-between">
                    
                    <span className='form-check-label'>Want to purchase additional tickets</span>
                    <Form.Check 
                    className='.form-check'
                    type="checkbox"
                    label="Yes"
                    name="additional_contact"
                    checked={formData.additional_contact}
                    onChange={e => handleChange({target: {name: e.target.name, value: e.target.checked}})}/>
                    
                    </Form.Label>                
            </Form.Group>
            {/* <Form.Group controlId='names_to_sit_by'>
                <Form.Label className='bold-label'>Names to sit by</Form.Label>
                <Form.Control
                type='text'
                placeholder='Enter Names'
                name='names_to_sit_by'
                onChange={handleChange}
                value={formData.names_to_sit_by}>
                </Form.Control>
            </Form.Group> */}
            <Form.Group>
                <Form.Label className="bold-label bold-label d-flex justify-content-between">When receiving this ticket you understand that ...</Form.Label>
                <span>{
                        <div className='confirmation-text' onClick={() => setFormCommit(!formCommit)}>
                        <div>"I understand participants must attend the informance before the performance."</div>
                        <div>"I understand that participation in post-performance interaction and survey are required."</div>
                        <div>"I understand this free service is provided by the Utah State Board of Education (POPS program) and these are requirements for qualifying tickets."</div>
                        </div>
                    }</span>
                <Form.Check
                    type="checkbox"
                    label={<div onClick={() => setFormCommit(!formCommit)}>I understand.</div>}
                    name="formCommit"
                    checked={formCommit}
                    onChange={handleChange}
                />
            </Form.Group>

            <Button className="m-3" type="submit" 
              disabled={formData.teacher_status ?
              (!formData.first_name || !formData.last_name || !formCommit || !formData.cactus_number
              || !formData.email || !formData.phone_number || !formData.school_id 
              || !formData.street || !formData.city || !formData.state || !formData.zip
              || !formData.grade_id || !formData.event_program_id
              || !formData.event_date_id || disableSubmit) 
              : 
              (!formData.first_name || !formData.last_name || !formCommit 
                || !formData.email || !formData.phone_number || !formData.school_id 
                || !formData.street || !formData.city || !formData.state || !formData.zip
                || !formData.grade_id || !formData.event_program_id
                || !formData.event_date_id || disableSubmit)
              }>
                Submit
            </Button>
            {missingFields.length > 0 && (
                <div>
                <div className='bold-label'>Missing Required Fields: </div>
                    <div className='missing-requirements'>{missingFields.map((field, index) => (
                    <div key={index}>{field}</div>
                ))}</div>
                </div>            
            )}
        </Form>
            <Modal show={homeSchoolModal} onHide={() => setHomeSchoolModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Important Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Home-schooled students do not qualify for the free POPS funding provided by this program. Please reach out to Pamela Gee at "pamgee@ufomt.org" for a 50% student discount or for free dress rehearsal tickets
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setHomeSchoolModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={privateSchoolModal} onHide={() => setPrivateSchoolModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Important Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Private school students do not qualify for the free POPS funding provided by this program. Please reach out to the UFOMT box office for a 50% off student discount
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setPrivateSchoolModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={ineligibleGradeModal} onHide={() => setIneligibleGradeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Important Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Only k-12 public school students and certified public school teachers qualify for the POPS funding provided by this program.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIneligibleGradeModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={submitSuccess} onHide={() => setSubmitSuccess(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Submission Success!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Thank you for your participation.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSubmitSuccess(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <ErrorModalMessage errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage('')}></ErrorModalMessage>
    </div>
    );
};

export default FormSubmission;
