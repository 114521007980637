import React, { createContext, useState, useContext, useEffect } from 'react';
import { backendURL } from '../IPaddress';
import ErrorModalMessage from './ErrorMessage';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children, navigate }) => {
    const [authState, setAuthState] = useState(false);
    const [userId, setUserId] = useState(localStorage.getItem('userId') || '');
    const [resetUserData, setResetUserData] = useState(() => () => {});
    const [ errorMessage, setErrorMessage ] = useState(null);


    const login = async (user, password) => {
        
        const response = await fetch(`${backendURL}/api/login/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: `username=${user}&password=${encodeURIComponent(password)}`,
            credentials: 'include',
        });
        if (response.ok) {
            const data = await response.json();
            // data is a message saying if the login was a success or not. 
            
            setAuthState(true);
            checkAuth();
            // fetchUserInfo();
            // Fetch user info right after setting the username
            // await fetchUserInfo();
            navigate('/main');
        } else{
            throw new Error("Username or password is incorrect!")
        }
    };

    const logout = async () => {
        
        await fetch(`${backendURL}/api/logout/`, {
            method: 'POST',
            credentials: 'include',
        });
        setAuthState(false);
        localStorage.removeItem('userId'); // Clear userId from localStorage
        setUserId('');
        navigate('/login');
        resetUserData();
    };

    const checkAuth = async () => {
        
        const response = await fetch(`${backendURL}/api/check-auth`, {
            method: 'GET',
            credentials: 'include',
        });
    
        if (response.ok) {
            try {
                const data = await response.json();
                setUserId(data);
                localStorage.setItem('userId', data); // Store userId in localStorage
                setAuthState(true);
            } catch (error) {
                console.error("Error reading response data:", error);
            }
        } else {
            setAuthState(false);
            // navigate('/login');
        }
    };


    useEffect(() => {
        if (!userId) {
            const storedUserId = localStorage.getItem('userId');
            if (storedUserId) {
                setUserId(storedUserId);
            }
        }

        // if (userId) {
        //     fetchUserInfo();
        // }
    }, [userId]);


    return (
        <AuthContext.Provider value={{ authState, userId, login, logout, checkAuth }}>
                {children}
                <ErrorModalMessage errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage(null)} />
        </AuthContext.Provider>
    );
};

export default AuthContext;
