import React, { useState, useEffect, useSyncExternalStore } from 'react';
import { Modal, Button, Form, Table, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../store/AuthContext';
import { backendURL } from '../IPaddress';
import '../CSS/CRUDfiles.css';
import Collapse from 'react-bootstrap/Collapse';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useSchoolYear } from '../store/SchoolYearCalculator';
import ErrorModalMessage from '../store/ErrorMessage';

const EventsProgramComponent = () => {
  const [eventsPrograms, setEventsPrograms] = useState([]);
  const [currentEventProgram, setCurrentEventProgram] = useState({
    event_template_id: null,
    name: null,
    description: '',
    location_id: null,
    coordinator_id: '',
    event_type_id: 4,
  });
  const [currentEventDate, setCurrentEventDate ] = useState({
    event_dates_id: null,
    event_id: null, 
    date: null,
    start_time: null, 
    end_time: null,
    total_seats: null,
    sold_out: false,
  })
  const [ eventDates, setEventDates ] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [ showModalDateEvent, setShowModalDateEvent] = useState(false);
  const { authState } = useAuth();
  const [openStates, setOpenStates] = useState({});
  const navigate = useNavigate();
  const [ eventTypes, setEventTypes] = useState([]);
  const [ locations, setLocations] = useState([]);
  const [ userNames, setUsersNames] = useState([]);
  const [ eventTemplates, setEventTemplates ] = useState([]);
  const [newDateModal, setNewDateModal] = useState(false);
  const [showEventDateModal, setShowEventDateModal] = useState(false);
  const [showDeletionError, setShowDeletionError] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const {currentYear, schoolYearListParent } = useSchoolYear();
  const [schoolYearList, setSchoolYearList] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState();
  const [errorMessage, setErrorMessage] = useState('');


  useEffect(() => {
    if (!authState) {
      navigate('/login');
    }
  }, [authState, navigate]);

  useEffect(() => {
    if (currentYear && schoolYearListParent.length) {
      setSelectedSchoolYear(currentYear);
      setSchoolYearList(schoolYearListParent);
    }
  }, [currentYear, schoolYearListParent]);

  useEffect(() => {
    if(selectedSchoolYear){
      fetchEventsPrograms();
      fetchEventDates();
    }
  }, [selectedSchoolYear]);

  useEffect(() => {
    fetchEventTypes();
    fetchLocations();
    fetchUserNames();
    fetchEventTemplates();
  }, []);





  const fetchEventsPrograms = async () => {
    try {const response = await fetch(`${backendURL}/api/events-programs/year/${selectedSchoolYear}`, {
      method: 'GET',
    //   credentials: 'include',
    });
    const data = await response.json();
    //filtering out classroom events
    const filteredEvents = data.filter(event => event.event_type_id === 4)
    // console.log("filtered data: ", filteredEvents)
    setEventsPrograms(filteredEvents);}
    catch {
      ErrorModalMessage('error fetching program events')
    }
  };

  const fetchEventTemplates = async () => {
    const response = await fetch(`${backendURL}/api/event-templates`, {
      method: "GET",
      credentials: "include"
    })
    const data = await response.json();
    setEventTemplates(data)
  }

  const fetchEventTypes = async () => {
    const response = await fetch(`${backendURL}/api/event-types`, {
      method: 'GET',
      credentials: 'include'
    });
    try{
      const data = await response.json();
      setEventTypes(data);
    } catch (error) {
      console.error("Error fetching the event types: ", error.message)
    }
  }
  const fetchUserNames = async () => {
    try {
        const response = await fetch (`${backendURL}/api/app-users`,{
            method: 'GET',
            credentials: 'include'
          });
        const data = await response.json();
        if(!response.ok) throw new Error ('Failed to fetch user data')
        // console.log("Users Data: ",data)
        setUsersNames(data);
    } catch (err) {
        console.error(err.message);
    }
}
  const fetchLocations = async () => {
    const response = await fetch(`${backendURL}/api/locations`, {
      method: 'GET',
      credentials: 'include'
    });
    try{
      const data = await response.json();
      setLocations(data);
    } catch (error) {
      console.error("Error fetching locations: ", error.message)
    }
  }

  const fetchEventDates = async () => {
    try {const response = await fetch(`${backendURL}/api/event-dates/year/${selectedSchoolYear}`, {
        method: 'GET',
        credentials: 'include'
    });
    const data = await response.json();
    const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
      setEventDates(sortedData);
    }
    catch (error) {
        ErrorModalMessage("Error fetching event dates: ", error.message);
    }
  }
    const handleYearChange = (event) => {
      setSelectedSchoolYear(event.target.value);
    };
      

  const handleEventFormChange = (e) => {
    setCurrentEventProgram({ 
      ...currentEventProgram,
       [e.target.name]: e.target.value,
        school_year_id: parseInt(selectedSchoolYear, 10),
       });
  };
  const handleEventDateFormChange = (event) => {
    const { name, type, checked, value } = event.target;

    if (type === "checkbox") {
        // For checkboxes, use the `checked` property
        setCurrentEventDate(prevState => ({
            ...prevState,
            [name]: checked,
            school_year_id: parseInt(selectedSchoolYear, 10),
        }));
    } else if (type === "number") {
        // For number inputs, convert value to integer
        setCurrentEventDate(prevState => ({
            ...prevState,
            [name]: parseInt(value, 10),  // base 10 for decimal numbers
            school_year_id: parseInt(selectedSchoolYear, 10),
        }));
    } else {
        // For all other types, use the value directly
        setCurrentEventDate(prevState => ({
            ...prevState,
            [name]: value,
            school_year_id: parseInt(selectedSchoolYear, 10),
        }));
    }
};



  const handleEventSubmit = async (e) => {
    e.preventDefault();
    const method = currentEventProgram.event_id ? 'PUT' : 'POST';
    const url = currentEventProgram.event_id ? `${backendURL}/api/events-programs/${currentEventProgram.event_id}` : `${backendURL}/api/events-programs`;
    await fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(currentEventProgram),
      credentials: 'include',
    });

    setCurrentEventProgram({
      event_template_id: null,
      name: null,
      description: '',
      location_id: null,
      coordinator_id: '',
      event_type_id: 4,
    });
    setShowForm(false);
    fetchEventsPrograms();
  };

    const handleNewEventDate = async (eventProgramId) => {
    setCurrentEventDate({
      event_dates_id: null,
      event_id: eventProgramId,
      date: null,
      start_time: null, 
      end_time: null,
      total_seats: null,
      sold_out: false,
    });
    setShowEventDateModal(true); // Use the same modal for creating a new event date
  };

  const handleEventDateSubmit = async (e) => {
    e.preventDefault();

    if (!currentEventDate.date || !currentEventDate.start_time || !currentEventDate.end_time) {
      setErrorMessage('Please be sure to fill out all required fields (Date, Start Time, End Time).');
      return; // Prevent form submission if required fields are missing
  }

    const method = currentEventDate.event_dates_id ? 'PUT' : 'POST';
    const url = currentEventDate.event_dates_id ? `${backendURL}/api/event-dates/${currentEventDate.event_dates_id}` : `${backendURL}/api/event-dates`;
    const response = await fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(currentEventDate),
      credentials: 'include',
    });

    setCurrentEventDate({
        event_dates_id: null,
        event_id: null, 
        date: null,
        start_time: null, 
        end_time: null,
        total_seats: null,
        sold_out: false,
      });
    setShowEventDateModal(false);
    setShowModalDateEvent(false);
    setNewDateModal(false);
    fetchEventDates();
  };

  const editEventProgram = (eventProgram) => {
    setCurrentEventProgram(eventProgram);
    setShowForm(true);
  };

  const editEventDate = (eventDate) => {
    setCurrentEventDate({
        ...eventDate,
        school_year_id: parseInt(selectedSchoolYear, 10),
    });

    setShowEventDateModal(true); // Use the same modal for editing an existing event date
};


  const handleCancel = () => {
    setCurrentEventDate({
        event_dates_id: null,
        event_id: null, 
        date: null,
        start_time: null, 
        end_time: null,
        total_seats: null,
        sold_out: false,
      });
    setCurrentEventProgram({
        event_template_id: null,
        name: null,
        description: '',
        location_id: null,
        coordinator_id: '',
        event_type_id: 4,
      });
    setShowForm(false);
    setShowEventDateModal(false);
    setShowModalDateEvent(false);
    setNewDateModal(false);
  }

  const handleShowForm = () => {
    setCurrentEventProgram({
      event_template_id: null,
      name: null,
      description: '',
      location_id: null,
      coordinator_id: '',
      event_type_id: 4,
    });
    setShowForm(!showForm);
  }

  const deleteEventProgram = async (event_id) => {
    
     try {
      const response = await fetch(`${backendURL}/api/events-programs/${event_id}`, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (response.ok) {
      fetchEventsPrograms();
    } else {
      console.log("Unable to delete the associated event");
    }
  }
    catch (error) {
      setShowDeletionError(true);
      console.error("Please delete associated event dates before deleting an event.");
    }
  };

  const handleDeleteEventDate = () => {
    setConfirmationMessage('Are you sure you want to delete this event date?')
    setShowConfirmationModal(true);
  }
  
  const deleteEventDate = async (eventDateId) => {
    try {
      const response = await fetch(`${backendURL}/api/event-dates/${eventDateId}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (response.ok) {
        // Close modal and refresh the event dates list
        setShowEventDateModal(false);
        setShowConfirmationModal(false);
        fetchEventDates();    
      } else {
        throw new Error('Failed to delete the event date');
      }
    } catch (error) {
      console.error("Deletion failed:", error);
      // Optionally, handle error showing in UI
    }
  }

  const getEventType = (eventTypeId) => {
    const eventTypeObj = eventTypes.find(eventType => eventType.event_type_id === eventTypeId);
    return eventTypeObj ? (<>{eventTypeObj.name}</>) : 'Event type failed to load';
  }


  const getLocation = (locationId) => {
    const locationObj = locations.find(location => location.location_id === locationId);
    return locationObj ? (<>{locationObj.name}</>) : 'Event type failed to load';
  }

  const getCoordinatorId = (userId) => {
    const nameObject = userNames.find(user => user.user_id === userId);
    return nameObject ? (`${nameObject.first_name} ${nameObject.last_name}`) : 'Failed to get User';
}

  const formatTime = (timeString) => {
    // Assuming timeString is in 'HH:MM:SS' format
    if (timeString === null) {
      return `no time set`;
    }

    const [hours, minutes] = timeString.split(':');
    const hoursInt = parseInt(hours, 10);
    const suffix = hoursInt >= 12 ? 'PM' : 'AM';
    const formattedHours = ((hoursInt + 11) % 12 + 1); // Convert 24hr to 12hr format
    return `${formattedHours}:${minutes} ${suffix}`;
  };

  const getEventDates = (eventDateId) => {
    try {const eventDateObj =  eventDates.filter( eventDate => eventDate.event_id === eventDateId)
    if (eventDateObj.length > 0) {
        return (
            <ul >
                  {eventDateObj.map(eventDate => (
                      <li key={eventDate.event_dates_id} className='event-dates-list' onClick={() => editEventDate(eventDate)}>
                          {`Date: `}{eventDate.date ? eventDate.date : 'No date set'} <br/>
                          {'From: '}{formatTime(eventDate.start_time)}{' to '}{formatTime(eventDate.end_time)}
                      </li>
                  ))}
            </ul>
        )
    }
    return eventDateObj ? (<>{eventDateObj.date}</>) : 'Date failed to load';}
    catch{
      console.log("landed here")
      ErrorModalMessage("Unable to filter event dates by program event");
    }
  }
  const toggleOpenState = (eventId) => {
    setOpenStates((prevOpenStates) => ({
      ...prevOpenStates,
      [eventId]: !prevOpenStates[eventId],
    }));
  };
  

  return (
    <div>
      <Button onClick={() => handleShowForm()} className="m-1">
        {showForm ? 'Show Events Programs List' : 'Add New Event Program'}
      </Button>
      <div>
          <Dropdown>
            <span className='fw-bold'>Select School Year</span>
          <select value={selectedSchoolYear} onChange={handleYearChange}>
              <option value="" disabled>Select School Year</option>
              {schoolYearList.map((year) => (
                <option key={year.id} value={year.id}>
                  {year.school_year}
                </option>
              ))}
            </select>
          </Dropdown>
      </div>

      {showForm ? (
        <Form onSubmit={handleEventSubmit}>
          <Form.Group controlId="event_template_id">
            <Form.Label>Event Template ID</Form.Label>
            <Form.Control
              as="select"
              name="event_template_id"
              value={currentEventProgram.event_template_id}
              onChange={handleEventFormChange}
            >
              {<option value={''}>Select Event Template</option>}
            {eventTemplates.map( eventTemplate => (
            <option value={eventTemplate.template_id} key={eventTemplate.template_id}>{eventTemplate.name}</option>))
            }
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter event name"
              name="name"
              value={currentEventProgram.name}
              onChange={handleEventFormChange}
            />
          </Form.Group>

          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter event description"
              name="description"
              value={currentEventProgram.description}
              onChange={handleEventFormChange}
            />
          </Form.Group>

          <Form.Group controlId="location_id">
            <Form.Label>Location ID</Form.Label>
            <Form.Control
              as={'select'}
              name="location_id"
              value={currentEventProgram.location_id}
              onChange={handleEventFormChange}
            >
              <option value={null}>{"Select Location"}</option>
            {locations.map( location => (
            <option key={location.location_id} value={location.location_id}>{location.name}</option>
            ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="coordinator_id">
            <Form.Label>Coordinator ID</Form.Label>
            <Form.Control
              as={'select'}
              name="coordinator_id"
              value={currentEventProgram.coordinator_id}
              onChange={handleEventFormChange}
            >
              <option value={''}>Select Coordinator</option>
              {userNames.map(userName => (
                <option value={userName.user_id} key={userName.user_id}>{userName.first_name} {userName.last_name}</option>
              ))}
            </Form.Control>
          </Form.Group>

          {/*<Form.Group controlId="event_type_id">
            <Form.Label>Event Type ID</Form.Label>
            <Form.Control
              as={'select'}
              name="event_type_id"
              value={currentEventProgram.event_type_id}
              onChange={handleEventFormChange}
            >
              <option value={null}>Select Event Type</option>
              {eventTypes.map(eventType => (
                <option key={eventType.event_type_id} value={eventType.event_type_id}>{eventType.name}</option>
              ))}
            </Form.Control>
            </Form.Group>*/}

          <Button type="submit" className="m-1">Submit</Button>
          <Button variant="secondary" className="m-1" onClick={handleCancel}>Cancel</Button>
        </Form>
      ) : (
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>Event Name</th>
          <th>Description</th>
          <th>Location ID</th>
          <th>Seats Info</th>
          <th>Coordinator ID</th>
          <th>Event Type ID</th>
          <th>Dates Listed:</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {eventsPrograms.map((eventProgram) => (
          <tr key={eventProgram.event_id}>
            <td>{eventProgram.name}</td>
            <td>{eventProgram.description}</td>
            <td>{getLocation(eventProgram.location_id)}</td>
            <td>{'seats info'}</td>
            <td>{getCoordinatorId(eventProgram.coordinator_id)}</td>
            <td>{getEventType(eventProgram.event_type_id)}</td>
            <td>
              <Button
                onClick={() => toggleOpenState(eventProgram.event_id)}
                aria-controls="example-collapse-text"
                aria-expanded={openStates[eventProgram.event_id] || false}
              >
                {'Expand to show dates'}
              </Button>
              <Collapse in={openStates[eventProgram.event_id] || false}>
                <div>
                  <Button style={{margin: 5 +'px'}} onClick={() => 
                    handleNewEventDate(eventProgram.event_id)}>{'New Date'}</Button>
                  {getEventDates(eventProgram.event_id)}
                </div>
              </Collapse>
            </td>
            <td>
              <Button onClick={() => editEventProgram(eventProgram)} className="m-1"><i className="bi bi-pencil"></i></Button>
              <Button variant="danger" onClick={() => deleteEventProgram(eventProgram.event_id)} className="m-1"><i className="bi bi-trash3"></i></Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
      )}
        <Modal show={showEventDateModal} onHide={() => setShowEventDateModal(false)}>
          <Modal.Header>{currentEventDate.event_dates_id ? 'Edit Event Date' : 'Create New Event Date'}</Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Select Date</Form.Label>
                <Form.Control
                type={'date'}
                id='date'
                name='date'
                value={currentEventDate.date}
                onChange={handleEventDateFormChange}
                required>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Start Time</Form.Label>
                <Form.Control
                type={'time'}
                id='start_time'
                name='start_time'
                value={currentEventDate.start_time}
                onChange={handleEventDateFormChange}
                required>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>End Time</Form.Label>
                <Form.Control
                id='end_time'
                value={currentEventDate.end_time}
                type={'time'}
                name='end_time'
                onChange={handleEventDateFormChange}>
                </Form.Control>
              </Form.Group>
              <Form.Group>
              <Form.Label>Total Number of Seats</Form.Label>
              <Form.Control
                type="number"
                id="total_seats"
                name="total_seats"
                value={currentEventDate.total_seats}
                onChange={handleEventDateFormChange}>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              {/* <Form.Label>Sold Out?</Form.Label> */}
              <Form.Check 
                type="checkbox"
                id="sold_out"
                name="sold_out"
                checked={currentEventDate.sold_out}
                onChange={handleEventDateFormChange}
                label="Sold Out" />
            </Form.Group>

            </Form>
            <Modal.Footer className='modal-footer-buttons'>
              {currentEventDate.event_dates_id ? 
              <Button variant='danger' onClick={() => {handleDeleteEventDate()}}>Delete</Button>
               : 
               ''}
              <Button  onClick={handleEventDateSubmit}>{currentEventDate.event_dates_id ? 'Update' : 'Create'}</Button>
              <Button variant='secondary' onClick={() => setShowEventDateModal(false)}>Cancel</Button>
           </Modal.Footer>
          </Modal.Body>
        </Modal>
        <Modal show={showDeletionError} onHide={() => {setShowDeletionError(false)}}>
          <Modal.Header>Unable to process Request</Modal.Header>
          <Modal.Body>Please be sure to delete all associated dates with the event before deleting the event</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => setShowDeletionError(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
          <Modal.Header closeButton>{'Please Confirm!'}</Modal.Header>
          <Modal.Body>{confirmationMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={() => deleteEventDate(currentEventDate.event_dates_id)}>{'Delete'}</Button>
            <Button variant='secondary' onClick={() => setShowConfirmationModal(false)}>{'Cancel'}</Button>
          </Modal.Footer>
        </Modal>
        <ErrorModalMessage errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage('')}/>

        
    </div>
  );
};

export default EventsProgramComponent;
