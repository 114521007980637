import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Button, Form, Table, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../store/AuthContext';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { backendURL } from '../IPaddress';
import '../CSS/CRUDfiles.css'
import { useSchoolYear } from '../store/SchoolYearCalculator';
import { responsivePropType } from 'react-bootstrap/esm/createUtilityClasses';


const TicketsComponent = () => {
  const [tickets, setTickets] = useState([]);
  const [currentTicket, setCurrentTicket] = useState({
    event_date_id: '',
    app_user_id: '',
    seat_number: '',
    order_number: '',
    ticket_pulled: false,
    ticket_mailed: false,
    notes: '',
    status_type: ''
  });
  const [loading, setLoading] = useState({
    eventPrograms: false,
    eventDates: false
});
const [error, setError] = useState({
  eventPrograms: null,
  eventDates: null
});
  const [showForm, setShowForm] = useState(false);
  const [ticketToDelete, setTicketToDelete] = useState(null);
  const [eventPrograms, setEventPrograms] = useState([]);
  const [selectedEventProgram, setSelectedEventProgram] = useState(null);
  const [eventDates, setEventDates] = useState([]);
  const { authState } = useAuth();
  const navigate = useNavigate();
  const [schoolYearList, setSchoolYearList] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState();
  const {currentYear, schoolYearListParent} = useSchoolYear();
  const [ ticketStatuses, setTicketStatuses ] = useState([]);


  useEffect(() => {
    if (!authState) {
      navigate('/login');
    }
  }, [authState, navigate]);

  useEffect(() => {
    if (currentYear && schoolYearListParent.length) {
      setSelectedSchoolYear(currentYear);
      setSchoolYearList(schoolYearListParent);
    }
  }, [currentYear, schoolYearListParent]);


  useEffect(() => {
    if (selectedSchoolYear) {
      fetchTickets();
      fetchEventPrograms();
      fetchEventDates();
    }
  }, [selectedSchoolYear]);

  useEffect(()=> {
    fetchTicketStatuses();
  }, [])


  const fetchTickets = async () => {
    const response = await fetch(`${backendURL}/api/tickets/year/${selectedSchoolYear}`, {
      method: 'GET',
      credentials: 'include'
    });
    const data = await response.json();
    setTickets(data);
  };

  const fetchEventPrograms = async () => {
      try {
          setLoading(prev => ({ ...prev, eventPrograms: true }));
          const response = await fetch(`${backendURL}/api/events-programs/year/${selectedSchoolYear}`);
          if (!response.ok) throw new Error('Failed to fetch event programs.');
          let data = await response.json();

          // Filtering the data for event_type_id of 4
          data = data.filter(eventProgram => eventProgram.event_type_id === 4);
          setEventPrograms(data);
      } catch (err) {
          setError(prev => ({ ...prev, eventPrograms: err.message }));
      } finally {
          setLoading(prev => ({ ...prev, eventPrograms: false }));
      }
  };

  const fetchEventDates = async () => {
    try {
        const response = await fetch(`${backendURL}/api/event-dates/year/${selectedSchoolYear}`);
        if (!response.ok) throw new Error('Failed to fetch event dates.');
        const data = await response.json();
        const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
      setEventDates(sortedData);

    } catch (err) {
        console.error(err.message);
    }
  };

  const fetchTicketStatuses = async () => {
    const response = await fetch(`${backendURL}/api/ticket-statuses`,{
      method: 'GET',
      credentials: "include"
    });
    if(response.ok){
      const data = await response.json();
      setTicketStatuses(data);      
    }
  }

  const handleFormChange = (e) => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setCurrentTicket({ ...currentTicket, [e.target.name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentTicket.ticket_id) {
      await updateTicket(currentTicket.ticket_id, currentTicket);
    } else {
      await createTicket(currentTicket);
    }
    setCurrentTicket({
      event_date_id: '',
      app_user_id: '',
      seat_number: '',
      order_number: '',
      ticket_pulled: false,
      ticket_mailed: false,
      notes: '',
      status_type: ''
    });
    setShowForm(false);
    fetchTickets();
  };

  const createTicket = async (ticketData) => {
    await fetch(`${backendURL}/api/tickets`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(ticketData),
      credentials: 'include'
    });
  };

  const updateTicket = async (ticketId, ticketData) => {
    await fetch(`${backendURL}/api/tickets/${ticketId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(ticketData),
      credentials: 'include'
    });
  };

  const deleteTicket = async () => {
    if (ticketToDelete) {
      await fetch(`${backendURL}/api/tickets/${ticketToDelete}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      setTicketToDelete(null);
      fetchTickets();
    }
  };

  const editTicket = (ticket) => {
    setCurrentTicket(ticket);
    setShowForm(true);
  };

  const handleCancel = () => {
    setCurrentTicket({
      event_date_id: '',
      app_user_id: '',
      seat_number: '',
      order_number: '',
      ticket_pulled: false,
      ticket_mailed: false,
      notes: '',
      status_type: ''
    });
    setShowForm(false);
  };

  const relevantEventDates = useMemo(() => {
    if (!selectedEventProgram) return [];

    return eventDates
      .filter(event_date_id => event_date_id.event_id === Number(selectedEventProgram.value))
      .map(event_date_id => {
          // Parse the start_time and create a Date object. Assuming start_time is in 'HH:mm:ss' format
          const timeParts = event_date_id.start_time.split(':');
          const date = new Date();
          date.setHours(parseInt(timeParts[0], 10), parseInt(timeParts[1], 10), parseInt(timeParts[2], 10));
        
          // Format the date object to a 12-hour time with AM/PM
          const formattedTime = date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          });
                  
          return {
            label: `${event_date_id.date} ${formattedTime}`,
            value: event_date_id.event_dates_id
          };
        });
        
  }, [eventDates, selectedEventProgram]);

  const handleEventProgramChange = selectedOption => {
    setSelectedEventProgram(selectedOption);
    setCurrentTicket({
      ...currentTicket,
      event_program_id: selectedOption ? parseInt(selectedOption.value, 10) : null,
      event_date_id: null  // resetting event date when event program changes
  });
  };

  const handleEventDateChange = (selectedOption) => {
    setCurrentTicket({
        ...currentTicket,
        event_date_id: selectedOption ? selectedOption.value : null
    });
};  
  const handleYearChange = (event) => {
    setSelectedSchoolYear(event.target.value);
  };

  const getTicketStatus = (ticketStatusId) => {
    const ticketStatusObj = ticketStatuses.find(status => status.ticket_status_id === ticketStatusId);

    if(ticketStatusObj){
      return (
        <div style={{ backgroundColor: ticketStatusObj.color }}>{ticketStatusObj.name}</div>
      )
    } else {
      <>No status selected</>
    }
  }

  return (
    <div>
      <Button onClick={() => setShowForm(!showForm)} className="m-1">
        {showForm ? 'Show Tickets List' : 'Add New Ticket'}
      </Button>
      <div>
          <Dropdown>
          <span className='fw-bold'>Select School Year</span>
          <select value={selectedSchoolYear} onChange={handleYearChange}>
              <option value="" disabled>Select School Year</option>
              {schoolYearList.map((year) => (
                <option key={year.id} value={year.id}>
                  {year.school_year}
                </option>
              ))}
            </select>
          </Dropdown>
      </div>

      {showForm && (
        <Form onSubmit={handleSubmit}>
          {/* Add form groups for each ticket attribute here */}
          {/* Example for seat_number */}
          <Form.Group controlId="event_program_id">
                <Form.Label className="bold-label">Select Event Program</Form.Label>
                <Form.Control 
                  as="select"
                  name="event_program_id"
                  value={currentTicket.event_program_id}
                  onChange={e => handleEventProgramChange({ value: e.target.value, label: e.target.options[e.target.selectedIndex].text })}
                >
                  <option value="">Select Event Program</option>
                  {eventPrograms.map(program => (
                    <option key={program.event_id} value={program.event_id}>{program.name}</option>
                  ))}
                </Form.Control>
            </Form.Group>

            <Form.Group controlId="event_date_id">
                <Form.Label className="bold-label">Event Date</Form.Label>
                <Select 
                    options={relevantEventDates}
                    onChange={handleEventDateChange}
                    value={relevantEventDates.find(date => date.value === currentTicket.event_date_id) || null}
                    placeholder="Select Event Date"
                    isDisabled={!selectedEventProgram}
                    inputId="event_date_id"
                />
            </Form.Group>
          <Form.Group controlId="seat_number">
            <Form.Label>Seat Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter seat number"
              name="seat_number"
              value={currentTicket.seat_number}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="order_number">
            <Form.Label>Order Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter order number"
              name="order_number"
              value={currentTicket.order_number}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="ticket_pulled">
            <Form.Label>Ticket Pulled</Form.Label>
            <Form.Check
              label="Yes"
              type="checkbox"
              name="ticket_pulled"
              checked={currentTicket.ticket_pulled}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="ticket_mailed">
            <Form.Label>Ticket Mailed</Form.Label>
            <Form.Check
              label="Yes"
              type="checkbox"
              name="ticket_mailed"
              checked={currentTicket.ticket_mailed}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="notes" className="custom-textbox-width">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter notes"
              name="notes"
              value={currentTicket.notes}
              onChange={handleFormChange}
              
            />
          </Form.Group>
          
          {/* Repeat for other attributes */}
          <Button type="submit" className="m-1">Submit</Button>
          <Button variant="secondary" onClick={handleCancel} className="ml-2">Cancel</Button>
        </Form>
      )}

      {!showForm && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Event Date ID</th>
              <th>App User ID</th>
              <th>Seat Number</th>
              <th>Order Number</th>
              <th>Ticket Pulled</th>
              <th>Ticket Mailed</th>
              <th>Notes</th>
              <th>Status Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket) => (
              <tr key={ticket.ticket_id}>
                {/* Render ticket details */}
                <td>{ticket.event_date_id}</td>
                <td>{ticket.app_user_id}</td>
                <td>{ticket.seat_number}</td>
                <td>{ticket.order_number}</td>
                <td>{ticket.ticket_pulled ? 'Yes' : 'No'}</td>
                <td>{ticket.ticket_mailed ? 'Yes' : 'No'}</td>
                <td>{ticket.notes}</td>
                <td>{getTicketStatus(ticket.status_type)}</td>
                <td>
                  <Button onClick={() => editTicket(ticket)} className="m-1"><i className="bi bi-pencil-square"></i></Button>
                  <Button variant="danger" onClick={() => {
                    setTicketToDelete(ticket.ticket_id);
                  }} className="m-1"><i className="bi bi-trash3"></i></Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Modal for deletion confirmation */}
      <Modal show={ticketToDelete !== null} onHide={() => setTicketToDelete(null)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this ticket?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setTicketToDelete(null)}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteTicket}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TicketsComponent;
