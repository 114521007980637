import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../store/AuthContext';
import { backendURL } from '../IPaddress';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../CSS/CRUDfiles.css';

const RecordStatusComponent = () => {
  const [statuses, setStatuses] = useState([]);
  const [currentStatus, setCurrentStatus] = useState({ status: '', color: '', description: '' });
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [statusToDelete, setStatusToDelete] = useState(null);
  const { authState } = useAuth();
  const navigate = useNavigate();

  // Authentication check
  useEffect(() => {
    if (!authState) {
        navigate('/login');
    }
  }, [authState, navigate]);

  // Fetch all record statuses
  useEffect(() => {
    fetchRecordStatuses();
  }, []);

  const fetchRecordStatuses = async () => {
    try {
      const response = await fetch(`${backendURL}/api/record-status/`, {
        method: 'GET',
        credentials: 'include'
      });
      const data = await response.json();
      setStatuses(data);
    } catch (error) {
      console.error('Error fetching record statuses:', error);
    }
  };

  const handleFormChange = (e) => {
    setCurrentStatus({ ...currentStatus, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentStatus.status_id) {
      await updateRecordStatus(currentStatus.status_id, currentStatus);
    } else {
      await createRecordStatus(currentStatus);
    }
    setCurrentStatus({ status: '', color: '', description: '' });
    setShowForm(false);
    fetchRecordStatuses();
  };

  const createRecordStatus = async (statusData) => {
    await fetch(`${backendURL}/api/record-status`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(statusData),
        credentials: 'include'
      });
  };

  const updateRecordStatus = async (statusId, statusData) => {
    await fetch(`${backendURL}/api/record-status/${statusId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(statusData),
        credentials: 'include'
      });
  };

  const deleteRecordStatus = async () => {
    if (statusToDelete) {
        await fetch(`${backendURL}/api/record-status/${statusToDelete}`, {
          method: 'DELETE',
          credentials: 'include'
        });
        setStatusToDelete(null);
        setShowModal(false);
        fetchRecordStatuses();
      }
    };

  const editRecordStatus = (status) => {
    setCurrentStatus(status);
    setShowForm(true);
  };

  const handleCancel = () => {
    setCurrentStatus({ status: '', color: '', description: '' });
    setShowForm(false);
  };

  return (
    <div>
      <Button onClick={() => setShowForm(!showForm)} className="m-1">
        {showForm ? 'Show Statuses List' : 'Add New Status'}
      </Button>

      {showForm ? (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="status">
            <Form.Label>Status</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter status"
              name="status"
              onChange={handleFormChange}
              value={currentStatus.status}
            />
          </Form.Group>
          <Form.Group controlId="color">
            <Form.Label>Color</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter color"
              name="color"
              onChange={handleFormChange}
              value={currentStatus.color}
            />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Enter description"
              name="description"
              onChange={handleFormChange}
              value={currentStatus.description}
            />
          </Form.Group>
          <Button type="submit" className="m-1">Submit</Button>
          <Button variant="secondary" onClick={handleCancel} className="ml-2">Cancel</Button>
        </Form>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Status</th>
              <th>Color</th>
              <th>Description</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {statuses.map((status) => (
              <tr key={status.status_id}>
                <td>{status.status}</td>
                <td>{status.color}</td>
                <td>{status.description}</td>
                <td>
                  <Button onClick={() => editRecordStatus(status)} className="m-1"><i className="bi bi-pencil-square"></i></Button>
                  <Button variant="danger" onClick={() => {
                    setStatusToDelete(status.status_id);
                    setShowModal(true);
                  }} className="m-1"><i className="bi bi-trash3"></i></Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this status?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteRecordStatus}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RecordStatusComponent;
