import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { Modal, Form, Table, Button } from 'react-bootstrap';
import { useAuth } from '../store/AuthContext';
import { useNavigate } from 'react-router-dom';
import { backendURL } from '../IPaddress';
import '../CSS/CRUDfiles.css';
import 'bootstrap-icons/font/bootstrap-icons.css';


const EventTemplateComponent = () => {
    const [formSubmissions, setFormSubmissions] = useState([]);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        student_name: '',
        teacher_status: false,
        cactus_number: '',
        school_id: '',
        district: '',
        event_program_id: '',
        event_date_id: '',
        grade_id: '',
        additional_contact: false
    });
    const [showForm, setShowForm] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [submissionToDelete, setSubmissionToDelete] = useState(null);
    const { authState } = useAuth();
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [grades, setGrades] = useState([]);
    const [schools, setSchools] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [eventPrograms, setEventPrograms] = useState([]);
    const [eventDates, setEventDates] = useState([]);
    const [loading, setLoading] = useState({
        grades: false,
        schools: false,
        eventPrograms: false,
        eventDates: false
    });
    const [error, setError] = useState({
        grades: null,
        schools: null,
        eventPrograms: null,
        eventDates: null
    }); 
    const [selectedEventProgram, setSelectedEventProgram] = useState(null);
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [privateSchoolModal, setPrivateSchoolModal] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [homeSchoolModal, setHomeSchoolModal] = useState(false);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [ineligibleGradeModal, setIneligibleGradeModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);


    // Redirect to login if not authenticated
    useEffect(() => {
        if (!authState) {
            navigate('/login');
        }
    }, [authState, navigate]); 

    // Fetch all event templates
    useEffect(() => {
        fetchFormSubmissions();
    }, []);

    useEffect(() => {
        const fetchGrades = async () => {
          const url = `${backendURL}/api/grades`
          const response = await fetch(url);
          if (response.ok) {
            const data = await response.json();
  
            if (Array.isArray(data)) {
              setGrades(data);
          } else {
              console.error('Fetched grades data is not an array:', data);
          }}
        };
        const fetchDistricts = async () => {
          const url = `${backendURL}/api/districts`;
          const response = await fetch(url);
          if (response.ok) {
            const data = await response.json();
            setDistricts(data);
          }
        }
  
        const fetchSchools = async () => {
          setLoading(prev => ({ ...prev, schools: true }));
          const response = await fetch(`${backendURL}/api/schools`);
          
          if (!response.ok) throw new Error('Failed to fetch schools.');
          const data = await response.json();
          
          if (Array.isArray(data)) {
            setSchools(data);
        } else {
            console.error('Fetched Schools data is not an array:', data);
  
        }}
  
        const fetchEventPrograms = async () => {
            try {
                setLoading(prev => ({ ...prev, eventPrograms: true }));
                const response = await fetch(`${backendURL}/api/events-programs`);
                if (!response.ok) throw new Error('Failed to fetch event programs.');
                let data = await response.json();
  
                // Filtering the data for event_type_id of 4
                data = data.filter(eventProgram => eventProgram.event_type_id === 4);
                setEventPrograms(data);
            } catch (err) {
                setError(prev => ({ ...prev, eventPrograms: err.message }));
            } finally {
                setLoading(prev => ({ ...prev, eventPrograms: false }));
            }
        };
  
        const fetchEventDates = async () => {
          try {
              const response = await fetch(`${backendURL}/api/event-dates`);
              if (!response.ok) throw new Error('Failed to fetch event dates.');
              const data = await response.json();
              const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
      setEventDates(sortedData);
  
          } catch (err) {
              console.error(err.message);
          }
        };
  
        fetchGrades();
        fetchSchools();
        fetchDistricts();
        fetchEventPrograms();
        fetchEventDates();
    }, []);
    
    const schoolOptions = schools.map(school_id => {
        const district = districts.find(d => d.district_id === school_id.district_id);
        const districtName = district ? district.name : 'Unknown District';
        
        return {
          label: `${school_id.name} (${districtName})`,
          value: school_id.school_id
        };
    });

    const relevantEventDates = useMemo(() => {
        if (!selectedEventProgram) return [];
  
        return eventDates
          .filter(event_date_id => event_date_id.event_id === Number(selectedEventProgram.value))
          .map(event_date_id => {
              // Parse the start_time and create a Date object. Assuming start_time is in 'HH:mm:ss' format
              const timeParts = event_date_id.start_time.split(':');
              const date = new Date();
              date.setHours(parseInt(timeParts[0], 10), parseInt(timeParts[1], 10), parseInt(timeParts[2], 10));
            
              // Format the date object to a 12-hour time with AM/PM
              const formattedTime = date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              });
                      
              return {
                label: `${event_date_id.date} ${formattedTime}`,
                value: event_date_id.event_dates_id
              };
            });
            
      }, [eventDates, selectedEventProgram]);
    // console.log("relevantEventDates list: ", relevantEventDates)

    const handleEventProgramChange = selectedOption => {
        setSelectedEventProgram(selectedOption);
        setFormData(prevFormData => ({
          ...prevFormData,
          event_program_id: selectedOption ? parseInt(selectedOption.value, 10) : null,
          event_date_id: isEditing ? parseInt(prevFormData.event_date_id, 10) : null // Reset only if not editing
        }));
    };

    const fetchFormSubmissions = async () => {
        const response = await fetch(`${backendURL}/api/form-submissions`, {
            method: 'GET',
            credentials: 'include'
        });
        const data = await response.json();
        setFormSubmissions(data);
    };

    const handleEventDateChange = (selectedOption) => {
        setFormData({
            ...formData,
            event_date_id: selectedOption ? selectedOption.value : null
        });
    };

    const handleSchoolChange = selectedOption => {
        setSelectedSchool(selectedOption);
        let schoolId = null;
        if (selectedOption) {
            schoolId = parseInt(selectedOption.value, 10);
        if (isNaN(schoolId)) {
            console.warn(`Warning: could not parse school_id value "${selectedOption.value}" as an integer`);
            schoolId = null;
            }}
            setFormData({
            ...formData,
            school_id: schoolId
            });

            const selectedSchoolObj = schools.find(school_id => school_id.school_id === schoolId);
        if (selectedOption && selectedOption.label.includes("(Private)")) {
            setPrivateSchoolModal(true);
            setDisableSubmit(true);
            // Optionally, you may clear the form and selections here
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                student_name: '',
                teacher_status: false,
                cactus_number: '',
                school_id: '',
                district: '',
                event_program_id: '',
                event_date_id: '',
                grade_id: '',
                additional_contact: false
            });
            setSelectedSchool(null);
            setSelectedEventProgram(null);} 
        else if (selectedSchoolObj && selectedSchoolObj.name === "Home-School") {
            setHomeSchoolModal(true);
            setDisableSubmit(true);
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                student_name: '',
                teacher_status: false,
                cactus_number: '',
                school_id: '',
                district: '',
                event_program_id: '',
                event_date_id: '',
                grade_id: '',
                additional_contact: false
            });
            setSelectedSchool(null);
            setSelectedEventProgram(null);
        } else {
            setDisableSubmit(false);
        }
    
        if (selectedSchoolObj)  {
            // Find the corresponding district object
            const correspondingDistrict = districts.find(district => district.district_id === selectedSchoolObj.district_id);
        
        if (correspondingDistrict) {
            // Update the district in the state
            setSelectedDistrict(correspondingDistrict);

            // Update the district in the formData
            setFormData(prevFormData => ({
                ...prevFormData,
                district: correspondingDistrict.district_id
            }));
        }
      }
    };

    const handleGradeChange = (e) => {
        const gradeValueStr = e.target.value;
        let gradeValue = parseInt(gradeValueStr, 10);
        
        // Check if the parsed value is NaN and handle accordingly
        if (isNaN(gradeValue)) {
            console.warn(`Warning: could not parse grade value "${gradeValueStr}" as an integer`);
            gradeValue = null; // or handle differently based on your use-case
        }
            // Find the selected grade object
            const selectedGrade = grades.find(grade => grade.grade_id === gradeValue);

        // Check if selected grade is one of the ineligible grades
        if (selectedGrade && ["College", "Community", "Preschool"].includes(selectedGrade.grade)) {
            setIneligibleGradeModal(true);
            setDisableSubmit(true);
            
            // Optionally, you may clear the form and selections here
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                student_name: '',
                teacher_status: false,
                cactus_number: '',
                school_id: '',
                district: '',
                event_program_id: '',
                event_date_id: '',
                grade_id: '',
                additional_contact: false
            });
            setSelectedSchool(null);
            setSelectedEventProgram(null);
            
        } else {
            setDisableSubmit(false);
            setFormData({
                ...formData,
                grade_id: gradeValue
            });
        } 
    };



    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("form data: ", formData)
        if (formData.submission_id) {
            await updateFormSubmission(formData.submission_id, formData);
        } else {
            await createEventTemplate(formData);
        }
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            student_name: '',
            teacher_status: false,
            cactus_number: '',
            school_id: '',
            district: '',
            event_program_id: '',
            event_date_id: '',
            grade_id: '',
            additional_contact: false
        });
        // console.log("form data cleared")
        setShowForm(false);
        setSelectedSchool(null);
        fetchFormSubmissions();
    };

    const filteredFormSubmissions = formSubmissions.filter(submission => 
        `${submission.first_name} ${submission.last_name}`.toLowerCase().includes(searchQuery.toLowerCase())
    );
    

    const createEventTemplate = async (formData) => {
        await fetch(`${backendURL}/api/form-submissions`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
            credentials: 'include'
        });
    };

    const updateFormSubmission = async (submissionId, formData) => {
        await fetch(`${backendURL}/api/form-submissions/${submissionId}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
            credentials: 'include'
        });
    };

    const deleteEventTemplate = async () => {
        if (submissionToDelete) {
            await fetch(`${backendURL}/api/form-submissions/${submissionToDelete}`, {
                method: 'DELETE',
                credentials: 'include'
            });
            
            setSubmissionToDelete(null);
            setShowModal(false);
            fetchFormSubmissions();
        }
    };
    
    // console.log("event dates: ", eventDates)

    const editSubmission = (submission) => {
        setIsEditing(true);
        setFormData(submission);

        //finds the proper event program object from the select list in the form and then sets it if it is true. 
        const programObject = eventPrograms.find(program => program.event_id === submission.event_program_id);
        if (programObject) {
        setSelectedEventProgram({ value: programObject.event_id, label: programObject.name });
        }
    
        const schoolObject = schoolOptions.find(school => school.value === submission.school_id);
        setSelectedSchool(schoolObject);

        setShowForm(true);
    };    

    useEffect(() => {
        if (selectedEventProgram && isEditing) {
            // Find the event date from the formData and set it
            const eventDate = eventDates.find(date => date.event_dates_id === formData.event_date_id);

            if (eventDate) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    event_date_id: eventDate.event_dates_id
                }));
            }
        }
    }, [selectedEventProgram, eventDates, formData.event_date_id, isEditing]);
    

    const handleCancel = () => {
        setIsEditing(false);
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            student_name: '',
            teacher_status: false,
            cactus_number: '',
            school_id: '',
            district: '',
            event_program_id: '',
            event_date_id: '',
            grade_id: '',
            additional_contact: false
        });
        setShowForm(false);
    };

    const getSchoolName = (schoolId) => {
        const schoolObject = schoolOptions.find(school => school.value === schoolId); 
        return schoolObject ? schoolObject.label : 'Unknown';
    }

    const getGradeType = (gradeId) => {
        const grade = grades.find(g => g.grade_id === gradeId)
        if (grade) {
          return grade.grade
        }
        return "Unknown Grade";
      }
    const getEventName = (eventID) => {
        const event = eventPrograms.find(e => e.event_id === eventID)
        if (event) {
          return event.name
        }
        return "Unknown Program";
    }
    const getEventDate = (eventDateId) => {
        const event = eventDates.find(e => e.event_dates_id === eventDateId);
        
        if (event) {
            // Assuming event.start_time is in 'HH:MM:SS' format
            const timeString = event.start_time;
            const timeParts = timeString.split(':');
            const date = new Date();
            date.setHours(timeParts[0], timeParts[1], timeParts[2]);
    
            // Convert to 12-hour time format
            const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    
            return `${event.date} at ${formattedTime}`;
        }
    
        return "Unknown Event Date";
    };

    const ScrollableTableBody = ({ submissions }) => (
        <div className="scrollable-table">
            <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            {/* <th>student_name</th> */}
                            <th>Teacher status</th>
                            <th>Cactus Number</th>
                            <th>School</th>
                            {/* <th>District</th> */}
                            <th>Event Program</th>
                            <th>Event Date</th>
                            <th>Grade</th>
                            <th>Additional Contact Requested</th>
                            <th>Submitted Date</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {submissions.map((submission) => (
                            <tr key={submission.submission_id}>
                                <td>{submission.first_name}</td>
                                <td>{submission.last_name}</td>
                                <td>{submission.email}</td>
                                <td>{submission.phone_number}</td>
                                {/* <td>{submission.student_name}</td> */}
                                <td>{submission.teacher_status ? '\u2713' : ''}</td>
                                <td>{submission.cactus_number}</td>
                                <td>{getSchoolName(submission.school_id)}</td>
                                {/* <td>{submission.district}</td> */}
                                <td>{getEventName(submission.event_program_id)}</td>
                                <td>{getEventDate(submission.event_date_id)}</td>
                                <td>{getGradeType(submission.grade_id)}</td>
                                <td>{submission.additional_contact ? '\u2713' : ''}</td>
                                <td>{submission.submitted_date}</td>

                                <td>
                                    <Button onClick={() => editSubmission(submission)} className="m-1"><i className="bi bi-pencil-square"></i></Button>
                                    <Button variant="danger" onClick={() => {
                                        setSubmissionToDelete(submission.submission_id);
                                        setShowModal(true);
                                    }}><i className="bi bi-trash3"></i></Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
            </Table>
        </div>
    );
    

    return (
        <div>
            <Button onClick={() => setShowForm(!showForm)} className="m-1">
                {showForm ? 'Show Ticket Submissions List' : 'Add New Ticket Submission'}
            </Button>
            { formSubmissions.length === filteredFormSubmissions.length ? 
            <p>Total Submissions: {filteredFormSubmissions.length}</p> : <p>Filtered Submissions: {filteredFormSubmissions.length}</p>}
            {!showForm ? 
            <Form.Group controlId="search">
            <Form.Control
                type="text"
                placeholder="Search by name..."
                onChange={(e) => setSearchQuery(e.target.value)}
            /></Form.Group> : <></>}
            
            {showForm ? (
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="first_name">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter first name"
                            name="first_name"
                            onChange={handleFormChange}
                            value={formData.first_name}
                        />
                    </Form.Group>
                    <Form.Group controlId="last_name">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter last name"
                            name="last_name"
                            onChange={handleFormChange}
                            value={formData.last_name}
                        />
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter email"
                            name="email"
                            onChange={handleFormChange}
                            value={formData.email}
                        />
                    </Form.Group>
                    <Form.Group controlId="phone_number">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter phone_number"
                            name="phone_number"
                            onChange={handleFormChange}
                            value={formData.phone_number}
                        />
                    </Form.Group>
                    {/* <Form.Group controlId="student_name">
                        <Form.Label>Student Name</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter student name"
                            name="student_name"
                            onChange={handleFormChange}
                            value={formData.student_name}
                        />
                    </Form.Group> */}
                    <Form.Group controlId="teacher_status">
                        <Form.Label>Teacher Status</Form.Label>
                        <Form.Check 
                            type="checkbox"
                            label="Is a teacher"
                            name="teacher_status"
                            onChange={e => handleFormChange({target: {name: e.target.name, value: e.target.checked}})}
                            checked={formData.teacher_status}
                        />
                    </Form.Group>
                    <Form.Group controlId="cactus_number">
                        <Form.Label>Cactus Number</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter cactus number"
                            name="cactus_number"
                            onChange={handleFormChange}
                            value={formData.cactus_number}
                        />
                    </Form.Group>
                    <Form.Group controlId="grade_id">
                        <Form.Label>Grade</Form.Label>
                        <Form.Control 
                            as="select"
                            name="grade_id"
                            onChange={handleGradeChange}
                            value={formData.grade_id}
                        ><option value="">Select Grade</option>
                        {Array.isArray(grades) && grades.map(grade => (
                            <option key={grade.grade_id} value={grade.grade_id}>{grade.grade}</option>
                        ))}
                        </Form.Control> 
                    </Form.Group>
                    <Form.Group controlId="school_id">
                        <Form.Label>School</Form.Label>
                        <Select 
                            isSearchable={true}
                            options={schoolOptions}
                            value={selectedSchool}
                            onChange={handleSchoolChange}
                            inputId="school_id"
                        />
                    </Form.Group>
                    {/* <Form.Group controlId="email">
                        <Form.Label>District</Form.Label>
                        <Form.Control 
                            type="text"
                            placeholder="Enter district"
                            name="district"
                            onChange={handleFormChange}
                            value={formData.district}
                        />
                    </Form.Group> */}
                    <Form.Group controlId="event_program_id">
                    <Form.Label className="bold-label">Event Program</Form.Label>
                        <Form.Control 
                        as="select"
                        name="event_program_id"
                        value={formData.event_program_id}
                        onChange={e => handleEventProgramChange({ value: e.target.value, label: e.target.options[e.target.selectedIndex].text })}
                        >
                        <option value="">Select Event Program</option>
                        {eventPrograms.map(program => (
                            <option key={program.id} value={program.event_id}>{program.name}</option>
                        ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="event_date_id">
                        <Form.Label className="bold-label">Event Date</Form.Label>
                        <Select 
                            options={relevantEventDates}
                            onChange={handleEventDateChange}
                            value={relevantEventDates.find(date => date.value === formData.event_date_id) || null}
                            placeholder="Select Event Date"
                            isDisabled={!selectedEventProgram }
                            inputId="event_date_id"
                        />
                    </Form.Group>
                    <Form.Group controlId="additional_contact">
                        <Form.Label>Would you like to be contacted for additional tickets?</Form.Label>
                        <Form.Check 
                            type="checkbox"
                            label="Yes"
                            name="additional_contact"
                            onChange={e => handleFormChange({target: {name: e.target.name, value: e.target.checked}})}
                            checked={formData.additional_contact}
                        />
                    </Form.Group>

                    <Button type="submit" className="m-1">Submit</Button>
                    <Button variant="secondary" onClick={handleCancel} className="ml-2">Cancel</Button>
                </Form>
            ) : (
                <>
                {/* <FixedHeader/> */}
                <ScrollableTableBody submissions={filteredFormSubmissions} />
                </>
            )}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this ticket submission?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={deleteEventTemplate}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
    

};

export default EventTemplateComponent;
