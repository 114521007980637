import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../store/AuthContext';
import { backendURL } from '../IPaddress';
import '../CSS/CRUDfiles.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const ActionComponent = () => {
  const [actions, setActions] = useState([]);
  const [currentAction, setCurrentAction] = useState({ name: '' });
  const [showForm, setShowForm] = useState(false);
  const [actionToDelete, setActionToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { authState } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState) {
      navigate('/login');
    }
  }, [authState, navigate]);

  useEffect(() => {
    fetchActions();
  }, []);

  const fetchActions = async () => {
    const response = await fetch(`${backendURL}/api/actions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const data = await response.json();
    setActions(data);
  };

  const handleFormChange = (e) => {
    setCurrentAction({ ...currentAction, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentAction.name || currentAction.name.trim() === '') {
      alert("Name can't be empty"); // Simple validation feedback
      return;
    }

    if (currentAction.action_id) {
      await updateAction(currentAction.action_id, currentAction);
    } else {
      await createAction(currentAction);
    }
    setCurrentAction({ name: '' });
    setShowForm(false);
    fetchActions();
  };

  const createAction = async (actionData) => {
    await fetch(`${backendURL}/api/actions`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(actionData),
      credentials: 'include',
    });
  };

  const updateAction = async (actionId, actionData) => {
    await fetch(`${backendURL}/api/actions/${actionId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(actionData),
      credentials: 'include',
    });
  };

  const deleteAction = async () => {
    if (actionToDelete) {
      await fetch(`${backendURL}/api/actions/${actionToDelete}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      setActionToDelete(null);
      setShowModal(false);
      fetchActions();
    }
  };

  const editAction = (action) => {
    setCurrentAction(action);
    setShowForm(true);
  };

  return (
    <div>
      <Button onClick={() => setShowForm(!showForm)} className="m-1">
        {showForm ? 'Show Actions List' : 'Add New Action'}
      </Button>

      {showForm ? (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Action Name</Form.Label>
            <Form.Control 
              type="text"
              placeholder="Enter action name"
              name="name"
              onChange={handleFormChange}
              value={currentAction.name}
            />
          </Form.Group>
          <Button type="submit" className="m-1">Submit</Button>
          <Button variant="secondary" onClick={() => setShowForm(false)} className="ml-2">Cancel</Button>
        </Form>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Action Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {actions.map((action) => (
              <tr key={action.action_id}>
                <td>{action.name}</td>
                <td>
                  <Button onClick={() => editAction(action)} className="m-1"><i className="bi bi-pencil"></i></Button>
                  <Button variant="danger" onClick={() => {
                    setActionToDelete(action.action_id);
                    setShowModal(true);
                  }} className="m-1"><i className="bi bi-trash3"></i></Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this action?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteAction}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActionComponent;
