import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Spinner, Table, Modal } from 'react-bootstrap';
import { backendURL } from '../IPaddress';

const SelectSchoolYearComponent = ({ currentSchoolYear, onUpdate }) => {
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [selectedSchoolYear, setSelectedSchoolYear] = useState(currentSchoolYear);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalSchoolYear, setModalSchoolYear] = useState(null);

    useEffect(() => {
        fetchSchoolYears();
    }, []);

    const fetchSchoolYears = async () => {
        try {
            const response = await fetch(`${backendURL}/api/school-year-list`, {
                method: 'GET', 
                credentials: 'include'
            });
            if (!response.ok) {
                throw new Error('Failed to fetch school years');
            }
            const data = await response.json();
            setSchoolYearList(data);
        } catch (error) {
            setError(error.message);
        }
    };

    const handleSchoolYearChange = (e) => {
        setSelectedSchoolYear(e.target.value);
    };

    const handleUpdate = async () => {
        setIsLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await fetch(`${backendURL}/api/school-year-list/active`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ school_year_id: selectedSchoolYear }),
            });

            if (!response.ok) {
                throw new Error('Failed to update the active school year');
            }

            const data = await response.json();
            setSuccess('Active school year updated successfully!');
            onUpdate(data); // Assuming onUpdate is a prop to update the parent component state
            fetchSchoolYears(); // Refresh the school year list
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditClick = (year) => {
        setModalSchoolYear(year);
        setShowModal(true);
    };

    const handleModalChange = (e) => {
        const { name, value } = e.target;
        setModalSchoolYear({ ...modalSchoolYear, [name]: value });
    };

    const handleModalUpdate = async () => {
        setIsLoading(true);
        setError(null);
        setSuccess(null);

        try {
            const response = await fetch(`${backendURL}/api/school-year-list/${modalSchoolYear.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(modalSchoolYear),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Failed to update the school year');
            }

            const data = await response.json();
            setSuccess('School year updated successfully!');
            fetchSchoolYears(); // Refresh the school year list
            setShowModal(false);
        } catch (error) {
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const generateNextSchoolYear = () => {
        const existingYears = schoolYearList.map(year => year.school_year);
        const currentYear = new Date().getFullYear();
        let nextYearStart = currentYear;
        let nextYearEnd = currentYear + 1;
        let nextYear = `${nextYearStart}-${nextYearEnd}`;

        while (existingYears.includes(nextYear)) {
            nextYearStart++;
            nextYearEnd++;
            nextYear = `${nextYearStart}-${nextYearEnd}`;
        }

        return nextYear;
    };

    const handleCreateNewYear = async () => {
        const newSchoolYear = generateNextSchoolYear();

        const response = await fetch(`${backendURL}/api/school-year-list`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ school_year: newSchoolYear, active: false }),
            credentials: 'include'
        });

        if (response.ok) {
            const data = await response.json();
            setSuccess('New school year created successfully!');
            fetchSchoolYears(); // Refresh the school year list
        } else {
            setError('Failed to create new school year');
        }
    };

    return (
        <div>
            <h3>Select Active School Year</h3>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <Button variant="primary" onClick={handleCreateNewYear}>
                Create New School Year
            </Button>
            <h3>School Year List</h3>
            <h5>This component allows you to set the current active school year for the Form Submission that everyone submits their ticket request to.
                This in turn controls which events are displayed to the user. 
            </h5>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Active</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {schoolYearList.map((year) => (
                        <tr key={year.id}>
                            <td>{year.school_year}</td>
                            <td>{year.active ? 'Yes' : 'No'}</td>
                            <td>
                                <Button variant="secondary" onClick={() => handleEditClick(year)}>
                                    Edit
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit School Year</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Label>{'School Year: '}</Form.Label>
                        <span>{` ${modalSchoolYear?.school_year}`}</span>
                        <Form.Group controlId="modalSchoolYearActive">
                            <Form.Label>Active</Form.Label>
                            <Form.Control
                                as="select"
                                name="active"
                                value={modalSchoolYear?.active || ''}
                                onChange={handleModalChange}
                            >
                                <option value={''}>Select status</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleModalUpdate} disabled={isLoading}>
                        {isLoading ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                {' '}Loading...
                            </>
                        ) : 'Save Changes'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default SelectSchoolYearComponent;
