import './CSS/App.css';
import { AuthProvider } from './store/AuthContext';
import { UserDataProvider } from './store/UserDataContext';
import { SchoolYearInfo } from './store/SchoolYearCalculator';
import LoginPage from './loginForm';
import MainPage from './MainPage';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import FormSubmission from './FormSubmission';
import ProtectedRoute from './protectedRoute';
import NewAccountForm from "./crudFiles/appUser";
import Management from './management';
import SchoolGenreComponent from './crudFiles/schoolGenre';
import TicketTable from './staffHomePage';
import TicketOfficeMain from './ticketOfficeMain';
import { useAuth } from './store/AuthContext';
import { useState } from 'react';
import Reporting from './Reporting';
import AdjudicationEvent from './UHSMTAfiles/adjudicationEvent';
import UHSMTAMainPage from './uhsmtaMain';

function AppWithRouter() {
    const navigate = useNavigate();
    const [resetUserData, setResetUserData] = useState(() => () => {});



    return (
        <div className='App'>
            <AuthProvider navigate={navigate}>
                <UserDataProvider onLogout={resetUserData}>
                    <SchoolYearInfo>
                        <Routes>
                            <Route path="/login" element={<LoginPage />} />
                            <Route element={<ProtectedRoute />}>
                                <Route path="/main" element={<MainPage />} />
                                {/* <Route path="/new-account" element={<NewAccountForm />} /> */}
                                <Route path="/formsubmission" element={<FormSubmission />} />
                                <Route path="/management" element={<Management />} />
                                {/* <Route path="/schoolgenre" element={<SchoolGenreComponent />} /> */}
                                <Route path="/ticket-office-main" element={<TicketOfficeMain />} />
                                <Route path='/reporting' element={<Reporting />} />
                                <Route path='/uhsmtaMain' element={<UHSMTAMainPage />}></Route>
                                {/* You can add more protected routes here */}
                            </Route>
                            <Route path="*" element={<Navigate to="/main" replace />} />
                        </Routes>
                    </SchoolYearInfo>
                </UserDataProvider>
            </AuthProvider>
        </div>
    );
}




function App() {


    return (
        <Router>
            <AppWithRouter />
        </Router>
    );
}

export default App;
