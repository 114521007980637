import React, {useEffect, useState, useSyncExternalStore} from "react";
import { Container, Button, Fade, Form, Alert } from "react-bootstrap";
import { useAuth } from "./store/AuthContext";
import { useUserData } from "./store/UserDataContext";
import { useNavigate } from "react-router-dom";
import './CSS/MainPage.css';
import NavbarComponent from './Nav';
import ClassroomComponent from './crudFiles/classroom'
import ErrorModalMessage from "./store/ErrorMessage";


function MainPage() {
    const [showGreeting, setShowGreeting] = useState(true);
    const [showClassroom, setShowClassroom] = useState(false); // Additional state to control the ClassroomComponent visibility
    const { userData } = useUserData();
    const navigate = useNavigate();
    const { authState } = useAuth();
    const [ errorMessage, setErrorMessage ] = useState(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowGreeting(false);
            setShowClassroom(true); // After the greeting fades, show the ClassroomComponent
        }, 3000);

        return () => clearTimeout(timer); // Clean up the timer
    }, []);

    useEffect(() => {
        if (!authState) {
            setErrorMessage("Looks like you will need to log back in.");
            navigate('/login');
        }
    }, [authState, navigate]);

    return (    
        <div>
            {authState && (
                <div>
                    <NavbarComponent/>
                    
                    {showClassroom && <div className="fade-in"><ClassroomComponent /></div>}
                    {showGreeting ? <Container className="vh-100 d-flex align-items-center justify-content-center">
                    {showGreeting && (
                            <div className="fade-in" style={{animationDuration: '2s'}}> {/* Adjust the animation duration here */}
                                <h1>Hello, {userData ? userData.first_name : 'Loading...'}!</h1>
                            </div>
                        )}
                    </Container> : <></>}
                    <ErrorModalMessage errorMessage={errorMessage} clearErrorMessage={()=> {setErrorMessage(null)}} />
                </div>
            )}
        </div>
    );
}

export default MainPage;
