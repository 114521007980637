import React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./store/AuthContext";
import NavbarComponent from "./Nav";
import { backendURL } from "./IPaddress";
import { useSchoolYear } from "./store/SchoolYearCalculator";

function Reporting() {
    const { authState } = useAuth();
    const navigate = useNavigate();
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [selectedSchoolYear, setSelectedSchoolYear] = useState();
    const { currentYear, schoolYearListParent } = useSchoolYear();

    useEffect(() => {
        if (!authState) {
            navigate('/login');
        }
    }, [authState, navigate]);

    useEffect(() => {
        if (currentYear && schoolYearListParent.length) {
            setSelectedSchoolYear(currentYear);
            setSchoolYearList(schoolYearListParent);
        }
    }, [currentYear, schoolYearListParent]);

    const getSchoolYearName = (selectedSchoolYear) => {
        const yearName = schoolYearListParent.find(year => year.id === parseInt(selectedSchoolYear, 10));


        if (yearName) {
            return yearName.school_year;
        } else {
            console.error('Year name not found');
            return 'Unknown Year';
        }
    };

    const downloadReport = async (endpoint, reportName) => {
        try {
            const response = await fetch(`${backendURL}/api/${endpoint}/${selectedSchoolYear}`, {
                method: 'GET',
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            const yearName = getSchoolYearName(selectedSchoolYear);
            link.download = `${reportName}_${yearName}`;
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error(`Failed to download the ${reportName} report:`, error);
        }
    };

    const handleYearChange = (event) => {
        setSelectedSchoolYear(event.target.value);
    };

    return (
        <div>
            {authState && <NavbarComponent />}
            <h1>Reports Page</h1>
            <div>
                <Dropdown>
                    <span className='fw-bold'>Select School Year</span>
                    <select value={selectedSchoolYear} onChange={handleYearChange}>
                        <option value="" disabled>Select School Year</option>
                        {schoolYearList.map((year) => (
                            <option key={year.id} value={year.id}>
                                {year.school_year}
                            </option>
                        ))}
                    </select>
                </Dropdown>
            </div>
            <div>
                <h2>OBC Reports: </h2>
                <ul><Button onClick={() => downloadReport('download_A_OBC_workshop_report', 'A_OBC_workshop_report')}>Download A. OBC Workshop Report</Button></ul>
                <ul><Button onClick={() => downloadReport('download_B_obc_classroom_implementation_report', 'B_obc_classroom_implementation_report')}>Download B. OBC Classroom Implementation Report</Button></ul>
                <ul><Button onClick={() => downloadReport('download_C_OBC_performance_report', 'C_OBC_performance_report')}>Download C. OBC Performance Report</Button></ul>
            </div>
            <div>
                <h2>Attendance Report: </h2>
                <Button onClick={() => downloadReport('download_student_teacher_report', 'A_student_teacher_in_attendance_report')}>Download A. Student Teacher In Attendance Report</Button>
            </div>
            <div>
                <h2>UHSMTA Reports: </h2>
                <ul><Button onClick={() => downloadReport('download_UHSMTA_adjudication_feedback_report', 'A_UHSMTA_adjudication_feedback_report')}>Download A. UHSMTA Adjudication Feedback Report</Button></ul>
                <ul><Button onClick={() => downloadReport('download_B_UHSMTA_group_or_one_on_one_report', 'B_UHSMTA_group_or_one_on_one_report')}>Download B. UHSMTA Group/one on one Coaching Report</Button></ul>
                <ul><Button onClick={() => downloadReport('download_C_UHSMTA_workshops_report', 'C_UHSMTA_workshops_report')}>Download C. UHSMTA Workshops Report</Button></ul>
                <ul><Button onClick={() => downloadReport('download_D_UHSMTA_award_shows_report', 'D_UHSMTA_award_shows_report')}>Download D. UHSMTA Award Shows Report</Button></ul>
                <ul><Button onClick={() => downloadReport('download_E_UHSMTA_winners_report', 'E_UHSMTA_winners_report')}>Download E. UHSMTA Winners Report</Button></ul>
                <ul><Button onClick={() => downloadReport('download_F_UHSMTA_interns_report', 'F_UHSMTA_interns_report')}>Download F. UHSMTA Interns Report</Button></ul>
                <ul><Button onClick={() => downloadReport('download_G_UHSMTA_special_performance_report', 'G_UHSMTA_special_performance_report')}>Download G. UHSMTA Special Performance Report</Button></ul>
            </div>
            <div>
                <h2>Full Data & Total Reports:</h2>
                <ul><Button onClick={() => downloadReport('download_get_full_data_report', 'get_full_data_report')}>Download Full Data Report</Button></ul>
                <ul><Button onClick={() => downloadReport('download_get_total_schools_report', 'get_total_schools_report')}>Download Total Schools Report</Button></ul>
            </div>
        </div>
    );
}

export default Reporting;
