import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../store/AuthContext';
import { backendURL } from '../IPaddress';
import 'bootstrap-icons/font/bootstrap-icons.css';


const TicketStatusComponent = () => {
  const [ticketStatuses, setTicketStatuses] = useState([]);
  const [currentTicketStatus, setCurrentTicketStatus] = useState({ 
    name: '',
    description: '',
    color: ''
  });
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [ticketStatusToDelete, setTicketStatusToDelete] = useState(null);
  const { authState } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState) {
      navigate('/login');
    }
  }, [authState, navigate]);

  useEffect(() => {
    fetchTicketStatuses();
  }, []);

  const fetchTicketStatuses = async () => {
    const response = await fetch(`${backendURL}/api/ticket-statuses`, {
      method: 'GET',
      credentials: 'include'
    });
    const data = await response.json();
    setTicketStatuses(data);
  };

  const handleFormChange = (e) => {
    setCurrentTicketStatus({ ...currentTicketStatus, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentTicketStatus.ticket_status_id) {
      await updateTicketStatus(currentTicketStatus.ticket_status_id, currentTicketStatus);
    } else {
      await createTicketStatus(currentTicketStatus);
    }
    setCurrentTicketStatus({ name: '', description: '', color: '' });
    setShowForm(false);
    fetchTicketStatuses();
  };

  const createTicketStatus = async (ticketStatusData) => {
    await fetch(`${backendURL}/api/ticket-statuses`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(ticketStatusData),
      credentials: 'include'
    });
  };

  const updateTicketStatus = async (ticketStatusId, ticketStatusData) => {
    await fetch(`${backendURL}/api/ticket-statuses/${ticketStatusId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(ticketStatusData),
      credentials: 'include'
    });
  };

  const deleteTicketStatus = async () => {
    if (ticketStatusToDelete) {
      await fetch(`${backendURL}/api/ticket-statuses/${ticketStatusToDelete}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      setTicketStatusToDelete(null);
      setShowModal(false);
      fetchTicketStatuses();
    }
  };

  const editTicketStatus = (ticketStatus) => {
    setCurrentTicketStatus(ticketStatus);
    setShowForm(true);
  };

  const handleCancel = () => {
    setCurrentTicketStatus({ name: '', description: '', color: '' });
    setShowForm(false);
  };

  return (
    <div>
      <Button onClick={() => setShowForm(!showForm)} className="m-1">
        {showForm ? 'Show Ticket Statuses List' : 'Add New Ticket Status'}
      </Button>

      {showForm ? (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control 
              type="text"
              placeholder="Enter status name"
              name="name"
              value={currentTicketStatus.name}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control 
              as="textarea"
              placeholder="Enter status description"
              name="description"
              value={currentTicketStatus.description}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Form.Group controlId="color">
            <Form.Label>Color</Form.Label>
            <Form.Control 
              type="text"
              placeholder="Enter status color"
              name="color"
              value={currentTicketStatus.color}
              onChange={handleFormChange}
            />
          </Form.Group>
          <Button type="submit" className="m-1">Submit</Button>
          <Button variant="secondary" onClick={handleCancel} className="ml-2">Cancel</Button>
        </Form>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Status Name</th>
              <th>Description</th>
              <th>Color</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {ticketStatuses.map((status) => (
              <tr key={status.ticket_status_id}>
                <td>{status.name}</td>
                <td>{status.description}</td>
                <td style={{backgroundColor: status.color}}>{status.color}</td>
                <td>
                  <Button onClick={() => editTicketStatus(status)} className="m-1"><i className="bi bi-pencil-square"></i></Button>
                  <Button variant="danger" onClick={() => {
                    setTicketStatusToDelete(status.ticket_status_id);
                    setShowModal(true);
                  }} className="m-1"><i className="bi bi-trash3"></i></Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this ticket status?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteTicketStatus}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TicketStatusComponent;
