import React, { useState, useEffect, useSyncExternalStore, useDebugValue } from 'react';
import { Modal, Button, Form, Table, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import { createHashRouter, useAsyncError, useNavigate } from 'react-router-dom';
import { useAuth } from '../store/AuthContext';
import { useSchoolYear } from '../store/SchoolYearCalculator';

import { backendURL } from '../IPaddress';
import '../CSS/CRUDfiles.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Collapse from 'react-bootstrap/Collapse';
import {useUserData} from '../store/UserDataContext';
import ErrorModalMessage from '../store/ErrorMessage';

const ClassroomComponent = () => {
  const [classrooms, setClassrooms] = useState([]);
  const [currentClassroom, setCurrentClassroom] = useState({
    size: null, 
    grade_id: '', 
    teacher_id: '', 
    drama_mentor_id: '', 
    art_mentor_id: '', 
    music_mentor_id: '',
    school_id: '',
    classroom_id: '',
    teacher_profile_id: null,
    service_type_id: '',
  });
  const [showForm, setShowForm] = useState(false);
  const [openStates, setOpenStates] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [classroomToDelete, setClassroomToDelete] = useState(null);
  const { authState } = useAuth();
  const { userData } = useUserData();
  const navigate = useNavigate();
  const [ mentors, setMentors ] = useState([]);
  const [ teachers, setTeachers ] = useState([]);
  const [teacherSearch, setTeacherSearch] = useState("");
  // const [selectedTeacherName, setSelectedTeacherName] = useState('');
  const [dramaMentorSearch, setDramaMentorSearch] = useState("");
  const [artMentorSearch, setArtMentorSearch] = useState("");
  const [musicMentorSearch, setMusicMentorSearch] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [selectedDramaMentor, setSelectedDramaMentor] = useState(null);
  const [selectedArtMentor, setSelectedArtMentor] = useState(null);
  const [selectedMusicMentor, setSelectedMusicMentor] = useState(null);
  const [ grades, setGrades ] = useState([]);
  const [ineligibleGradeModal, setIneligibleGradeModal] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [ schools, setSchools] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [homeSchoolModal, setHomeSchoolModal] = useState(false);
  const [privateSchoolModal, setPrivateSchoolModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredClassrooms, setFilteredClassrooms] = useState([]);
  const [eventsPrograms, setEventsPrograms] = useState([]);
  const [ eventTypes, setEventTypes] = useState([]);
  const [ eventDates, setEventDates ] = useState([]);
  const [currentEventDate, setCurrentEventDate ] = useState({
    event_dates_id: '',
    event_id: '', 
    date: '', 
    start_time: '', 
    end_time: '',
    // mentor_id: ''
  })
  const [isEditingClassroom, setIsEditingClassroom] = useState(false);
  const [showNewVisitModal, setShowNewVisitModal] = useState(false);
  const [ teacherStatuses, setTeacherStatuses] = useState([]);
  const [ errorMessage, setErrorMessage] = useState(null);
  const [ teacherProfiles, setTeacherProfiles] = useState([]);
  const [ isEditingVisit, setIsEditingVisit ] = useState(false);
  const [ classroomStatuses, setClassroomStatuses ] = useState([]);
  const [expandedClassroomId, setExpandedClassroomId] = useState(null);
  const [ classroomServiceTypes, setClassroomServiceTypes ] = useState([]);
  const [schoolYearList, setSchoolYearList] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState();
  const {currentYear, schoolYearListParent} = useSchoolYear();


  // Authentication check
  useEffect(() => {
    if (!authState) {
      
      setErrorMessage("Looks like you will need to log back in.");
      navigate('/login');
    }
  }, [authState, navigate]);

  useEffect(() => {
    if (currentYear && schoolYearListParent.length) {
      setSelectedSchoolYear(currentYear);
      setSchoolYearList(schoolYearListParent);
    }
  }, [currentYear, schoolYearListParent]);
  
  useEffect(() => {
    if (selectedSchoolYear) {
      fetchClassrooms();
      fetchEventsPrograms();
      fetchEventDates();
    }
  }, [selectedSchoolYear]);
  
  useEffect(() => {
    settingFilteredSchools();
  }, [searchQuery, classrooms]);
  
  // Initial fetch for static or less frequently changed data
  useEffect(() => {
    fetchUsers();
    fetchGrades();
    fetchSchools();
    fetchDistricts();
    fetchEventTypes();
    fetchTeacherStatuses();
    fetchTeacherProfiles();
    fetchClassroomStatuses();
    fetchClassroomServiceTypes();
  }, []);

  

  const fetchSchools = async () => {
    // setLoading(prev => ({ ...prev, schools: true }));
    const response = await fetch(`${backendURL}/api/schools`);
    
    if (!response.ok) {
      setErrorMessage('Unable to retrieve school information');
    };
    const data = await response.json();
    
    if (Array.isArray(data)) {
      setSchools(data);

  } else {
      setErrorMessage('Fetched Schools data is not an array:', data);
  }} 
  const fetchTeacherProfiles = async() => {
    const response = await fetch(`${backendURL}/api/profiles`, {
      method:'GET',
      credentials: 'include'
    });
    if (!response.ok) {
      setErrorMessage('Unable to fetch teacher profiles.')
    }
    const data = await response.json();
    const filteredTeacherProfiles = data.filter( profile => profile.teacher_status === true);
    // console.log('teacher profiles: ', filteredTeacherProfiles)
    setTeacherProfiles(filteredTeacherProfiles)
  }
  const fetchClassroomStatuses = async () => {
    const response = await fetch(`${backendURL}/api/classroom-statuses`,{
      method: 'GET',
      credentials: 'include'
    })
    if (!response.ok){
      setErrorMessage('Error fetching the classroom statuses')
    }
    const data = await response.json();
    setClassroomStatuses(data);
  }

  const fetchDistricts = async () => {
    const url = `${backendURL}/api/districts`;
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      setDistricts(data);
    } else {
      setErrorMessage('Unable to retrieve districts');
    }
  }
  const fetchClassroomServiceTypes = async () => {
    const response = await fetch(`${backendURL}/api/classroom-service-types`, {
      method: 'GET',
      credentials: 'include'
    })
    if (response.ok){
      const data = await response.json();
      setClassroomServiceTypes(data)
    } else {
      setErrorMessage('error fetching the classroom service types');
      return;
    }
  }

  const fetchEventsPrograms = async () => {
    try {const response = await fetch(`${backendURL}/api/events-programs/year/${selectedSchoolYear}`, {
      method: 'GET',
    //   credentials: 'include',
    });
    if (!response.ok){
      console.log(response)
    }
    const data = await response.json();
    setEventsPrograms(data);}
    catch {
      ErrorModalMessage('unable to fetch program events')
    }
  };

  const fetchTeacherStatuses = async () => {
    const response = await fetch(`${backendURL}/api/teacherstatuses`, {
      method: 'GET',
      credentials: 'include',
    });
    const data = await response.json();
    setTeacherStatuses(data);
  };

  const fetchEventTypes = async () => {
    const response = await fetch(`${backendURL}/api/event-types`, {
      method: 'GET',
      credentials: 'include'
    });
    try{
      const data = await response.json();
      setEventTypes(data);
    } catch (error) {
      setErrorMessage("Error fetching the event types: ", error.detail)
    }
  }

  const fetchEventDates = async () => {
    try {const response = await fetch(`${backendURL}/api/event-dates/year/${selectedSchoolYear}`, {
        method: 'GET',
        credentials: 'include'
    });
    const data = await response.json();
    const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
      setEventDates(sortedData);}
    catch (error) {
        setErrorMessage("Error fetching event dates: ", error.detail);
    }
  }

  const fetchClassrooms = async () => {
    if (selectedSchoolYear) {
      try {
        const response = await fetch(`${backendURL}/api/classrooms/year/${selectedSchoolYear}`, {
          method: 'GET',
          credentials: 'include',
        });
        if (!response.ok) {
          throw new Error('Error fetching the classrooms');
        }
        const data = await response.json();
        setClassrooms(data);
      } catch (error) {
        setErrorMessage(error.message);
      }
    } else {
      console.log("selected school year: ", selectedSchoolYear);
    }
  };

  const fetchGrades = async () => {
    const response = await fetch(`${backendURL}/api/grades`, {
      method: 'GET',
      credentials: 'include'
    });
    const data = await response.json();
    setGrades(data);
  };

  const fetchUsers = async () => {
    const response = await fetch(`${backendURL}/api/app-users`, {
      method: 'GET',
      credentials: 'include'
    });
    const data = await response.json();
    const teachers = data.filter(user => user.type_id === 1)
    const mentors = data.filter(user => user.type_id === 3 || user.type_id === 5)
    setMentors(mentors);
    setTeachers(teachers);
  };

  const schoolOptions = schools.map(school_id => {
    const district = districts.find(d => d.district_id === school_id.district_id);
    const districtName = district ? district.name : 'Unknown District';
    
    return {
      label: `${school_id.name} (${districtName})`,
      value: school_id.school_id
    };
  });

  const handleSchoolChange = selectedOption => {
    setSelectedSchool(selectedOption);
    let schoolId = null;
    if (selectedOption) {
        schoolId = parseInt(selectedOption.value, 10);
    if (isNaN(schoolId)) {
        console.warn(`Warning: could not parse school_id value "${selectedOption.value}" as an integer`);
        schoolId = null;
        }}
        setCurrentClassroom({
        ...currentClassroom,
        school_id: schoolId,
        school_year_id: parseInt(selectedSchoolYear, 10),
        });

        const selectedSchoolObj = schools.find(school_id => school_id.school_id === schoolId);
    if (selectedOption && selectedOption.label.includes("(Private)")) {
        setPrivateSchoolModal(true);
        setDisableSubmit(true);
        // Optionally, you may clear the form and selections here
        setCurrentClassroom({
            size: null, 
            grade_id: '', 
            teacher_id: '', 
            drama_mentor_id: '', 
            art_mentor_id: '', 
            music_mentor_id: '',
            school_id: '',
            teacher_profile_id: null,
            service_type_id: '',
        });
        setSelectedSchool(null);} 
        // else if (selectedSchoolObj && selectedSchoolObj.name === "Home-School") {
        //   setHomeSchoolModal(true);
        //   setDisableSubmit(true);
        //   setCurrentClassroom({
        //       size: null, 
        //       grade_id: '', 
        //       teacher_id: '', 
        //       drama_mentor_id: '', 
        //       art_mentor_id: '', 
        //       music_mentor_id: '',
        //       school_id: '',
        //       teacher_profile_id: null,
        //       service_type_id: '',
        //   });
        //   setSelectedSchool(null);
        // }
         else {
        setDisableSubmit(false);
    }
};

const handleMentorSelect = (mentor, mentorType) => {
  setCurrentClassroom({
    ...currentClassroom,
    [`${mentorType}_id`]: mentor.user_id,
    school_year_id: parseInt(selectedSchoolYear, 10),
  });

  if (mentorType === 'drama_mentor') {
    setSelectedDramaMentor(mentor);
  } else if (mentorType === 'art_mentor') {
    setSelectedArtMentor(mentor);
  } else if (mentorType === 'music_mentor') {
    setSelectedMusicMentor(mentor);
  }
};

  const handleTeacherSelect = (teacher) => {
    setCurrentClassroom({
      ...currentClassroom,
      teacher_profile_id: teacher.profile_id,
      school_year_id: parseInt(selectedSchoolYear, 10),
    });
    setSelectedTeacher(teacher);
  };

    
  const filterTeachers = (searchTerm) => {
        return teachers.filter(teacher =>
          `${teacher.first_name} ${teacher.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()))
      }

  // const handleFormChange = (e) => {
  //   setCurrentClassroom({ ...currentClassroom, [e.target.name]: e.target.value });
  // };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
  
    // Check if the form element should be an integer and convert it
    if (name === 'service_type_id' && value) {
      setCurrentClassroom(prevState => ({
        ...prevState,
        [name]: parseInt(value, 10), // Ensure value is treated as a base-10 integer
        school_year_id: parseInt(selectedSchoolYear, 10),
      }));
    } else {
      setCurrentClassroom(prevState => ({
        ...prevState,
        [name]: value,
        school_year_id: parseInt(selectedSchoolYear, 10),
      }));
    }
  };
  
  


  function handleIntegerChange(event) {
    const { name, value } = event.target;
    // Parse the input value as an integer
    const intValue = parseInt(value, 10);
    // Check for NaN values which can occur if the input is not a number
    // and decide how you want to handle it. Here, we fallback to 0 if NaN is encountered.
    // Alternatively, you could use '' or any other fallback value as needed.
    const validValue = isNaN(intValue) ? 0 : intValue;
  
    // Assuming you have a setState or similar function to update your state
    // For example, if you're managing form values in a state object named formData

    
    setCurrentClassroom({
      ...currentClassroom,
      [name]: validValue,
    });
  }
  const handleGradeChange = (e) => {
    const gradeValueStr = e.target.value;
    let gradeValue = parseInt(gradeValueStr, 10);
    
    // Check if the parsed value is NaN and handle accordingly
    if (isNaN(gradeValue)) {
        console.warn(`Warning: could not parse grade value "${gradeValueStr}" as an integer`);
        gradeValue = null; // or handle differently based on your use-case
    }
        // Find the selected grade object
        const selectedGrade = grades.find(grade => grade.grade_id === gradeValue);

    // Check if selected grade is one of the ineligible grades
    if (selectedGrade && ["College", "Community", "Preschool"].includes(selectedGrade.grade)) {
        setIneligibleGradeModal(true);
        setDisableSubmit(true);
        
        // setSelectedSchool(null);
        // setSelectedEventProgram(null);
        
    } else {
        setDisableSubmit(false);
        setCurrentClassroom({
            ...currentClassroom,
            grade_id: gradeValue
        });
    } 
};
const handleEventDateFormChange = (e) => {
  setCurrentEventDate({ ...currentEventDate, [e.target.name]: e.target.value });
};
// const handleEventFormChange = (e) => {
//   console.log("being set at the handle")
//   setCurrentEvent({ ...currentEvent, [e.target.name]: e.target.value });
// };
const editEventDate = (eventDateId) => {
  setCurrentEventDate(eventDateId);
  setShowNewVisitModal(true);
  setIsEditingVisit(true);
};

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (currentClassroom.classroom_id) {
      await updateClassroom(currentClassroom.classroom_id, currentClassroom);
    } 
    if (!currentClassroom.classroom_id) {
      await createClassroom(currentClassroom);
    }
    setCurrentClassroom({
      size: null, 
      grade_id: '', 
      teacher_id: '', 
      drama_mentor_id: '', 
      art_mentor_id: '', 
      music_mentor_id: '',
      school_id: '',
      classroom_id: '',
      teacher_profile_id: null,
      service_type_id: '',
    });
    setSelectedSchool(null);
    setSelectedArtMentor(null);
    setSelectedMusicMentor(null);
    setSelectedTeacher(null);
    setSelectedDramaMentor(null);
    setShowForm(false);
    setIsEditingClassroom(false);
    fetchClassrooms();
    fetchEventsPrograms();
    fetchClassroomStatuses();
  };

  const getGradeType = (gradeId) => {
    const grade = grades.find(g => g.grade_id === gradeId)
    if (grade) {
      return grade.grade
    }
    return "Unknown Grade";
  }

  const getTeacherDetails = (teacherId) => {
    const teacher = teacherProfiles.find(t => t.profile_id === teacherId);

    if (!teacher) return null;

    return (
      <div className="teacher-content-box">
        <div className="teacher-info-container">
          {/* Teacher's name */}
          <div className="teacher-contact-info-container">
            <span className='teacher-contact-info'>Teacher: </span>
            <span>{teacher.first_name} {teacher.last_name}</span>
          </div>

          {/* Teacher's phone numbers */}
          {teacher.home_phone_number && (
            <div className="teacher-contact-info-container">
              <span className='teacher-contact-info'>Home: </span>
              <span>{teacher.home_phone_number}</span>
            </div>
          )}
          {teacher.cell_phone_number && (
            <div className="teacher-contact-info-container">
              <span className='teacher-contact-info'>Cell: </span>
              <span>{teacher.cell_phone_number}</span>
            </div>
          )}
          {!teacher.home_phone_number && !teacher.cell_phone_number && (
            <div className="teacher-contact-info-container">
              <span className='teacher-contact-info'>No phone numbers added.</span>
            </div>
          )}

          {/* Teacher's email addresses */}
          {teacher.email && (
            <div className="teacher-contact-info-container">
              <span className='teacher-contact-info'>Email: </span>
              <span>{teacher.email}</span>
            </div>
          )}
          {teacher.secondary_email && (
            <div className="teacher-contact-info-container">
              <span className='teacher-contact-info'>2nd Email: </span>
              <span>{teacher.secondary_email}</span>
            </div>
          )}
        </div>
      </div>
    );
};



  const getTeacherStatus = (teacherId) => {
    const teacher = teacherProfiles.find(t => t.profile_id === teacherId)    
    if (teacher) {
      if (teacher.teacher_status_id === null){
        return `No status selected.`;
      } else{
        const teacherStatusObj = teacherStatuses.find(status => status.teacher_status_id === teacher.teacher_status_id);
        if (teacherStatusObj){
          return `${teacherStatusObj.status}`;
        }
      }      
    }
  }

  const getClassroomServiceType = (classroomServiceId) => {
    const serviceTypeObj = classroomServiceTypes.find(type => classroomServiceId === type.id)
    if (serviceTypeObj) {
      return (
        <span>{serviceTypeObj.service_name}</span>
      )
    } else {
      return (
        <span>{'Not Selected'}</span>
      )
    }
  }

  const getSchoolName = (schoolId) => {
    const school = schools.find(t => t.school_id === schoolId)
    if (school) {
      return `${school.name}`
    }
  }
  const getSchoolPhone = (schoolId) => {
    const school = schools.find(t => t.school_id === schoolId)
    if (school) {
      return `${school.phone}`
    }
  }
  const getSchoolAddress = (schoolId) => {
    const school = schools.find(t => t.school_id === schoolId)
    if (school) {
        let address = `${school.street} ${school.city} ${school.zip}`
        // if (address.includes(`P.O.`)) {
        //   return `Invalid Address: ${address}`
        // }
      return address
    }
  }

  const updateClassroomStatus = (classroomId, updatedStatus) => {
    setClassroomStatuses(prevStatuses => 
        prevStatuses.map(status => 
            status.classroom_id === classroomId ? updatedStatus : status
        )
    );
};

  const toggleClassroomExpansion = (classroomId) => {
    setExpandedClassroomId(prevId => prevId === classroomId ? null : classroomId);
  };

  const sendStatusUpdate = async (statusObj, classroomStatusId, key, value) => {

    const payload = {
      ...statusObj,
      id: classroomStatusId,
        [key]: value
    };

    try {
        const response = await fetch(`${backendURL}/api/classroom-statuses/${classroomStatusId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
          setErrorMessage("There was an error updating the classroom data. Your changes likely didn't save");
          return;
        }

        const data = await response.json();
        // Handle success (e.g., showing a confirmation message)
    } catch (error) {
        setErrorMessage('Error:', String(error));
        // Handle error (e.g., showing an error message)
    }
  };
  


  const ClassroomStatus = ({ classroomId, classroomStatusId, classroomStatuses, isExpanded, toggleExpansion }) => {
    const [localStatus, setLocalStatus] = useState(() => {
        const statusObj = classroomStatuses.find(status => status.classroom_id === classroomId);
        return statusObj ? { ...statusObj } : null; // Clone the status object for local manipulation
    });
    const [showStatusModal, setShowStatusModal] = useState(false);

    if (!localStatus) return null;
  
    const statusObj = classroomStatuses.find(status => status.classroom_id === classroomId);

    if (!statusObj) return null;

  
    // Predefined order of the keys
    const keyOrder = [
      "story_visit",
      "libretto_visit",
      "opera_submitted_for_review_title_summary", //change to "Libretto submitted for review"
      "libretto_sent_to_music_mentor_and_filed_on_google", 
      "melodies_recorded",
      "music_scored",
      "music_approved",
      "music_sent",
      "supertitles_complete",
      "staging_1_visit", // change to "staging visit 1"
      "staging_2_visit",
      "staging_3_visit",
      "art_intake",
      "paint_day"
    ];

    const calculateCompletionPercentage = () => {
      const filledValuesCount = keyOrder.filter(key => localStatus[key]).length;
      const totalKeysCount = keyOrder.length;
      return (filledValuesCount / totalKeysCount) * 100; // Returns the completion percentage
    };
    
    const completionPercentage = calculateCompletionPercentage();
    

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      // Convert empty string to null when a date is cleared
      const updatedValue = value === "" ? null : value;
      const updatedStatus = { ...localStatus, [name]: updatedValue };

      // Update local state
      setLocalStatus(updatedStatus);

      // Call a function to update the status in the parent component or server
      updateClassroomStatus(classroomId, updatedStatus);

      // Send the update to the server
      sendStatusUpdate(statusObj, classroomStatusId, name, updatedValue);
    };  
      // Sorting the entries by date when preparing them for display
      const entriesInOrder = keyOrder.map(key => ({
        key,
        value: localStatus[key] || null // Use an empty string for null/undefined values
    }));

    return (
        <div>
            <Button onClick={() => setShowStatusModal(true)}>{isExpanded ? 'Collapse' : 'Expand'}</Button>
            {/* Progress Bar */}
            <div className="progress-bar-container" style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '8px', marginTop: '20px' }}>
              <div style={{ height: '20px', width: `${completionPercentage}%`, backgroundColor: 'green', borderRadius: '8px' }}>
              </div>
            </div>
            <div>
              {Math.round(completionPercentage)}% Complete
            </div>
                  <ul className='classroom-status-list'>
                      {entriesInOrder.filter(({ value }) => value).map(({ key, value }) => (
                          <li className='classroom-status-item' key={key}>{`${key.replace(/_/g, ' ')}: ${value}`}</li>
                      ))}
                  </ul>
            
            <Modal size='lg' show={showStatusModal} onHide={() => setShowStatusModal(false)}>
                  <Modal.Header>Classroom Status</Modal.Header>
                  <Modal.Body>
                  <Form>
                    {entriesInOrder.map(({ key, value }) => (
                      <Form.Group key={key} className="form-group-flex">
                        <div className="label-input-pair">
                          <Form.Label className="classroom-status-label"><li>{key.replace(/_/g, ' ')}</li></Form.Label>
                          <Form.Control
                            type="date"
                            name={key}
                            value={value}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Form.Group>
                    ))}
                  </Form>
                </Modal.Body>
              </Modal>
        </div>
    );
};
  

  const getMentorName = (mentorId) => {
    const mentor = mentors.find(t => t.user_id === mentorId)
    if (mentor) {
      return `${mentor.first_name} ${mentor.last_name}`
    }
  }


  const getEventProgram = (classroomId) => {
  try {
    if (eventsPrograms){
      const eventProgramObj = eventsPrograms.filter(eventProgram =>
      eventProgram.classroom_id === classroomId && eventProgram.event_type_id == 2
      );
    //  the Drama, Art, Music Program events are created upon creating a classroom. 
    //  and then the application looks for those event_ids and assigns them their
    //  music, art, and drama events. 

  return eventProgramObj.length > 0 ? (
    <div className='mentor-events-visits' >
      {eventProgramObj.map(eventProgram => (
        <div className='mentor-event-item' key={eventProgram.event_id} >
          <Button onClick={() => toggleOpenState(eventProgram.event_id)}>
            {eventProgram.name}
          </Button>
          <Collapse in={openStates[eventProgram.event_id] || false}>
            <div id={`event-dates-${eventProgram.event_id}`}>
              <Button onClick={() => handleCreateEventDate(eventProgram.event_id)}>
                New visit
              </Button>
              <div>
                {getEventDates(eventProgram.event_id)}
              </div>
            </div>
          </Collapse>
        </div>
      ))}
    </div>
  ) : <p>No visits found</p>;}}
  catch{
    ErrorModalMessage("error filtering the event programs related to classrooms")
  }
  }

  const getPerformanceEvent = (classroomId) => {
    const eventProgramObjs = eventsPrograms.filter(eventProgram =>
       eventProgram.classroom_id == classroomId && eventProgram.event_type_id == 1);

    return eventProgramObjs.length > 0 ? (
      <div className='mentor-events' >
        {eventProgramObjs.map(eventProgram => (
          <div className='mentor-event-item' key={eventProgram.event_id} >
            <Button onClick={() => toggleOpenState(eventProgram.event_id)}>
              {eventProgram.name}
            </Button>
            <Collapse in={openStates[eventProgram.event_id] || false}>
              <div id={`event-dates-${eventProgram.event_id}`}>
                <Button onClick={() => handleCreateEventDate(eventProgram.event_id)}>
                  New Performance
                </Button>
                <div>{getEventDates(eventProgram.event_id)}</div>
              </div>
            </Collapse>
          </div>
        ))}
      </div>
    ) : <p>No performances found</p>;
  }

  const getWorkshopEvent = (teacherProfileId) => {
    const profileObj = teacherProfiles.filter(profile => 
      profile.profile_id === teacherProfileId)

    if (profileObj.length > 0){
      const eventProgramObj = eventsPrograms.filter(eventProgram =>
       eventProgram.event_id === profileObj[0].workshop_event_id
       );
       return eventProgramObj.length > 0 ? (
        <div className='mentor-events' >
          {eventProgramObj.map(eventProgram => (
            <div className='mentor-event-item' key={eventProgram.event_id} >
              <Button onClick={() => toggleOpenState(eventProgram.event_id)}>
                {'Workshops'}
              </Button>
              <Collapse in={openStates[eventProgram.event_id] || false}>
                <div id={`event-dates-${eventProgram.event_id}`}>
                  <Button onClick={() => handleCreateEventDate(eventProgram.event_id)}>
                    New Workshop
                  </Button>
                  <div>{getEventDates(eventProgram.event_id)}</div>
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      ) : <p>No workshops found</p>;

      }    
  }
  

  const formatTime = (timeString) => {
    // Assuming timeString is in 'HH:MM:SS' format
    const [hours, minutes] = timeString.split(':');
    const hoursInt = parseInt(hours, 10);
    const suffix = hoursInt >= 12 ? 'PM' : 'AM';
    const formattedHours = ((hoursInt + 11) % 12 + 1); // Convert 24hr to 12hr format
    return `${formattedHours}:${minutes} ${suffix}`;
  };
  

  const getEventDates = (eventId) => {
    try {const eventDateObj = eventDates.filter(eventDate => eventDate.event_id === eventId);
    return eventDateObj.length > 0 ? (
      <ul className='event-dates-container'>
        {eventDateObj.map(eventDate => (
          <li key={eventDate.event_dates_id} className='event-dates-list' onClick={() => editEventDate(eventDate)}>
            {eventDate.date} - From: {formatTime(eventDate.start_time)} to {formatTime(eventDate.end_time)}
          </li>
        ))}
      </ul>
    ) : 'No Visits Found';}
    catch {
      ErrorModalMessage('unable to display mentor visits')
    }
  }


  const createClassroom = async (classroomData) => {

    await fetch(`${backendURL}/api/classrooms`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(classroomData),
      credentials: 'include'
    });
  };
 

  const updateClassroom = async (classroomId, classroomData) => {

    await fetch(`${backendURL}/api/classrooms/${classroomId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(classroomData),
      credentials: 'include'
    });


    setCurrentClassroom({
      size: null, 
      grade_id: '', 
      teacher_id: '', 
      drama_mentor_id: '', 
      art_mentor_id: '', 
      music_mentor_id: '',
      school_id: '',
      classroom_id: '',
      teacher_profile_id: null,
      service_type_id: '',
    });
  };

  const deleteClassroom = async () => {
    try {
      if (classroomToDelete) {
      const response = await fetch(`${backendURL}/api/classrooms/${classroomToDelete}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (!response.ok) {
        setErrorMessage(
          'Unable to delete this classroom. Check to see if one of the events has a date and delete that first. Then try deleting the classroom again.');
        setShowModal(false); 
        return;
      }
      
      setClassroomToDelete(null);
      setShowModal(false);
      fetchClassrooms();
      }
    } catch (error) {
      setErrorMessage(
        'Unable to delete this classroom. Check to see if one of the events has a date and delete that first. Then try deleting the classroom again.');
      setShowModal(false); 
      return;
    }
  };

  const editClassroom = (classroom) => {
    // Set the current classroom state with the selected classroom data
    setIsEditingClassroom(true);
    setCurrentClassroom({
        size: classroom.size,
        grade_id: classroom.grade_id,
        teacher_id: classroom.teacher_id,
        drama_mentor_id: classroom.drama_mentor_id,
        art_mentor_id: classroom.art_mentor_id,
        music_mentor_id: classroom.music_mentor_id,
        school_id: classroom.school_id,
        classroom_id: classroom.classroom_id,
        teacher_profile_id: classroom.teacher_profile_id,
        service_type_id: classroom.service_type_id
    });
    const schoolObject = schoolOptions.find(school => school.value === classroom.school_id);
        
    const dramaMentorObj = mentors.find(mentor => mentor.user_id === classroom.drama_mentor_id);

    const artMentorObj = mentors.find(mentor => mentor.user_id === classroom.art_mentor_id);
    
    const musicMentorObj = mentors.find(mentor => mentor.user_id === classroom.music_mentor_id);
    
    const teacherObj = teacherProfiles.find(teacher => teacher.profile_id === classroom.teacher_profile_id);
    

    setSelectedSchool(schoolObject);
    setSelectedArtMentor(artMentorObj);
    setSelectedDramaMentor(dramaMentorObj);
    setSelectedMusicMentor(musicMentorObj);
    setSelectedTeacher(teacherObj);
    setShowForm(true);
};

  const toggleOpenState = (eventId) => {
    setOpenStates((prevOpenStates) => ({
      ...prevOpenStates,
      [eventId]: !prevOpenStates[eventId],
    }));
  };

  const settingFilteredSchools = () => {
    try {if (searchQuery.length > 0){
      setFilteredClassrooms(
      classrooms.filter(
        classroom => getSchoolName(classroom.school_id).toLowerCase().includes(searchQuery.toLowerCase())
    ));
    }else{
      setFilteredClassrooms(classrooms);
    }} catch {
      ErrorModalMessage('classroom list is empty')
    }
  }


  const handleCreateEventDate = (eventId) => {
    setCurrentEventDate({
      ...currentEventDate,
      event_id: eventId,
      school_year_id: parseInt(selectedSchoolYear, 10),
    })
    setShowNewVisitModal(true)
  }

  const handleVisitSubmit = async (e) => {
    e.preventDefault();
    console.log('current event date data: ', currentEventDate.event_dates_id)
    if (!currentEventDate.event_dates_id){
      currentEventDate.event_dates_id = null;
    }
    const method = currentEventDate.event_dates_id ? 'PUT' : 'POST';
    const url = currentEventDate.event_dates_id ? `${backendURL}/api/event-dates/${currentEventDate.event_dates_id}` : `${backendURL}/api/event-dates`;

    if (!currentEventDate.date) {
      setErrorMessage("Please include a date");
      return;
    }
    if (!currentEventDate.start_time) {
      setErrorMessage("Please include a start time");
      return;
    }
    if (!currentEventDate.end_time) {
      setErrorMessage("Please include an end time");
      return;
    }
    if (currentEventDate.start_time > currentEventDate.end_time) {
      setErrorMessage("The start time must begin before the end time");
      return;
    }

    await fetch(url, {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(currentEventDate),
      credentials: 'include',
    });
  
      setCurrentEventDate({
        event_dates_id: '',
        event_id: '', 
        date: '', 
        start_time: '', 
        end_time: '',
        // mentor_id: ''
      })
      setShowNewVisitModal(false);
      setShowForm(false);
      fetchEventDates();
    };

    const deleteEventDate = async (eventDateId) => {

      await fetch(`${backendURL}/api/event-dates/${eventDateId}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      setShowNewVisitModal(false);
      setCurrentEventDate({
        event_dates_id: '',
        event_id: '', 
        date: '', 
        start_time: '', 
        end_time: '',
        // mentor_id: ''
      })
      fetchEventDates();    
    }
    

  const handleCancel = () => {
    setCurrentClassroom({/*setting back to initial state */})
    setShowForm(false);
    setIsEditingClassroom(false);
    setSelectedSchool(null);
    setSelectedArtMentor(null);
    setSelectedMusicMentor(null);
    setSelectedTeacher(null);
    setSelectedDramaMentor(null);
    setCurrentEventDate({});
    setShowNewVisitModal(false);
    setIsEditingVisit(false);
    setCurrentClassroom({
        size: null, 
        grade_id: '', 
        teacher_id: '', 
        drama_mentor_id: '', 
        art_mentor_id: '', 
        music_mentor_id: '',
        teacher_profile_id: null,
        service_type_id: '',
      });
    setDramaMentorSearch('');
    setArtMentorSearch('');
    setMusicMentorSearch('');
  }
  
  const handleYearChange = (event) => {
    setSelectedSchoolYear(event.target.value);
  };
    

return (
    <div>
      <Button onClick={() => setShowForm(!showForm)}>
        {showForm ? 'Show Classrooms List' : 'Add New Classroom'}
      </Button>
      <div>
          <Dropdown>
            <span className='fw-bold'>Select School Year</span>
          <select value={selectedSchoolYear} onChange={handleYearChange}>
              <option value="" disabled>Select School Year</option>
              {schoolYearList.map((year) => (
                <option key={year.id} value={year.id}>
                  {year.school_year}
                </option>
              ))}
            </select>
          </Dropdown>
      </div>
      <Form.Group controlId="search">{ showForm ?
         <div></div> : <Form.Control
         type="text"
         placeholder="Search by school name..."
         onChange={(e) => setSearchQuery(e.target.value)}
     />}
      </Form.Group>
  
      {showForm ? (
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="school_id">
            <Form.Label className="bold-label">School</Form.Label>
            <Select 
                required
                isSearchable={true}
                options={schoolOptions}
                value={selectedSchool}
                onChange={handleSchoolChange}
                inputId="school_id"
            />
        </Form.Group>
      <Form.Group controlId="size">
        <Form.Label>Size</Form.Label>
        <Form.Control
        type="number"
        name="size"
        value={currentClassroom.size}
        onChange={handleIntegerChange}
        required
        />

      </Form.Group>

      <Form.Group controlId="grade_id">
                <Form.Label className="bold-label">Grade</Form.Label>
                <Form.Control 
                    required
                    as="select"
                    name="grade_id"
                    onChange={handleGradeChange}
                    value={currentClassroom.grade_id}
                >
                    <option value="">Select Grade</option>
                    {Array.isArray(grades) && grades.map(grade => (
                        <option key={grade.grade_id} value={grade.grade_id}>{grade.grade}</option>
                    ))}
                </Form.Control>
            </Form.Group>

          {/* Teacher Search and Display */}
          <Form.Group controlId="teacher_search">
            <Form.Label>Teacher</Form.Label>
            <Select 
            required
              options={teacherProfiles.map(teacher => ({
                value: teacher.profile_id,
                label: `${teacher.first_name} ${teacher.last_name}`
              }))}
              value={selectedTeacher ? {
                value: selectedTeacher.profile_id, 
                label: `${selectedTeacher.first_name} ${selectedTeacher.last_name}`
              } : null}
              onChange={(selectedOption) => {
                const teacher = teacherProfiles.find(t => t.profile_id === selectedOption.value);
                handleTeacherSelect(teacher);
              }}
            />
          </Form.Group>

          {/* Drama Mentor Search */}
          <Form.Group controlId="drama_mentor_search">
            <Form.Label>Drama Mentor</Form.Label>
            <Select 
              options={mentors.filter(mentor => mentor.drama_mentor === true).map(mentor => ({
                value: mentor.user_id,
                label: `${mentor.first_name} ${mentor.last_name}`
              }))}
              value={selectedDramaMentor ? {
                value: selectedDramaMentor.user_id, 
                label: `${selectedDramaMentor.first_name} ${selectedDramaMentor.last_name}`
              } : null}
              onChange={(selectedOption) => {
                const mentor = mentors.find(m => m.user_id === selectedOption.value);
                handleMentorSelect(mentor, 'drama_mentor');
              }}
            />
          </Form.Group>

          {/* Art Mentor Search and Display */}
          <Form.Group controlId="art_mentor_search">
            <Form.Label>Art Mentor</Form.Label>
            <Select 
              options={mentors.filter(mentor => mentor.art_mentor === true).map(mentor => ({
                value: mentor.user_id,
                label: `${mentor.first_name} ${mentor.last_name}`
              }))}
              value={selectedArtMentor ? {
                value: selectedArtMentor.user_id, 
                label: `${selectedArtMentor.first_name} ${selectedArtMentor.last_name}`
              } : null}
              onChange={(selectedOption) => {
                const mentor = mentors.find(m => m.user_id === selectedOption.value);
                handleMentorSelect(mentor, 'art_mentor');
              }}
            />
          </Form.Group>


          {/* Music Mentor Search and Display */}
          <Form.Group controlId="music_mentor_search">
            <Form.Label>Music Mentor</Form.Label>
            <Select 
              options={mentors.filter(mentor => mentor.music_mentor === true).map(mentor => ({
                value: mentor.user_id,
                label: `${mentor.first_name} ${mentor.last_name}`
              }))}
              value={selectedMusicMentor ? {
                value: selectedMusicMentor.user_id, 
                label: `${selectedMusicMentor.first_name} ${selectedMusicMentor.last_name}`
              } : null}
              onChange={(selectedOption) => {
                const mentor = mentors.find(m => m.user_id === selectedOption.value);
                handleMentorSelect(mentor, 'music_mentor');
              }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Service Type</Form.Label>
            <Form.Select
            name='service_type_id'
            onChange={handleFormChange}
            value={currentClassroom.service_type_id}
            >
              <option value={''}>Select Service Type</option>
              {classroomServiceTypes.map( service => (
                <option key={service.id} value={service.id}>
                  {service.service_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Button type="submit" className="m-1">{ isEditingClassroom ? "Update" : "Submit"}</Button>
          <Button variant="secondary" onClick={handleCancel} className="ml-2">Cancel</Button>
        </Form>
      ) : (
        <div className="scrollable-table">
        <Table striped bordered hover>
          <thead>
            <tr>
              {/* <th>Classroom Info</th> */}
              <th>Classroom Status</th>
              <th>Classroom Info</th>
              <th>Workshops</th>
              <th>Mentors</th>
              <th>Classroom Visits</th>
              <th>Performance</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredClassrooms.map((classroom) => (
              <tr key={classroom.classroom_id}>
                  {/* <td className='classroom-content-box'>
                    
                  </td> */}
                  <td>
                    <ClassroomStatus
                     classroomId={classroom.classroom_id}
                     classroomStatusId={classroom.status_id}
                     classroomStatuses={classroomStatuses}
                     isExpanded={expandedClassroomId === classroom.classroom_id}
                     toggleExpansion={() => toggleClassroomExpansion(classroom.classroom_id)}
                     />
                  </td>
                  <td>
                    {/* Teacher Info Container */}
                    <div className='teacher-info-container'>
                      {/* Teacher Contact Information */}
                        {getTeacherDetails(classroom.teacher_profile_id)}
                      {/* Teacher Status */}
                      <div className='teacher-status-container'>
                        <span className='teacher-status-info'>{'Status: '}</span>{getTeacherStatus(classroom.teacher_profile_id)}
                      </div>
                    </div>

                    {/* Classroom Info Container */}
                    <div className='classroom-info-container'>
                      <p>
                        <span className='classroom-info-list'>{'School: '}</span>{getSchoolName(classroom.school_id)}
                        <span className='classroom-info-list'>{`Phone: `}</span>{getSchoolPhone(classroom.school_id)}
                      </p>
                      <a href={`https://www.google.com/maps/?q=${getSchoolAddress(classroom.school_id)}`}
                       target='_blank'><i className="bi bi-geo-alt-fill map-pin">
                        </i>
                      </a>
                      <p>
                        <span className='classroom-info-list'>{'Class Size: '}</span>{classroom.size}
                        <span className='classroom-info-list'>{'Grade: '}</span>{getGradeType(classroom.grade_id)}
                        <span className='classroom-info-list'>{'Service: '}</span>{getClassroomServiceType(classroom.service_type_id)}
                      </p>
                    </div>
                  </td>

                  <td>
                    {/* {getWorkshopEvent(classroom.teacher_profile_id)} */}
                    Workshop placeholder
                  </td>
                  <td className='mentor-content-box'>
                      <span className='mentor-list'>{'Drama: '}</span>{getMentorName(classroom.drama_mentor_id)}<br></br>
                      <span className='mentor-list'>{'Art: '}</span>{getMentorName(classroom.art_mentor_id)}<br></br>
                      <span className='mentor-list'>{'Music: '}</span>{getMentorName(classroom.music_mentor_id)}
                  </td>
                  <td >
                    {getEventProgram(classroom.classroom_id)}
                  </td>
                  <td>
                  {getPerformanceEvent(classroom.classroom_id)}
                  </td>
                  <td>
                    <Button className="m-1" onClick={() => editClassroom(classroom)}><i className="bi bi-pencil"></i></Button>
                    <Button variant="danger" className="m-1" onClick={() => {
                      setClassroomToDelete(classroom.classroom_id);
                      setShowModal(true);
                    }}><i className="bi bi-trash3"></i></Button>                    
                  </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </div>
      )}
  
      {/* Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this classroom?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteClassroom}>
          Delete
          </Button>
        </Modal.Footer>
      </Modal>
        <Modal show={showNewVisitModal} onHide={handleCancel}>
          <Modal.Header>New Visit</Modal.Header>
          <Modal.Body>
                  <Form>
                    <Form.Group>
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                      type={'date'}
                      id='date'
                      name='date'
                      value={currentEventDate.date}
                      onChange={handleEventDateFormChange}
                      required>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Start Time</Form.Label>
                      <Form.Control
                      type={'time'}
                      id='start_time'
                      name='start_time'
                      value={currentEventDate.start_time}
                      onChange={handleEventDateFormChange}
                      required></Form.Control>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>End Time</Form.Label>
                      <Form.Control
                      id='end_time'
                      value={currentEventDate.end_time}
                      type={'time'}
                      name='end_time'
                      onChange={handleEventDateFormChange}
                      required></Form.Control>
                    </Form.Group>
                    
                  </Form>
          </Modal.Body>
          <Modal.Footer>
          {currentEventDate.event_dates_id ? 
              <Button variant='danger' onClick={() => deleteEventDate(currentEventDate.event_dates_id)}>Delete</Button>
               : 
               ''}
            <Button onClick={handleVisitSubmit}>{isEditingVisit ? "Update" : "Create"}</Button>
            <Button variant='secondary' onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
        </Modal>
        <ErrorModalMessage errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage('')} />
    </div>
  );
}  
export default ClassroomComponent;
