import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Clipboard from 'clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class EmailModal extends React.Component {
  componentDidMount() {
    this.clipboard = new Clipboard('#copy-button', {
      text: () => this.props.emails.join(', '),
    });

    this.clipboard.on('success', () => {
      toast.success('Emails copied to clipboard!');
    });

    this.clipboard.on('error', (e) => {
      toast.error('Failed to copy emails to clipboard');
      console.error('Error copying emails to clipboard:', e);
    });
  }

  componentWillUnmount() {
    this.clipboard.destroy();
  }

  render() {
    const { show, handleClose, emails } = this.props;

    return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Email List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul>
              {emails.map((email, index) => (
                <li key={index}>{email}</li>
              ))}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" id="copy-button">
              Copy Emails
            </Button>
          </Modal.Footer>
        </Modal>
        <ToastContainer />
      </>
    );
  }
}

export default EmailModal;
