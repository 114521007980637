import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../store/AuthContext';
import { backendURL } from '../IPaddress';
import '../CSS/CRUDfiles.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const ResourceComponent = () => {
  const [resources, setResources] = useState([]);
  const [currentResource, setCurrentResource] = useState({ name: '' });
  const [showForm, setShowForm] = useState(false);
  const [resourceToDelete, setResourceToDelete] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { authState } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState) {
      navigate('/login');
    }
  }, [authState, navigate]);

  useEffect(() => {
    fetchResources();
  }, []);

  const fetchResources = async () => {
    const response = await fetch(`${backendURL}/api/resources`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });
    const data = await response.json();
    setResources(data);
  };

  const handleFormChange = (e) => {
    setCurrentResource({ ...currentResource, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentResource.name || currentResource.name.trim() === '') {
      alert("Name can't be empty"); // Simple validation feedback
      return;
    }

    if (currentResource.resource_id) {
      await updateResource(currentResource.resource_id, currentResource);
    } else {
      await createResource(currentResource);
    }
    setCurrentResource({ name: '' });
    setShowForm(false);
    fetchResources();
  };

  const createResource = async (resourceData) => {
    await fetch(`${backendURL}/api/resources`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(resourceData),
      credentials: 'include',
    });
  };

  const updateResource = async (resourceId, resourceData) => {
    await fetch(`${backendURL}/api/resources/${resourceId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(resourceData),
      credentials: 'include',
    });
  };

  const deleteResource = async () => {
    if (resourceToDelete) {
      await fetch(`${backendURL}/api/resources/${resourceToDelete}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      setResourceToDelete(null);
      setShowModal(false);
      fetchResources();
    }
  };

  const editResource = (resource) => {
    setCurrentResource(resource);
    setShowForm(true);
  };

  return (
    <div>
      <Button onClick={() => setShowForm(!showForm)} className="m-1">
        {showForm ? 'Show Resources List' : 'Add New Resource'}
      </Button>

      {showForm ? (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="name">
            <Form.Label>Resource Name</Form.Label>
            <Form.Control 
              type="text"
              placeholder="Enter resource name"
              name="name"
              onChange={handleFormChange}
              value={currentResource.name}
            />
          </Form.Group>
          <Button type="submit" className="m-1">Submit</Button>
          <Button variant="secondary" onClick={() => setShowForm(false)} className="ml-2">Cancel</Button>
        </Form>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Resource Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {resources.map((resource) => (
              <tr key={resource.resource_id}>
                <td>{resource.name}</td>
                <td>
                  <Button onClick={() => editResource(resource)} className="m-1"><i className="bi bi-pencil"></i></Button>
                  <Button variant="danger" onClick={() => {
                    setResourceToDelete(resource.resource_id);
                    setShowModal(true);
                  }} className="m-1"><i className="bi bi-trash3"></i></Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this resource?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteResource}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ResourceComponent;
