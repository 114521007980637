import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Button, Form, Table, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../store/AuthContext';
import { backendURL } from '../IPaddress';
import '../CSS/CRUDfiles.css';
import Collapse from 'react-bootstrap/Collapse';
import Select from 'react-select';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useSchoolYear } from '../store/SchoolYearCalculator';
import ErrorModalMessage from '../store/ErrorMessage';

const AwardsEventUHSMTA = () => {
  const [eventsPrograms, setEventsPrograms] = useState([]);
  const [currentEventProgram, setCurrentEventProgram] = useState({
    event_template_id: null,
    name: null,
    description: '',
    location_id: null,
    coordinator_id: null,
    event_type_id: 8,
  });
  const [currentEventDate, setCurrentEventDate] = useState({
    event_dates_id: null,
    event_id: null,
    date: null,
    start_time: null,
    end_time: null,
    total_seats: null,
    sold_out: false,
  });
  const [currentStudentProfile, setCurrentStudentProfile] = useState({
    first_name: '',
    last_name: '',
    teacher_status: false,
    requires_email: false,
    email: null,
    school_id: null,
    school_year_id: null,
  });
  const [currentDatesSchool, setCurrentDatesSchool] = useState({
    event_date_id: '',
    school_id: '',
    school_year_id: '',
    number_of_students: 0,
  });
  const [eventDates, setEventDates] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showModalDateEvent, setShowModalDateEvent] = useState(false);
  const { authState } = useAuth();
  const [openStates, setOpenStates] = useState({});
  const navigate = useNavigate();
  const [eventTypes, setEventTypes] = useState([]);
  const [locations, setLocations] = useState([]);
  const [userNames, setUsersNames] = useState([]);
  const [newDateModal, setNewDateModal] = useState(false);
  const [showEventDateModal, setShowEventDateModal] = useState(false);
  const [showDeletionError, setShowDeletionError] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [profileEvents, setProfileEvents] = useState([]);
  const [obcWorkshopTypes, setOBCWorkshopTypes] = useState([]);
  const [studentProfiles, setStudentProfiles] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [displayedSelectionTeachers, setDisplayedSelectionTeachers] = useState([]);
  const [schoolYearList, setSchoolYearList] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState();
  const { currentYear, schoolYearListParent } = useSchoolYear();
  const [schools, setSchools] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showStudentForm, setShowStudentForm] = useState();
  const [showStudentListTable, setShowStudentListTable] = useState(false);
  const [datesSchoolList, setDatesSchoolList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!authState) {
      navigate('/login');
    }
  }, [authState, navigate]);

  useEffect(() => {
    if (currentYear && schoolYearListParent.length) {
      setSelectedSchoolYear(currentYear);
      setSchoolYearList(schoolYearListParent);
    }
  }, [currentYear, schoolYearListParent]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedSchoolYear) {
          await fetchEventsPrograms();
          await fetchEventDates();
          await fetchProfileEvents();
          await fetchProfiles();
          await fetchDatesSchool();
        }
        await fetchEventTypes();
        await fetchLocations();
        await fetchUserNames();
        await fetchOBCWorkshopTypes();
        await fetchSchools();
        await fetchDistricts();
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedSchoolYear]);

  const fetchEventsPrograms = async () => {
    try {
      const response = await fetch(`${backendURL}/api/events-programs/year/${selectedSchoolYear}`, {
        method: 'GET',
      });
      const data = await response.json();
      const filteredEvents = data.filter(event => event.event_type_id === 8);
      setEventsPrograms(filteredEvents);
    } catch {
      ErrorModalMessage('unable to fetch the program events');
    }
  };

  const fetchProfiles = async () => {
    try {
      const response = await fetch(`${backendURL}/api/profiles/year/${selectedSchoolYear}`, {
        method: 'GET',
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch profiles');
      }
      const data = await response.json();
      const students = data.filter(profile => profile.teacher_status === false);
      setStudentProfiles(students);
    } catch {
      setErrorMessage('Failed to fetch the student profiles.');
    }
  };

  const fetchDatesSchool = async () => {
    try {
      const response = await fetch(`${backendURL}/api/dates-schools/year/${selectedSchoolYear}`, {
        method: 'GET',
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to retrieve date school links');
      }
      const data = await response.json();
      setDatesSchoolList(data);
    } catch {
      setErrorMessage('Failed to fetch dates and schools link list');
    }
  };

  const fetchProfileEvents = async () => {
    try {
      const response = await fetch(`${backendURL}/api/profile-events/year/${selectedSchoolYear}`, {
        method: 'GET',
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch profile events');
      }
      const data = await response.json();
      setProfileEvents(data);
    } catch {
      console.error('Failed to fetch profile events');
    }
  };

  const fetchOBCWorkshopTypes = async () => {
    try {
      const response = await fetch(`${backendURL}/api/workshop_types`, {
        method: 'GET',
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch the obc workshop types');
      }
      const data = await response.json();
      setOBCWorkshopTypes(data);
    } catch {
      console.error('Failed to fetch the obc workshop types');
    }
  };

  const fetchEventTypes = async () => {
    try {
      const response = await fetch(`${backendURL}/api/event-types`, {
        method: 'GET',
        credentials: 'include'
      });
      const data = await response.json();
      setEventTypes(data);
    } catch (error) {
      console.error('Error fetching the event types:', error.message);
    }
  };

  const fetchUserNames = async () => {
    try {
      const response = await fetch(`${backendURL}/api/app-users`, {
        method: 'GET',
        credentials: 'include'
      });
      const data = await response.json();
      if (!response.ok) throw new Error('Failed to fetch user data');
      setUsersNames(data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const fetchLocations = async () => {
    try {
      const response = await fetch(`${backendURL}/api/locations`, {
        method: 'GET',
        credentials: 'include'
      });
      const data = await response.json();
      setLocations(data);
    } catch (error) {
      console.error('Error fetching locations:', error.message);
    }
  };

  const fetchEventDates = async () => {
    try {
      const response = await fetch(`${backendURL}/api/event-dates/year/${selectedSchoolYear}`, {
        method: 'GET',
        credentials: 'include'
      });
      const data = await response.json();
      const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
      setEventDates(sortedData);
    } catch (error) {
      console.error('Error fetching event dates:', error.message);
    }
  };

  const fetchSchools = async () => {
    try {
      const response = await fetch(`${backendURL}/api/schools`);
      if (!response.ok) throw new Error('Unable to retrieve school information');
      const data = await response.json();
      setSchools(data);
    } catch (error) {
      console.error('Error fetching schools:', error.message);
    }
  };

  const fetchDistricts = async () => {
    try {
      const response = await fetch(`${backendURL}/api/districts`);
      if (!response.ok) throw new Error('Unable to retrieve districts');
      const data = await response.json();
      setDistricts(data);
    } catch (error) {
      console.error('Error fetching districts:', error.message);
    }
  };

  const schoolOptions = useMemo(() =>
    schools.map(school => {
      const district = districts.find(d => d.district_id === school.district_id);
      const districtName = district ? district.name : 'Unknown District';
      return {
        label: `${school.name} (${districtName})`,
        value: school.school_id
      };
    }), [schools, districts]);

  const handleEventFormChange = (field, value, type = 'text') => {
    setCurrentEventProgram(currentEventProgram => ({
      ...currentEventProgram,
      [field]: type === 'number' ? parseInt(value, 10) || 0 : value,
      school_year_id: parseInt(selectedSchoolYear, 10),
    }));
  };

  const handleTeacherChange = selectedOptions => {
    setSelectedStudents(selectedOptions);
    const teacherIds = selectedOptions.map(option => parseInt(option.value, 10)).filter(id => !isNaN(id));
    setSelectedStudent(teacherIds);
  };

  const handleEventDateFormChange = event => {
    const { name, type, checked, value } = event.target;
    setCurrentEventDate(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : type === 'number' ? parseInt(value, 10) : value,
      school_year_id: parseInt(selectedSchoolYear, 10),
    }));
  };

  const handleStudentProfile = e => {
    setCurrentStudentProfile(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
      school_year_id: selectedSchoolYear
    }));
  };

  const handleDatesSchoolFormChange = e => {
    setCurrentDatesSchool(prevState => ({
      ...prevState,
      [e.target.name]: parseInt(e.target.value, 10),
      school_year_id: parseInt(selectedSchoolYear, 10),
    }));
  };

  const handleEventSubmit = async e => {
    e.preventDefault();
    const method = currentEventProgram.event_id ? 'PUT' : 'POST';
    const url = currentEventProgram.event_id ? `${backendURL}/api/events-programs/${currentEventProgram.event_id}` : `${backendURL}/api/events-programs`;
    await fetch(url, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(currentEventProgram),
      credentials: 'include',
    });
    setCurrentEventProgram({
      event_template_id: null,
      name: null,
      description: '',
      location_id: null,
      coordinator_id: null,
      event_type_id: 8,
    });
    setShowForm(false);
    fetchEventsPrograms();
  };

  const handleNewEventDate = eventProgramId => {
    setCurrentEventDate({
      event_dates_id: null,
      event_id: eventProgramId,
      date: null,
      start_time: null,
      end_time: null,
      total_seats: null,
      sold_out: false,
    });
    setCurrentDatesSchool({
      event_date_id: '',
      school_id: '',
      school_year_id: selectedSchoolYear,
      number_of_students: 0,
    });
    setShowEventDateModal(true);
  };

  const handleEventDateSubmit = async e => {
    e.preventDefault();
    if (!currentEventDate.date || !currentEventDate.start_time || !currentEventDate.end_time) {
      return setErrorMessage('Please include a date, start time and end time for the award');
    }
    try {
      const method = currentEventDate.event_dates_id ? 'PUT' : 'POST';
      const url = currentEventDate.event_dates_id ? `${backendURL}/api/event-dates/${currentEventDate.event_dates_id}` : `${backendURL}/api/event-dates`;
      const dateResponse = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(currentEventDate),
        credentials: 'include',
      });
      if (!dateResponse.ok) throw new Error('Failed to create event date for the related program');
      const newDateResponse = await dateResponse.json();

      if (newDateResponse && newDateResponse.event_dates_id) {
        const eventDateID = newDateResponse.event_dates_id;
        const dateProfilePayload = {
          event_id: newDateResponse.event_dates_id,
          profile_id: selectedStudent,
          school_year_id: parseInt(selectedSchoolYear, 10),
        };
        const eventDateProfileUrl = `${backendURL}/api/profile-events${currentEventDate.event_dates_id ? `/${currentEventDate.event_dates_id}` : ''}`;
        const eventDateProfile = await fetch(eventDateProfileUrl, {
          method,
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(dateProfilePayload),
          credentials: 'include'
        });
        if (!eventDateProfile.ok) throw new Error('Failed to create event date profile for the event and program');

        if (eventDateID > 0 && (currentDatesSchool.school_id !== '' && currentDatesSchool.school_id !== undefined)) {
          const dateSchoolsPayload = {
            ...currentDatesSchool,
            event_date_id: eventDateID,
            number_of_students: parseInt(currentDatesSchool.number_of_students, 10),
            school_year_id: selectedSchoolYear
          };
          const datesSchoolUrl = `${backendURL}/api/dates-schools${currentEventDate.event_dates_id ? `/${currentEventDate.event_dates_id}` : ''}`;
          const datesSchoolResponse = await fetch(datesSchoolUrl, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dateSchoolsPayload),
            credentials: 'include'
          });
          if (!datesSchoolResponse.ok) throw new Error('Failed to create date, school, and student number link');
        }
        setCurrentEventDate({
          event_dates_id: null,
          event_id: null,
          date: null,
          start_time: null,
          end_time: null,
          total_seats: null,
          sold_out: false,
        });
        setShowEventDateModal(false);
        setShowModalDateEvent(false);
        setNewDateModal(false);
        fetchEventDates();
        fetchProfileEvents();
        fetchDatesSchool();
        setCurrentDatesSchool({
          event_date_id: '',
          school_id: '',
          school_year_id: '',
          number_of_students: 0,
        });
        setSelectedStudents([]);
        setSelectedStudent([]);
        setSelectedSchool([]);
      }
    } catch (error) {
      setErrorMessage('Failed to create or update event date');
    }
  };

  const handleStudentProfileSubmit = async e => {
    e.preventDefault();
    const method = currentStudentProfile.profile_id ? 'PUT' : 'POST';
    const url = currentStudentProfile.profile_id ? `${backendURL}/api/profiles/${currentStudentProfile.profile_id}` : `${backendURL}/api/profiles`;
    const studentProfileResponse = await fetch(url, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(currentStudentProfile),
      credentials: 'include',
    });
    if (!studentProfileResponse.ok) {
      setErrorMessage('Failed to create student profile');
      throw new Error('Failed to create student profile');
    }
    const newStudentProfile = await studentProfileResponse.json();
    setShowStudentForm(false);
    setCurrentStudentProfile({
      first_name: '',
      last_name: '',
      teacher_status: false,
      requires_email: false,
      email: null,
      school_id: null,
    });
    setSelectedSchool(null);
    fetchProfiles();
  };

  const editEventProgram = eventProgram => {
    setCurrentEventProgram(eventProgram);
    setShowForm(true);
  };

  const editStudentProfile = profile => {
    setCurrentStudentProfile(profile);
    const schoolObj = schoolOptions.find(school => school.value === profile.school_id);
    if (schoolObj) {
      setSelectedSchool(schoolObj);
    }
    setShowStudentForm(true);
  };

  const studentOptions = useMemo(() =>
    studentProfiles.map(profile => ({
      label: `${profile.first_name} ${profile.last_name}`,
      value: profile.profile_id,
    })), [studentProfiles]);

  
  


  const getNumberOfStudents = eventProgramId => {
    try {
      const eventDateObjs = eventDates.filter(eventDate => eventDate.event_id === eventProgramId);

      if (eventDateObjs.length === 0) {
        return 0;
      }

      const eventDatesIds = eventDateObjs.map(eventDate => eventDate.event_dates_id);
      const dateSchoolObjs = datesSchoolList.filter(dateSchool => eventDatesIds.includes(dateSchool.event_date_id));
      const profileEventObjs = profileEvents.filter(profileEvent => eventDatesIds.includes(profileEvent.event_id));

      const totalNumberOfStudentsFromEvents = dateSchoolObjs.reduce((total, dateSchool) => total + (dateSchool.number_of_students || 0), 0);
      const totalProfileEvents = profileEventObjs.length;


      return totalNumberOfStudentsFromEvents + totalProfileEvents;
    } catch (error) {
      console.error('Error in getNumberOfStudents:', error);
      return 0;
    }
  };

  const getStudentsForDate = eventDateId => {
    try {
      const profileEventObjs = profileEvents.filter(profileEvent => profileEvent.event_id === eventDateId);
      if (profileEventObjs.length === 0) return <p>No Student Profiles assigned to dates.</p>;

      const profileIds = profileEventObjs.flatMap(profileEvent => profileEvent.profile_id);
      const listOfStudents = studentProfiles.filter(student => profileIds.includes(student.profile_id));

      return (
        <ul>
          {listOfStudents.map(student =>
            <li key={student.profile_id}>{student.first_name} {student.last_name}</li>
          )}
        </ul>
      );
    } catch (error) {
      console.error('Error in getStudentsForDate:', error);
      setErrorMessage('The profileEvent list failed to fetch');
      return <p>Error fetching student profiles.</p>;
    }
  };

  const getSchoolNameProfile = schoolId => {
    const schoolObj = schoolOptions.find(school => school.value === schoolId);
    return schoolObj ? <div>{schoolObj.label}</div> : null;
  };

  const getSchoolName = eventDateObj => {
    const eventId = eventDateObj.event_dates_id;
    const profileEventsList = profileEvents.filter(profileEvent => profileEvent.event_id === eventId) || [];
    const profileIds = profileEventsList.flatMap(profileEvent => profileEvent.profile_id);
    const attendingProfiles = studentProfiles.filter(profile => profileIds.includes(profile.profile_id));
    const profilesSchoolIds = attendingProfiles.map(profile => profile.school_id);
    const datesSchools = datesSchoolList.filter(datesSchool => datesSchool.event_date_id === eventId) || [];
    const datesSchoolIds = datesSchools.flatMap(datesSchool => datesSchool.school_id || []);
    const schoolIds = [...new Set([...profilesSchoolIds, ...datesSchoolIds])];
    const schoolList = schoolOptions.filter(school => schoolIds.includes(school.value));
    return (
      <>
        {schoolList.map(school => (
          <li key={school.value}>{school.label}</li>
        ))}
      </>
    );
  };

  const editEventDate = eventDate => {
    const eventDateTeachersObj = profileEvents.filter(eventProfile => eventProfile.event_id === eventDate.event_dates_id);
    const datesSchoolsObjs = datesSchoolList.filter(dateSchool => dateSchool.event_date_id === eventDate.event_dates_id);
    if (datesSchoolsObjs.length === 0) {
      setCurrentDatesSchool();
    } else {
      const schoolIds = datesSchoolsObjs.flatMap(schoolObj => schoolObj.school_id);
      const schoolObj = schoolOptions.find(school => school.value === schoolIds[0]);
      if (schoolObj) {
        setSelectedSchool(schoolObj);
      }
    }
    const listOfStudents = eventDateTeachersObj.flatMap(eventProfile =>
      studentOptions.filter(teacherOption => eventProfile.profile_id.includes(teacherOption.value))
    );
    if (listOfStudents.length) {
      const teacherIds = listOfStudents.map(option => parseInt(option.value, 10)).filter(id => !isNaN(id));
      setSelectedStudents(listOfStudents);
      setSelectedStudent(teacherIds);
    } else {
      setSelectedStudents([]);
      setSelectedStudent([]);
    }
    setCurrentEventDate({ ...eventDate });
    if (datesSchoolsObjs.length > 0) {
      setCurrentDatesSchool(datesSchoolsObjs[0]);
    } else {
      setCurrentDatesSchool([]);
    }
    setShowEventDateModal(true);
  };

  const handleCancel = () => {
    setCurrentEventDate({
      event_dates_id: null,
      event_id: null,
      date: null,
      start_time: null,
      end_time: null,
      total_seats: null,
      sold_out: false,
    });
    setCurrentDatesSchool({
      event_date_id: '',
      school_id: '',
      school_year_id: '',
      number_of_students: 0,
    });
    setCurrentEventProgram({
      event_template_id: null,
      name: null,
      description: '',
      location_id: null,
      coordinator_id: null,
      event_type_id: 8,
    });
    setCurrentStudentProfile({
      first_name: '',
      last_name: '',
      teacher_status: false,
      requires_email: false,
      email: null,
      school_id: null,
    });
    setShowForm(false);
    setShowEventDateModal(false);
    setShowModalDateEvent(false);
    setNewDateModal(false);
    setSelectedStudents([]);
    setSelectedStudent([]);
    setShowStudentForm(false);
    setSelectedSchool(null);
  };

  const handleShowEventForm = () => {
    setShowStudentListTable(false);
    setCurrentEventProgram({
      event_template_id: null,
      name: null,
      description: '',
      location_id: null,
      coordinator_id: null,
      event_type_id: 8,
    });
    setShowForm(!showForm);
  };

  const handleShowStudentList = () => {
    setShowStudentListTable(!showStudentListTable);
    setShowForm(false);
  };

  const deleteEventProgram = async event_id => {
    try {
      const response = await fetch(`${backendURL}/api/events-programs/${event_id}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (response.ok) {
        fetchEventsPrograms();
      } else {
        throw new Error('Unable to delete the associated event');
      }
    } catch (error) {
      setShowDeletionError(true);
      console.error('Please delete associated event dates before deleting an event.');
    }
    setSelectedStudent([]);
  };

  const deleteStudentProfile = async profileId => {
    try {
      const response = await fetch(`${backendURL}/api/profiles/${profileId}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to delete the student profile');
      fetchProfiles();
    } catch {
      setErrorMessage('Failed to delete the student profile.');
    }
  };

  const handleDeleteEventDate = () => {
    setConfirmationMessage('Are you sure you want to delete this event date?');
    setShowConfirmationModal(true);
  };

  const handleSchoolChange = selectedOption => {
    setSelectedSchool(selectedOption);
    const schoolId = selectedOption ? parseInt(selectedOption.value, 10) : null;
    if (selectedOption && isNaN(schoolId)) {
      setErrorMessage(`Warning: could not parse school_id value "${selectedOption.value}" as an integer`);
    }
    setCurrentStudentProfile(prevProfile => ({
      ...prevProfile,
      school_id: schoolId
    }));
    const selectedSchoolObj = schools.find(school => school.school_id === schoolId);
    if (selectedSchoolObj) {
      const correspondingDistrict = districts.find(district => district.district_id === selectedSchoolObj.district_id);
      if (correspondingDistrict) {
        setSelectedDistrict(correspondingDistrict);
        setCurrentStudentProfile(prevProfile => ({
          ...prevProfile,
          district: correspondingDistrict.district_id
        }));
      }
    }
  };

  const handleDatesSchoolChange = selectedOption => {
    setSelectedSchool(selectedOption);
    const schoolId = selectedOption ? parseInt(selectedOption.value, 10) : null;
    if (selectedOption && isNaN(schoolId)) {
      setErrorMessage(`Warning: could not parse school_id value "${selectedOption.value}" as an integer`);
    }
    setCurrentDatesSchool(prevState => ({
      ...prevState,
      school_id: schoolId
    }));
    const selectedSchoolObj = schools.find(school => school.school_id === schoolId);
    if (selectedSchoolObj) {
      const correspondingDistrict = districts.find(district => district.district_id === selectedSchoolObj.district_id);
      if (correspondingDistrict) {
        setSelectedDistrict(correspondingDistrict);
      }
    }
  };

  const deleteEventDate = async eventDateId => {
    try {
      const dateSchoolResponse = await fetch(`${backendURL}/api/dates-schools/${eventDateId}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      const dateProfileResponse = await fetch(`${backendURL}/api/profile-events/${eventDateId}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      if (dateSchoolResponse.ok && dateProfileResponse.ok) {
        const response = await fetch(`${backendURL}/api/event-dates/${eventDateId}`, {
          method: 'DELETE',
          credentials: 'include'
        });
        if (response.ok) {
          setShowEventDateModal(false);
          setShowConfirmationModal(false);
          setSelectedSchool([]);
          setSelectedStudents([]);
          setSelectedStudent([]);
          fetchProfileEvents();
          fetchEventDates();
        } else {
          throw new Error('Failed to delete the event date');
        }
      }
    } catch (error) {
      console.error('Deletion failed:', error);
    }
  };

  const getEventType = workshopTypeID => {
    const workshopType = obcWorkshopTypes.find(eventType => eventType.type_id === workshopTypeID);
    return workshopType ? workshopType.name : 'Workshop type failed to load';
  };

  const getLocation = locationId => {
    const locationObj = locations.find(location => location.location_id === locationId);
    return locationObj ? locationObj.name : 'Event type failed to load';
  };

  const getCoordinatorId = userId => {
    const nameObject = userNames.find(user => user.user_id === userId);
    return nameObject ? `${nameObject.first_name} ${nameObject.last_name}` : 'Coordinator not assigned';
  };

  const formatTime = timeString => {
    if (timeString === null) return 'No time set';
    const [hours, minutes] = timeString.split(':');
    const hoursInt = parseInt(hours, 10);
    const suffix = hoursInt >= 12 ? 'PM' : 'AM';
    const formattedHours = ((hoursInt + 11) % 12 + 1);
    return `${formattedHours}:${minutes} ${suffix}`;
  };

  const getEventDates = eventDateId => {
    const eventDateObj = eventDates.filter(eventDate => eventDate.event_id === eventDateId);
    if (eventDateObj.length > 0) {
      return (
        <>
          {eventDateObj.map(eventDate => (
            <tr key={eventDate.event_dates_id}>
              <td></td>
              <td colSpan={3}>{`Schools: `}<br></br>{getSchoolName(eventDate)}</td>
              <td>{getStudentsForDate(eventDate.event_dates_id)}</td>
              <td>{getNumberOfStudents(eventDate.event_dates_id)}</td>
              <td>
                {`Date: `}{eventDate.date ? eventDate.date : 'No date set'} <br />
                {'From: '}{formatTime(eventDate.start_time)}{' to '}{formatTime(eventDate.end_time)}
              </td>
              <td>
                <Button onClick={() => editEventDate(eventDate)}>Edit Date</Button>
              </td>
            </tr>
          ))}
        </>
      );
    }
    return null;
  };

  const toggleOpenState = eventId => {
    setOpenStates(prevOpenStates => ({
      ...prevOpenStates,
      [eventId]: !prevOpenStates[eventId],
    }));
  };

  const handleYearChange = event => {
    setSelectedSchoolYear(event.target.value);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div>
        <Button onClick={() => handleShowEventForm()} className="m-1">
          {showForm ? 'Show Awards Event List' : 'Add New Awards Event'}
        </Button>
        <Button onClick={() => setShowStudentForm(true)} className="m-1">
          Create Student Profile
        </Button>
        <Button onClick={() => handleShowStudentList()} className="m-1">
          {showStudentListTable ? 'Show Awards Event list' : 'Show Students List'}
        </Button>
        <div>
          <Dropdown>
            <span className='fw-bold'>Select School Year</span>
            <select value={selectedSchoolYear} onChange={handleYearChange}>
              <option value="" disabled>Select School Year</option>
              {schoolYearList.map(year => (
                <option key={year.id} value={year.id}>
                  {year.school_year}
                </option>
              ))}
            </select>
          </Dropdown>
        </div>
        {showForm && !showStudentListTable ? (
          <Form onSubmit={handleEventSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name of Event</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter event name"
                name="name"
                value={currentEventProgram.name}
                onChange={e => handleEventFormChange('name', e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter event description"
                name="description"
                value={currentEventProgram.description}
                onChange={e => handleEventFormChange('description', e.target.value)}
              />
            </Form.Group>
            <Button type="submit" className="m-1">Submit</Button>
            <Button variant="secondary" className="m-1" onClick={handleCancel}>Cancel</Button>
          </Form>
        ) : (
          !showStudentListTable && (
            <Table striped bordered>
              <thead>
                <tr>
                  <th>Event Name</th>
                  <th colSpan={2}>Description</th>
                  <th></th>
                  <th>Student Profiles</th>
                  <th>Total Students</th>
                  <th>Dates Listed:</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {eventsPrograms.map(eventProgram => (
                  <>
                    <tr key={eventProgram.event_id}>
                      <td>{eventProgram.name}</td>
                      <td colSpan={2}>{eventProgram.description}</td>
                      <td></td>
                      <td>{getStudentsForDate(eventProgram.event_id)}</td>
                      <td>{getNumberOfStudents(eventProgram.event_id)}</td>
                      <td>
                        <Button
                          onClick={() => toggleOpenState(eventProgram.event_id)}
                          aria-controls="example-collapse-text"
                          aria-expanded={openStates[eventProgram.event_id] || false}
                        >
                          {'Expand to show dates'}
                        </Button>
                        <Collapse in={openStates[eventProgram.event_id] || false}>
                          <div>
                            <Button style={{ margin: '5px' }} onClick={() => handleNewEventDate(eventProgram.event_id)}>
                              {'New Date'}
                            </Button>
                          </div>
                        </Collapse>
                      </td>
                      <td>
                        <Button onClick={() => editEventProgram(eventProgram)} className="m-1">
                          <i className="bi bi-pencil"></i>
                        </Button>
                        <Button variant="danger" onClick={() => deleteEventProgram(eventProgram.event_id)} className="m-1">
                          <i className="bi bi-trash3"></i>
                        </Button>
                      </td>
                    </tr>
                    {openStates[eventProgram.event_id] && getEventDates(eventProgram.event_id)}
                  </>
                ))}
              </tbody>
            </Table>
          )
        )}
        {showStudentListTable && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>School</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {studentProfiles.map(profile => (
                <tr key={profile.profile_id}>
                  <td>{profile.first_name} {profile.last_name}</td>
                  <td>{getSchoolNameProfile(profile.school_id)}</td>
                  <td>
                    <Button onClick={() => editStudentProfile(profile)} className="m-1">
                      <i className="bi bi-pencil"></i>
                    </Button>
                    <Button variant="danger" onClick={() => deleteStudentProfile(profile.profile_id)} className="m-1">
                      <i className="bi bi-trash3"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Modal show={showEventDateModal} onHide={handleCancel}>
          <Modal.Header>
            {currentEventDate.event_dates_id ? 'Edit Event Date' : 'Create New Event Date'}
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Select Student</Form.Label>
                <Select
                  isMulti
                  isSearchable
                  options={studentOptions}
                  value={selectedStudents}
                  onChange={handleTeacherChange}
                  classNamePrefix="select"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Select Date</Form.Label>
                <Form.Control
                  type="date"
                  id="date"
                  name="date"
                  value={currentEventDate.date}
                  onChange={handleEventDateFormChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Start Time</Form.Label>
                <Form.Control
                  type="time"
                  id="start_time"
                  name="start_time"
                  value={currentEventDate.start_time}
                  onChange={handleEventDateFormChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>End Time</Form.Label>
                <Form.Control
                  id="end_time"
                  value={currentEventDate.end_time}
                  type="time"
                  name="end_time"
                  onChange={handleEventDateFormChange}
                />
              </Form.Group>
              <Form.Group controlId="formSchool">
                <Form.Label>School</Form.Label>
                <Select
                  isSearchable
                  options={schoolOptions}
                  value={selectedSchool}
                  onChange={handleDatesSchoolChange}
                  inputId="school_id"
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Number of Students</Form.Label>
                <Form.Control
                  id="number_of_students"
                  name="number_of_students"
                  type="number"
                  value={currentDatesSchool.number_of_students}
                  onChange={handleDatesSchoolFormChange}
                />
              </Form.Group>
            </Form>
            <Modal.Footer className="modal-footer-buttons">
              {currentEventDate.event_dates_id && (
                <Button variant="danger" onClick={handleDeleteEventDate}>
                  Delete
                </Button>
              )}
              <Button onClick={handleEventDateSubmit}>
                {currentEventDate.event_dates_id ? 'Update' : 'Create'}
              </Button>
              <Button variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal.Body>
        </Modal>

        <Modal show={showDeletionError} onHide={() => setShowDeletionError(false)}>
          <Modal.Header>Unable to process Request</Modal.Header>
          <Modal.Body>Please be sure to delete all associated dates with the event before deleting the event</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDeletionError(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
          <Modal.Header closeButton>Please Confirm!</Modal.Header>
          <Modal.Body>{confirmationMessage}</Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => deleteEventDate(currentEventDate.event_dates_id)}>
              Delete
            </Button>
            <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showStudentForm} onHide={handleCancel}>
          <Modal.Header closeButton>
            <Modal.Title>Create Student Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  name="first_name"
                  value={currentStudentProfile.first_name}
                  onChange={handleStudentProfile}
                />
              </Form.Group>
              <Form.Group controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  name="last_name"
                  value={currentStudentProfile.last_name}
                  onChange={handleStudentProfile}
                />
              </Form.Group>
              <Form.Group controlId="formSchool">
                <Form.Label>School</Form.Label>
                <Select
                  isSearchable
                  options={schoolOptions}
                  value={selectedSchool}
                  onChange={handleSchoolChange}
                  inputId="school_id"
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancel}>
              Close
            </Button>
            <Button variant="primary" onClick={handleStudentProfileSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <ErrorModalMessage errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage('')} />
      </div>
    </div>
  );
};

export default AwardsEventUHSMTA;
