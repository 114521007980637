import React, { createContext, useState, useContext, useEffect } from 'react';
import { backendURL } from '../IPaddress';
import { useAuth } from './AuthContext';

const UserDataContext = createContext();

export const useUserData = () => useContext(UserDataContext);

export const UserDataProvider = ({ children, onLogout }) => {
    const {userId} = useAuth();
    const [userData, setUserData] = useState(null);
    const [userType, setUserType] = useState('');

    useEffect(() => {
        fetchUserInfo();

    }, [userId]);

    const resetUserData = () => {
        setUserData(null);
    };

    useEffect(() => {
        if (typeof onLogout === 'function') {
            onLogout(resetUserData);
        }
    }, [onLogout]);

    const fetchUserInfo = async () => {
        

        if (userId) {try {
            const response = await fetch(`${backendURL}/api/app-users/id/${userId}`, {
                method: 'GET',
                credentials: 'include'  // To ensure cookies are included
            });
            if (response.ok) {
                const userInfo = await response.json();
                // console.log("userInfo: ", userInfo)
                setUserData(userInfo);
                // fetchUserType();
                // setUserId(userInfo.user_id);
            }

        } catch (error) {
            console.error('Error fetching user info:', error);
            // Handle network errors
        }}
    };

    

    const fetchUserType = async () => {
        // I've found that this simply fetches the user type for the user and not
        // necessarily does anything useful if the user type can be pulled from the 
        // user data. 
            if ( userData ) {
                try {
                const response = await fetch(`${backendURL}/api/usertypes/${userData.type_id}`,{
                    method: 'GET',
                    credentials: "include",
                })
                if (response.ok){
                    const data = await response.json();
                    console.log("fetch user type ran!")
                    // console.log("fetching the user type and setting it: ", data)
                    setUserType(data);
                }
                } catch (error){
                    console.error("Error fetching user type id: ",error)
                }} else {
                    console.log("trying again...")
                }
    };

    return (
        <UserDataContext.Provider value={{ userData, setUserData, userType }}>
            {children}
        </UserDataContext.Provider>
    );
};

export default UserDataContext;
