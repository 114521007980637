import React, {useEffect, useState} from "react";
import { Container, Button, Fade, Form, Alert } from "react-bootstrap";
import { useAuth, useUserData } from './store/AuthContext';
import { useNavigate } from "react-router-dom";
import './CSS/MainPage.css';
import NavbarComponent from './Nav';
import { backendURL } from "./IPaddress";
import SchoolGenreComponent from "./crudFiles/schoolGenre";
import SchoolTypeComponent from "./crudFiles/schoolType";
import DistrictComponent from "./crudFiles/districts";
import SchoolComponent from "./crudFiles/schools";
import GradeComponent from "./crudFiles/grades";
import TeacherStatusComponent from "./crudFiles/teacherStatus";
import LocationComponent from "./crudFiles/locations";
import UserTypeComponent from "./crudFiles/appUserTypes";
import NewAccountForm from "./crudFiles/appUser";
import ClassroomComponent from "./crudFiles/classroom";
import EventType from "./crudFiles/eventTypes";
import EventTemplateComponent from "./crudFiles/eventTemplate";
import FormSubmissionComponent from "./crudFiles/formSubmissionCreate";
import RecordStatusComponent from "./crudFiles/record_status";
import TicketStatusComponent from "./crudFiles/ticketStatus";
import TicketsComponent from "./crudFiles/tickets";
import ActionComponent from "./crudFiles/actions";
import ResourceComponent from "./crudFiles/resource";
import PermissionComponent from "./crudFiles/permissions";
import EventsProgramComponent from "./crudFiles/eventPrograms";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import TeacherProfile from "./crudFiles/obcTeacherProfile";
import JudgeProfile from "./crudFiles/judgeProfile";
import UHSMTATeacherProfile from "./crudFiles/uhsmtaTeacherProfile";
import WorkshopTeachersOBC from "./crudFiles/workshopsTeachersOBC";
import AwardsEventUHSMTA from "./crudFiles/workshopsTeachersOBC";
import SelectSchoolYearComponent from "./crudFiles/yearSelect";
import WorkshopStudentsOBC from "./crudFiles/obcWorkshopStudents";



function Management() {
    const {authState } = useAuth();
    const navigate = useNavigate();
    const [file, setFile] = useState(null);
    const [upSchoolLoadStatus, setSchoolUploadStatus] = useState(null);
    const [upDistrictLoadStatus, setDistrictUploadStatus] = useState(null);
    const [ bulkLoad, setBulkLoad] = useState(false);
    // const [userType, setUserType] = useState('');
    const [showSchool, setShowSchool] = useState(false);
    const [showDistrict, setShowDistrict] = useState(false);
    const [showSchoolType, setShowSchoolType] = useState(false);
    const [showSchoolGenre, setShowSchoolGenre] = useState(false);
    const [showGrade, setShowGrade] = useState(false);
    const [showTeacherStatus, setShowTeacherStatus] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const [showUserType, setShowUserType] = useState(false);
    const [showNewAccountForm, setShowNewAccountForm] = useState(false);
    const [showClassroom, setShowClassroom] = useState(false);
    const [showEventType, setShowEventType] = useState(false);
    const [showEventTemplate, setShowEventTemplate] = useState(false);
    const [showFormSubmission, setShowFormSubmission] = useState(false);
    const [showRecordStatus, setShowRecordStatus] = useState(false);
    const [showTicketStatus, setShowTicketStatus] = useState(false);   
    const [showTickets, setShowTickets] = useState(false);   
    const [showActionComponent, setShowActionComponent] = useState(false);
    const [showResourceComponent, setShowResourceComponent] = useState(false);
    const [showPermissionComponent, setShowPermissionComponent] = useState(false);
    const [showEventPrograms, setShowEventPrograms] = useState(false);
    const [showOBCTeacherProfile, setShowOBCTeacherProfile] = useState(false);
    const [showJudgeProfile, setShowJudgeProfile] = useState(false);
    const [showUHSMTATeacherProfiles, setShowUHSMTATeacherProfiles ] = useState(false);
    const [showWorkshopOBC, setShowWorkshopOBC ] = useState(false);
    const [showWorkshopStudentsOBC, setShowWorkshopStudentsOBC ] = useState(false);
    const [showSelectSchoolYearComponent, setShowSelectSchoolYearComponent] = useState(false);


    const onSchoolUpload = async () => {
        const formData = new FormData();
        formData.append('file', file);
        
    // so make the file upload work, I had to remove the try & catch function while attempting the file upload.
    // The first attempt does what it can, and then removing the catch error seems to allow the omitted schools
    // to be entered. 
        try { 
            const response = await fetch(`${backendURL}/api/uploadschools`, {
                method: 'POST',
                body: formData,
                // headers: { 'Content-Type': 'multipart/form-data' } 
                // Note: Don't set Content-Type header when using FormData with Fetch
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok' + response.statusText);
            }
            
            setSchoolUploadStatus('Upload successful!');
        } catch (error) {
            console.error('Upload failed:', error);
            setSchoolUploadStatus('Upload failed. Please try again later.');
        }
    };
    const onDistrictUpload = async () => {
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            const response = await fetch(`${backendURL}/api/uploaddistricts`, {
                method: 'POST',
                body: formData,
                // headers: { 'Content-Type': 'multipart/form-data' } 
                // Note: Don't set Content-Type header when using FormData with Fetch
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok' + response.statusText);
            }
            
            setDistrictUploadStatus('Upload successful!');
        } catch (error) {
            console.error('Upload failed:', error);
            setDistrictUploadStatus('Upload failed. Please try again later.');
        }
    };

    // const checkingUserID = () => {
    //     if (!userData.type_id) {
    //         console.log("the type is undefined: ", userData)
    //     } else {
    //         console.log("The type is: ", userData.type_id)
    //     }
    // }
// this useEffect is to check for authentication. 
    useEffect(() => {
        if (!authState) {
            navigate('/login');
        }
    }, [authState, navigate]);

    // console.log("user info: ", userData)

    
    // checkingUserID();
    useEffect(() => {
        const fetchUserTypes = async () => {
            const url = `${backendURL}/api/usertypes`
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include'
            })
            if (response.ok) {
                const data = await response.json();
                // console.log("user types: ", data)
            } else {
                console.error('unable to fetch user types');
            }
        }
        const fetchPermissions = async () => {
            const url = `${backendURL}/api/permissions`
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include'
            })
            if (response.ok) {
                const data = await response.json();
                // console.log("permissions: ", data)
            } else {
                console.error('unable to fetch permissions');
            }
        }


        fetchPermissions();
        fetchUserTypes();
    }, [])


    // Assuming you have a function to check permissions
    const hasPermission = (userPermissions, requiredPermissions) => {
    // Implement your permission check logic here
    return userPermissions.includes(requiredPermissions);
  };
  
  // Button information and permissions (could be fetched or defined statically)
  const buttonConfigs = [
    { id: 'school', label: 'Schools', permission: 'view_schools', component: SchoolComponent },
    // Add more button configs here
  ];
  
//   const RenderButtons = ({ userPermissions }) => {
//     return (
//       <>
//         {buttonConfigs.map((config) => {
//           if (hasPermission(userPermissions, config.permission)) {
//             return (
//               <Button
//                 key={config.id}
//                 type="button"
//                 variant={toggleButtonStyle(show[config.id])}
//                 onClick={() => setShow[config.id](!show[config.id])}
//                 className="button-list"
//               >
//                 {toggleButtonText(show[config.id], config.label)}
//               </Button>
//             );
//           }
//           return null;
//         })}
//       </>
//     );
//   };
  
    


    const bulkLoadButtons = () => {
        
 // I will need to use the text name of the user_type to determine what to add to the "content" list for displaying the components.
    return(                
                <>
                {/* Toggle Button for Bulk Load */}
                <Button className="d-flex" onClick={toggleBulkLoad}>
                    {bulkLoad ? "Hide Bulk Load Options" : "Show Bulk Load Options (schools and districts)"}
                </Button>

                <div>
                {/*file uploads */}
                {bulkLoad && (
                <Container className="vh-100 d-flex align-items-center justify-content-center">
                    
                    {/* School File Upload Section */}
                    <Form>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Upload your Schools CSV file second</Form.Label>
                            <Form.Control 
                                type="file" 
                                onChange={onSchoolFileChange} 
                            />
                        </Form.Group>
                        <Button onClick={onSchoolUpload}>Upload Schools</Button>
                    </Form>
                    
                    {/* School Upload Status Message */}
                    {upSchoolLoadStatus && (
                        <Alert 
                            variant={upSchoolLoadStatus.includes('successful') ? 'success' : 'danger'} 
                            className="mt-3"
                        >
                            {upSchoolLoadStatus}
                        </Alert>
                    )}

                    {/* District File Upload Section */}
                    <Form>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Upload your districts CSV file first</Form.Label>
                            <Form.Control 
                                type="file" 
                                onChange={onDistrictFileChange} 
                            />
                        </Form.Group>
                        <Button onClick={onDistrictUpload}>Upload Districts</Button>
                    </Form>

                    {/* District Upload Status Message */}
                    {upDistrictLoadStatus && (
                        <Alert 
                            variant={upDistrictLoadStatus.includes('successful') ? 'success' : 'danger'} 
                            className="mt-3"
                        >
                            {upDistrictLoadStatus}
                        </Alert>
                    )}
                </Container>)}
                </div>
                </>);
    };


    const toggleBulkLoad = () => {
        setBulkLoad(prevBulkLoad => !prevBulkLoad);
    };

    const onSchoolFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    const onDistrictFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const toggleButtonStyle = (isShown) => isShown ? "danger" : "primary";
    const toggleButtonText = (isShown, text) => isShown ? `Hide ${text}` : `Show ${text}`;

    const componentConfigs = [
        { id: 'showSchool', label: 'School', Component: SchoolComponent },
        { id: 'showDistrict', label: 'District', Component: DistrictComponent },
        { id: 'showSchoolType', label: 'School Type', Component: SchoolTypeComponent },
        { id: 'showSchoolGenre', label: 'School Genre', Component: SchoolGenreComponent },
        { id: 'showGrade', label: 'Grade', Component: GradeComponent },
        { id: 'showTeacherStatus', label: 'Teacher Status', Component: TeacherStatusComponent },
        { id: 'showLocation', label: 'Location', Component: LocationComponent },
        { id: 'showUserType', label: 'User Type', Component: UserTypeComponent },
        { id: 'showNewAccountForm', label: 'New Account Form', Component: NewAccountForm },
        { id: 'showClassroom', label: 'Classroom', Component: ClassroomComponent },
        { id: 'showEventType', label: 'Event Type', Component: EventType },
        { id: 'showEventTemplate', label: 'Event Template', Component: EventTemplateComponent },
        { id: 'showFormSubmission', label: 'Form Submission', Component: FormSubmissionComponent },
        { id: 'showRecordStatus', label: 'Record Status', Component: RecordStatusComponent },
        { id: 'showTicketStatus', label: 'Ticket Status', Component: TicketStatusComponent },
        { id: 'showTickets', label: 'Tickets', Component: TicketsComponent },
        { id: 'showActionComponent', label: 'Action', Component: ActionComponent },
        { id: 'showResourceComponent', label: 'Resource', Component: ResourceComponent },
        { id: 'showPermissionComponent', label: 'Permission', Component: PermissionComponent },
        { id: 'showEventPrograms', label: 'Event Programs', Component: EventsProgramComponent },
        { id: 'showOBCTeacherProfile', label: 'OBC Teacher Profiles', Component: TeacherProfile },
        { id: 'showJudgeProfile', label: 'Judge Profiles', Component: JudgeProfile },
        { id: 'showUHSMTATeacherProfiles', label: 'UHSMTA Teachers', Component: UHSMTATeacherProfile },
        { id: 'showWorkshopOBC', label: 'OBC Workshops', Component: WorkshopTeachersOBC },
        { id: 'showSelectSchoolYearComponent', label: 'Year Select', Component: SelectSchoolYearComponent },
        // { id: 'showAwardsEventUHSMTA', label: 'AwardsEventUHSMTA', Component: AwardsEventUHSMTA },
        { id: 'showWorkshopStudentsOBC', label: 'OBC Workshop Students', Component: WorkshopStudentsOBC },

        

        // Add more configurations as needed
      ];
      

      const toggleShow = (setter) => {
        setter(prev => !prev);
      };


    return (
<div>
    <NavbarComponent/>
    {/* Below are the OBC CRUD operations */}
    
    { authState ? (
      <div>
        <div className="management-button-list">
        {/* Your existing DropdownButton and other UI elements */}


        
      </div>
        <div className="management-button-list">
            <DropdownButton id="dropdown-basic-button" title={"School Information"}>
                    <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showSchool)} onClick={() => setShowSchool(!showSchool)}>Schools</Dropdown.Item>
                    <Dropdown.Item href="#/action-2" variant={toggleButtonStyle(showSchoolType)} onClick={() => setShowSchoolType(!showSchoolType)}>School Types</Dropdown.Item>
                    <Dropdown.Item href="#/action-3" variant={toggleButtonStyle(showSchoolGenre)} onClick={() => setShowSchoolGenre(!showSchoolGenre)}>School Genres</Dropdown.Item>
                    <Dropdown.Item href="#/action-4" variant={toggleButtonStyle(showDistrict)} onClick={() => setShowDistrict(!showDistrict)}>Districts</Dropdown.Item>
                    <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showGrade)} onClick={() => setShowGrade(!showGrade)}>Grades</Dropdown.Item>
                    <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showSelectSchoolYearComponent)} onClick={() => setShowSelectSchoolYearComponent(!showSelectSchoolYearComponent)}>School Year</Dropdown.Item>

            </DropdownButton>

            <DropdownButton id="dropdown-basic-button" title="OBC">
                <Dropdown.Item href="#/action-2" variant={toggleButtonStyle(showClassroom)} onClick={() => setShowClassroom(!showClassroom)}>Classrooms</Dropdown.Item>
                <Dropdown.Item href="#/action-2" variant={toggleButtonStyle(showOBCTeacherProfile)} onClick={() => setShowOBCTeacherProfile(!showOBCTeacherProfile)}>OBC Teacher Profiles</Dropdown.Item>
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showTeacherStatus)} onClick={() => setShowTeacherStatus(!showTeacherStatus)}>Teacher Statuses</Dropdown.Item>
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showWorkshopOBC)} onClick={() => setShowWorkshopOBC(!showWorkshopOBC)}>OBC Workshop Teachers</Dropdown.Item>
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showWorkshopStudentsOBC)} onClick={() => setShowWorkshopStudentsOBC(!showWorkshopStudentsOBC)}>OBC Workshop Students</Dropdown.Item>
            </DropdownButton>

            <DropdownButton id="dropdown-basic-button" title="UHSMTA">
                <Dropdown.Item href="#/action-2" variant={toggleButtonStyle(showJudgeProfile)} onClick={() => setShowJudgeProfile(!showJudgeProfile)}>Judge Profiles</Dropdown.Item>    
                <Dropdown.Item href="#/action-2" variant={toggleButtonStyle(showUHSMTATeacherProfiles)} onClick={() => setShowUHSMTATeacherProfiles(!showUHSMTATeacherProfiles)}>UHSMTA Teachers</Dropdown.Item>
            </DropdownButton>

            <DropdownButton id="dropdown-basic-button" title="User Accounts">
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showUserType)} onClick={() => setShowUserType(!showUserType)}>User Types</Dropdown.Item>
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showNewAccountForm)} onClick={() => setShowNewAccountForm(!showNewAccountForm)}>User Accounts</Dropdown.Item>
            </DropdownButton>

            <DropdownButton id="dropdown-basic-button" title="Events">
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showEventType)} onClick={() => setShowEventType(!showEventType)}>Event Types</Dropdown.Item>
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showEventTemplate)} onClick={() => setShowEventTemplate(!showEventTemplate)}>Event Templates</Dropdown.Item>
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showEventPrograms)} onClick={() => setShowEventPrograms(!showEventPrograms)}>Events</Dropdown.Item>
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showLocation)} onClick={() => setShowLocation(!showLocation)}>Locations</Dropdown.Item>
            </DropdownButton>

            <DropdownButton id="dropdown-basic-button" title="Tickets">
                {/* <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showTickets)} onClick={() => setShowTickets(!showTickets)}>Tickets</Dropdown.Item> */}
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showTicketStatus)} onClick={() => setShowTicketStatus(!showTicketStatus)}>Ticket Statuses</Dropdown.Item>
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showRecordStatus)} onClick={() => setShowRecordStatus(!showRecordStatus)}>Form Statuses</Dropdown.Item>
                {/* <Dropdown.Item href="#/action-1" variant={toggleButtnStyle(showFormSubmission)} onClick={() => setShowFormSubmission(!showFormSubmission)}>Ticket Submissions</Dropdown.Item> */}
            </DropdownButton>

            <DropdownButton id="dropdown-basic-button" title="Administration">
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showPermissionComponent)} onClick={() => setShowPermissionComponent(!showPermissionComponent)}>Permissions</Dropdown.Item>
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showActionComponent)} onClick={() => setShowActionComponent(!showActionComponent)}>Actions</Dropdown.Item>
                <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showResourceComponent)} onClick={() => setShowResourceComponent(!showResourceComponent)}>Resources</Dropdown.Item>
            </DropdownButton>

        </div>
        {componentConfigs.map(({ id, label, Component }) => {
          const isVisible = eval(id); // Directly access the state based on id
          return isVisible ? (
            <Button key={id} variant="warning" onClick={() => toggleShow(eval(`set${id.charAt(0).toUpperCase() + id.slice(1)}`))}>
              Hide {label}
            </Button>
          ) : null;
        })}
        {/* Dynamically generated visibility toggle buttons */}

        {componentConfigs.map(({ id, Component }) => {
        const isVisible = eval(id);
        return isVisible ? <Component key={id} /> : null;
      })}

  </div>) : (<div><h1>Please refresh the page and login!</h1></div>)}

    {/*Checking Auth and loading the component*/}
        {/* {authState && (bulkLoadButtons())} */}
        

</div>

    );
}

export default Management;
