import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table, Dropdown, DropdownButton, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../store/AuthContext';
import { backendURL } from '../IPaddress';

const PermissionComponent = () => {
    const [permissions, setPermissions] = useState([]);
    const [resources, setResources] = useState([]);
    const [actions, setActions] = useState([]);
    const [userTypes, setUserTypes] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState('');
    const [currentPermission, setCurrentPermission] = useState({ can_access: false });
    const [showForm, setShowForm] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const { authState } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!authState) {
            navigate('/login');
        } else {
            fetchData();
        }
    }, [authState, navigate]);

    const fetchData = async () => {
        const fetchEndpoints = ['/api/resources', '/api/actions', '/api/usertypes', '/api/permissions'];
        const results = await Promise.all(
            fetchEndpoints.map(endpoint =>
                fetch(`${backendURL}${endpoint}`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                }).then(response => response.json())
            )
        );
        setResources(results[0]);
        setActions(results[1]);
        setUserTypes(results[2]);
        setPermissions(results[3]);
    };

    const handleEdit = (permission) => {
        setCurrentPermission(permission);
        setShowForm(true);
        setEditMode(true);
    };

    const handleDelete = (permissionId) => {
        setShowModal(true);
        setCurrentPermission({ ...currentPermission, permission_id: permissionId });
    };

    const deletePermission = async () => {
        await fetch(`${backendURL}/api/permissions/${currentPermission.permission_id}`, {
            method: 'DELETE',
            credentials: 'include',
        });
        setShowModal(false);
        fetchData();  // Refresh data after deletion
    };

    const handleFormChange = (e) => {
        const { name, value, checked, type } = e.target;
        setCurrentPermission({
            ...currentPermission,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = `${backendURL}/api/permissions${editMode ? '/' + currentPermission.permission_id : ''}`;
        const method = editMode ? 'PUT' : 'POST';

        await fetch(url, {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(currentPermission),
            credentials: 'include',
        });
        setShowForm(false);
        fetchData();
    };

    const handleCreateNew = () => {
        setCurrentPermission({ type_id: '', resource_id: '', action_id: '', can_access: false });
        setShowForm(true);
        setEditMode(false);
    };

    return (
        <Container>
            <Row className="mb-3">
                <Col>
                    <Button onClick={handleCreateNew} className="m-1">Create New Permission</Button>
                </Col>
                <Col>
                    <DropdownButton id="dropdown-basic-button" title="Filter by User Type" className="mb-3">
                        <Dropdown.Item onClick={() => setSelectedUserType('')}>All User Types</Dropdown.Item>
                        {userTypes.map(type => (
                            <Dropdown.Item key={type.type_id} onClick={() => setSelectedUserType(type.type_id)}>
                                {type.type}
                            </Dropdown.Item>
                        ))}
                    </DropdownButton>
                </Col>
            </Row>

            {showForm ? (
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="type_id">
                        <Form.Label>User Type</Form.Label>
                        <Form.Control as="select" name="type_id" value={currentPermission.type_id} onChange={handleFormChange}>
                            <option value="">Select User Type</option>
                            {userTypes.map(userType => (
                                <option key={userType.type_id} value={userType.type_id}>{userType.type}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="resource_id">
                        <Form.Label>Resource</Form.Label>
                        <Form.Control as="select" name="resource_id" value={currentPermission.resource_id} onChange={handleFormChange}>
                            <option value="">Select Resource</option>
                            {resources.map(resource => (
                                <option key={resource.resource_id} value={resource.resource_id}>{resource.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="action_id">
                        <Form.Label>Action</Form.Label>
                        <Form.Control as="select" name="action_id" value={currentPermission.action_id} onChange={handleFormChange}>
                            <option value="">Select Action</option>
                            {actions.map(action => (
                                <option key={action.action_id} value={action.action_id}>{action.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="can_access">
                        <Form.Check type="checkbox" label="Can Access" name="can_access" checked={currentPermission.can_access} onChange={handleFormChange} />
                    </Form.Group>
                    <Button type="submit" className="m-1">{editMode ? 'Update' : 'Create'}</Button>
                    <Button variant="secondary" onClick={() => setShowForm(false)} className="m-1">Cancel</Button>
                </Form>
            ) : (
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>User Type</th>
                            <th>Resource</th>
                            <th>Action</th>
                            <th>Can Access</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {permissions.filter(p => !selectedUserType || p.type_id === parseInt(selectedUserType)).map((permission, index) => (
                            <tr key={`permission-list-${index}`}>
                                <td>{userTypes.find(u => u.type_id === permission.type_id)?.type || 'N/A'}</td>
                                <td>{resources.find(r => r.resource_id === permission.resource_id)?.name || 'N/A'}</td>
                                <td>{actions.find(a => a.action_id === permission.action_id)?.name || 'N/A'}</td>
                                <td>{permission.can_access ? 'Yes' : 'No'}</td>
                                <td>
                                    <Button onClick={() => handleEdit(permission)} className="btn-icon m-1">
                                        <i className="bi bi-pencil"></i>
                                    </Button>
                                    <Button variant="danger" onClick={() => handleDelete(permission.permission_id)} className="btn-icon m-1">
                                        <i className="bi bi-trash"></i>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this permission?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    <Button variant="danger" onClick={deletePermission}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default PermissionComponent;
