import React, { createContext, useContext, useEffect, useState } from "react";
import { backendURL } from "../IPaddress";

const SchoolYearContext = createContext();

export const useSchoolYear = () => useContext(SchoolYearContext);

export const SchoolYearInfo = ({ children }) => {
    const [currentYear, setCurrentYear] = useState();
    const [schoolYearListParent, setSchoolYearListParent] = useState([]);

    useEffect(() => {
        fetchSchoolYears();
    }, []);

    useEffect(() => {
        getCurrentSchoolYear();
    }, [schoolYearListParent]);

    const fetchSchoolYears = async () => {
        try {
            const response = await fetch(`${backendURL}/api/school-year-list`, {
                method: 'GET',
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error('Error fetching the school year list');
            }
            const data = await response.json();
    
            // Sort the data in descending order by the newest school year first
            const sortedData = data.sort((a, b) => {
                const yearA = parseInt(a.school_year.split('-')[0]);
                const yearB = parseInt(b.school_year.split('-')[0]);
                return yearB - yearA;
            });
    
            setSchoolYearListParent(sortedData);
        } catch (error) {
            console.error(error.message);
        }
    };
    

    const getCurrentSchoolYear = () => {
        try {
            if (schoolYearListParent.length > 0) {
                // Find the active school year
                const activeYear = schoolYearListParent.find(year => year.active);

                // If an active year is found, set it as the current year
                if (activeYear && Number.isFinite(activeYear.id)) {
                    setCurrentYear(activeYear.id);
                } else {
                    // Fallback to setting the most recent year if no active year is found
                    const sortedYears = schoolYearListParent.sort((a, b) => {
                        return parseInt(b.school_year.split('-')[1]) - parseInt(a.school_year.split('-')[1]);
                    });
                    const mostRecentYear = sortedYears[0];
                    if (mostRecentYear && Number.isFinite(mostRecentYear.id)) {
                        setCurrentYear(mostRecentYear.id);
                    }
                }
            }
        } catch (error) {
            console.error('Unable to set the current school year:', error);
        }
    };

    return (
        <SchoolYearContext.Provider value={{ currentYear, schoolYearListParent }}>
            {children}
        </SchoolYearContext.Provider>
    );
};

export default SchoolYearContext;
