import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function ErrorModalMessage({ errorMessage, clearErrorMessage }) {
  return (
    <Modal show={!!errorMessage} onHide={clearErrorMessage}>
      <Modal.Header>
        <Modal.Title>{`Oh no! ${errorMessage}`}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={clearErrorMessage} style={{wordWrap:'normal'}}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ErrorModalMessage;
