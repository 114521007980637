import React, { useState, useEffect, useSyncExternalStore } from 'react';
import { Modal, Button, Form, Table, Dropdown } from 'react-bootstrap';
import { json, useNavigate } from 'react-router-dom';
import { useAuth } from '../store/AuthContext';
import { backendURL } from '../IPaddress';
import '../CSS/CRUDfiles.css';
import Collapse from 'react-bootstrap/Collapse';
import Select from 'react-select';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useSchoolYear } from '../store/SchoolYearCalculator';
import ErrorModalMessage from '../store/ErrorMessage';

const WorkshopTeachersOBC = () => {
    const [eventsPrograms, setEventsPrograms] = useState([]);
    const [currentEventProgram, setCurrentEventProgram] = useState({
      event_template_id: null,
      name: null,
      description: '',
      location_id: null,
      coordinator_id: null,
      event_type_id: 3,
      obc_workshop_type_id: ''
    });
    const [currentEventDate, setCurrentEventDate ] = useState({
      event_dates_id: null,
      event_id: null, 
      date: null,
      start_time: null, 
      end_time: null,
      total_seats: null,
      sold_out: false,
    })
    const [ eventDates, setEventDates ] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [ showModalDateEvent, setShowModalDateEvent] = useState(false);
    const { authState } = useAuth();
    const [openStates, setOpenStates] = useState({});
    const navigate = useNavigate();
    const [ eventTypes, setEventTypes] = useState([]);
    const [ locations, setLocations] = useState([]);
    const [ userNames, setUsersNames] = useState([]);
    const [newDateModal, setNewDateModal] = useState(false);
    const [showEventDateModal, setShowEventDateModal] = useState(false);
    const [showDeletionError, setShowDeletionError] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [profileEvents, setProfileEvents] = useState([]);
    const [obcWorkshopTypes, setOBCWorkshopTypes ] = useState([]);
    const [teacherProfiles, setTeacherProfiles ] = useState([]);
    const [selectedTeachers, setSelectedTeachers ] = useState([]);
    const [selectedTeacher, setSelectedTeacher ] = useState([]);
    const [displayedSelectionTeachers, setDisplayedSelectionTeachers] = useState([]);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [selectedSchoolYear, setSelectedSchoolYear] = useState();
    const {currentYear, schoolYearListParent} = useSchoolYear();
  
  
    useEffect(() => {
      if (!authState) {
        navigate('/login');
      }
    }, [authState, navigate]);

    useEffect(() => {
      if (currentYear && schoolYearListParent.length) {
        setSelectedSchoolYear(currentYear);
        setSchoolYearList(schoolYearListParent);
      }
    }, [currentYear, schoolYearListParent]);

    useEffect(() => {
      if (selectedSchoolYear) {
        fetchEventsPrograms();
        fetchEventDates();
        fetchProfileEvents();
      }
    }, [selectedSchoolYear]);

  
    useEffect(() => {
      fetchEventTypes();
      fetchLocations();
      fetchUserNames();
      fetchOBCWorkshopTypes();
      fetchProfiles();
    }, []);
  
    const fetchEventsPrograms = async () => {
      try {const response = await fetch(`${backendURL}/api/events-programs/year/${selectedSchoolYear}`, {
        method: 'GET',
      //   credentials: 'include',
      });
      const data = await response.json();
      //filtering out classroom events
      const filteredEvents = data.filter(event => event.event_type_id === 3)
      // console.log("filtered data: ", filteredEvents)
      setEventsPrograms(filteredEvents);}
      catch {
        ErrorModalMessage('unable to fetch the program events')
      }
    };

    const fetchProfiles = async()=> {
      const response = await fetch (`${backendURL}/api/profiles`, {
        method: 'GET',
        credentials: 'include'
      });
      if (!response) {
        console.error('failed to fetch profiles');
      };
      const data = await response.json();
      setTeacherProfiles(data);
    }

    const fetchProfileEvents = async ()=> {
      const response = await fetch(`${backendURL}/api/profile-events/year/${selectedSchoolYear}`, {
        method:'GET',
        credentials: 'include'
      })
      if (!response){
        console.error('failed to fetch profile events');
      }
      const data = await response.json();
      setProfileEvents(data);
    };

    const fetchOBCWorkshopTypes = async ()=> {
      const response = await fetch(`${backendURL}/api/workshop_types`,{
        method:'GET',
        credentials: 'include'
      })
      if (!response) {
        console.error('failed to fetch the obc workshop types');
      }
      const data = await response.json();
      setOBCWorkshopTypes(data);
    };
  
    const fetchEventTypes = async () => {
      const response = await fetch(`${backendURL}/api/event-types`, {
        method: 'GET',
        credentials: 'include'
      });
      try{
        const data = await response.json();
        setEventTypes(data);
      } catch (error) {
        console.error("Error fetching the event types: ", error.message)
      }
    };

    const fetchUserNames = async () => {
      try {
          const response = await fetch (`${backendURL}/api/app-users`,{
              method: 'GET',
              credentials: 'include'
            });
          const data = await response.json();
          if(!response.ok) throw new Error ('Failed to fetch user data')
          // console.log("Users Data: ",data)
          setUsersNames(data);
      } catch (err) {
          console.error(err.message);
      }
    };

    const fetchLocations = async () => {
      const response = await fetch(`${backendURL}/api/locations`, {
        method: 'GET',
        credentials: 'include'
      });
      try{
        const data = await response.json();
        setLocations(data);
      } catch (error) {
        console.error("Error fetching locations: ", error.message)
      }
    };
  
    const fetchEventDates = async () => {
      try {const response = await fetch(`${backendURL}/api/event-dates/year/${selectedSchoolYear}`, {
          method: 'GET',
          credentials: 'include'
      });
      const data = await response.json();
      const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
      setEventDates(sortedData);
      // console.log("event dates: ", data);
  
      }
      catch (error) {
          console.error("Error fetching event dates: ", error.message);
      }
    };
  
    const handleEventFormChange = (field, value, type = 'text') => {

      if(field === 'obc_workshop_type_id' || field === 'coordinator_id' || field === 'number_of_students') {
          setCurrentEventProgram(currentEventProgram => ({
              ...currentEventProgram, 
              [field]:parseInt(value, 10) || 0,
              school_year_id: parseInt(selectedSchoolYear, 10),
        }));
      }  else {    
        setCurrentEventProgram({
           ...currentEventProgram,
           [field]: value,
          school_year_id: parseInt(selectedSchoolYear, 10), });
      }
    };

    const handleTeacherChange = selectedOptions => {
      setSelectedTeachers(selectedOptions);
      const teacherIds = selectedOptions.map(option => parseInt(option.value, 10)).filter(id => !isNaN(id));

      selectedOptions.forEach(option => {
        const id = parseInt(option.value, 10);
        if (isNaN(id)) {
            console.warn(`Warning: could not parse school_id value "${option.value}" as an integer`);
        }
    });

    setSelectedTeacher(teacherIds)
  };
  

    const handleEventDateFormChange = (event) => {
      const { name, type, checked, value } = event.target;
  
      if (type === "checkbox") {
          // For checkboxes, use the `checked` property
          setCurrentEventDate(prevState => ({
              ...prevState,
              [name]: checked,
              school_year_id: parseInt(selectedSchoolYear, 10),
          }));
      } else if (type === "number") {
          // For number inputs, convert value to integer
          setCurrentEventDate(prevState => ({
              ...prevState,
              [name]: parseInt(value, 10),  // base 10 for decimal numbers
              school_year_id: parseInt(selectedSchoolYear, 10),
          }));
      } else {
          // For all other types, use the value directly
          setCurrentEventDate(prevState => ({
              ...prevState,
              [name]: value,
              school_year_id: parseInt(selectedSchoolYear, 10),
          }));
      }
  };
  
  
  
    const handleEventSubmit = async (e) => {
      e.preventDefault();
      const method = currentEventProgram.event_id ? 'PUT' : 'POST';
      const url = currentEventProgram.event_id ? `${backendURL}/api/events-programs/${currentEventProgram.event_id}` : `${backendURL}/api/events-programs`;
      await fetch(url, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(currentEventProgram),
        credentials: 'include',
      });
  
      setCurrentEventProgram({
        event_template_id: null,
        name: null,
        description: '',
        location_id: null,
        coordinator_id: null,
        event_type_id: 3,
        obc_workshop_type_id: ''
      });
      setShowForm(false);
      fetchEventsPrograms();
    };
  
      const handleNewEventDate = async (eventProgramId) => {
      setCurrentEventDate({
        event_dates_id: null,
        event_id: eventProgramId, 
        date: null, 
        start_time: null, 
        end_time: null,
        total_seats: null,
        sold_out: false,
      });
      setShowEventDateModal(true); // Use the same modal for creating a new event date
    };
  
    const handleEventDateSubmit = async (e) => {
      e.preventDefault();
      const method = currentEventDate.event_dates_id ? 'PUT' : 'POST';
      const url = currentEventDate.event_dates_id ? `${backendURL}/api/event-dates/${currentEventDate.event_dates_id}` : `${backendURL}/api/event-dates`;
      const dateResponse = await fetch(url, {
          method: method,
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(currentEventDate),
          credentials: 'include',
      });
  
      if (!dateResponse.ok) {
          throw new Error('Failed to create event date for the related program.');
      }
      const newDateResponse = await dateResponse.json();
      // console.log('selected school year: ', selectedSchoolYear);
  
      const payload = {
          event_id: newDateResponse.event_dates_id,
          profile_id: selectedTeacher,
          school_year_id: parseInt(selectedSchoolYear, 10),
      };
  
      // console.log('payload: ', payload);
  
      const eventDateProfileUrl = `${backendURL}/api/profile-events${currentEventDate.event_dates_id ? `/${currentEventDate.event_dates_id}` : ''}`;
      const eventDateProfile = await fetch(eventDateProfileUrl, {
          method: method,
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
          credentials: 'include'
      });
  
      if (!eventDateProfile.ok) {
          throw new Error('Failed to create event date profile for the event and program.');
      }
  
      setCurrentEventDate({
          event_dates_id: null,
          event_id: null,
          date: null,
          start_time: null,
          end_time: null,
          total_seats: null,
          sold_out: false,
      });
      setShowEventDateModal(false);
      setShowModalDateEvent(false);
      setNewDateModal(false);
      fetchEventDates();
      fetchProfileEvents();
      setSelectedTeachers([]);
      setSelectedTeacher([]);
  };
  
  
    const editEventProgram = (eventProgram) => {
      setCurrentEventProgram(eventProgram);
      setShowForm(true);
    };

    const teacherOptions = teacherProfiles.map(teacherProfile => {
      return{
        label: `${teacherProfile.first_name} ${teacherProfile.last_name}`,
        value: teacherProfile.profile_id
      }
    })

    const getTeachersOnDate = (eventDateId) => {
  
      // Retrieve relevant profiles for the event date
      const listProfileObjs = profileEvents.filter(profileEvent => profileEvent.event_id === eventDateId);
  
      // Map these profiles to options with labels from teacherProfiles
      const listOptions = listProfileObjs.map(profileEvent => {
          const teacher = teacherProfiles.find(profile => profile.profile_id === profileEvent.profile_id[0]);
          if (teacher){return {
              value: profileEvent.profile_id,
              label: teacher ? `${teacher.first_name} ${teacher.last_name}` : `Unknown Teacher`
          };}
      });
      console.log("teachers set for display: getTeachersOnDate", listOptions)
      setDisplayedSelectionTeachers(listOptions);
  }; // will need to take a look at this again. 

  const getTeachersForDate = (eventDateId) => {
    // Filter event dates to find those that match the given eventDateId
    const eventDateObjs = eventDates.filter(eventDate => eventDate.event_id === eventDateId);
    
    // Check if any event dates were found
    if (eventDateObjs.length > 0) {
      // Extract event_dates_id from each found event date object
      const eventDatesIds = eventDateObjs.map(eventDate => eventDate.event_dates_id);
      
      // Filter profileEvents to find those where event_id matches any of the extracted event_dates_ids
      const profileEventObjs = profileEvents.filter(profileEvent => 
        eventDatesIds.includes(profileEvent.event_id)
      );
      
      if (profileEventObjs.length > 0) {
        // Flatten the profile_ids from the filtered profile events
        const profileIds = profileEventObjs.flatMap(profileEvent => profileEvent.profile_id);
        
        // Filter teacher profiles where profile_id is in any of the flattened profile_ids
        const listOfTeachers = teacherProfiles.filter(teacher => 
          profileIds.includes(teacher.profile_id)
        );
  
        // Returning JSX directly in a function like this assumes it's part of a React component
        return (
          <ul>
            {listOfTeachers.map(teacher => 
              <li key={teacher.profile_id}>{teacher.first_name} {teacher.last_name}</li>
            )}
          </ul>
        );
      } else {
        
        return <p>No Teachers assigned to dates.</p>;
      }
    } else {
      
      return <p>No dates found.</p>;
    }
  }
  
  
  
    const editEventDate = (eventDate) => {

      const eventDateTeachersObj = profileEvents.filter(
        eventProfile => eventProfile.event_id === eventDate.event_dates_id
      );

      const listOfTeachers = eventDateTeachersObj.flatMap(eventProfile =>
        teacherOptions.filter(teacherOption => eventProfile.profile_id.includes(teacherOption.value))
      );

      if (listOfTeachers.length) {

        const teacherIds = listOfTeachers.map(option => parseInt(option.value, 10)).filter(id => !isNaN(id));

        setSelectedTeachers(listOfTeachers);
        setSelectedTeacher(teacherIds);
      } else {
        setSelectedTeachers([]);
        setSelectedTeacher([]);
      }

      setCurrentEventDate({...eventDate});
      // getTeachersOnDate(eventDate.event_dates_id);
      setShowEventDateModal(true); // Use the same modal for editing an existing event date
    };
  
    const handleCancel = () => {
      setCurrentEventDate({
          event_dates_id: null,
          event_id: null, 
          date: null,
          start_time: null, 
          end_time: null,
          total_seats: null,
          sold_out: false,
        });
      setCurrentEventProgram({
          event_template_id: null,
          name: null,
          description: '',
          location_id: null,
          coordinator_id: null,
          event_type_id: 3,
          obc_workshop_type_id: ''
        });
      setShowForm(false);
      setShowEventDateModal(false);
      setShowModalDateEvent(false);
      setNewDateModal(false);
      setSelectedTeachers([]);
      setSelectedTeacher([]);
    }
  
    const handleShowForm = () => {
      setCurrentEventProgram({
        event_template_id: null,
        name: null,
        description: '',
        location_id: null,
        coordinator_id: null,
        event_type_id: 3,
        obc_workshop_type_id: ''
      });
      setShowForm(!showForm);
    }
  
    const deleteEventProgram = async (event_id) => {
      
       try {
        const response = await fetch(`${backendURL}/api/events-programs/${event_id}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (response.ok) {
        fetchEventsPrograms();
      } else {
        console.log("Unable to delete the associated event");
      }
    }
      catch (error) {
        setShowDeletionError(true);
        console.error("Please delete associated event dates before deleting an event.");
      }
      setSelectedTeacher([]);
    };
  
    const handleDeleteEventDate = () => {
      setConfirmationMessage('Are you sure you want to delete this event date?')
      setShowConfirmationModal(true);
    }
    
    const deleteEventDate = async (eventDateId) => {
      try {
        const response = await fetch(`${backendURL}/api/event-dates/${eventDateId}`, {
          method: 'DELETE',
          credentials: 'include'
        });
        if (response.ok) {
          // Close modal and refresh the event dates list
          setShowEventDateModal(false);
          setShowConfirmationModal(false);
          setSelectedTeachers([]);
          setSelectedTeacher([]);
          fetchProfileEvents();
          fetchEventDates();    
        } else {
          throw new Error('Failed to delete the event date');
        }
      } catch (error) {
        console.error("Deletion failed:", error);
        // Optionally, handle error showing in UI
      }
    }
  
    const getEventType = (workshopTypeID) => {
      const workshopType = obcWorkshopTypes.find(eventType => eventType.type_id === workshopTypeID);
      return workshopType ? (<>{workshopType.name}</>) : 'workshop type failed to load';
    }
  
  
    const getLocation = (locationId) => {
      const locationObj = locations.find(location => location.location_id === locationId);
      return locationObj ? (<>{locationObj.name}</>) : 'Event type failed to load';
    }
  
    const getCoordinatorId = (userId) => {
      const nameObject = userNames.find(user => user.user_id === userId);
      return nameObject ? (`${nameObject.first_name} ${nameObject.last_name}`) : 'Coordinator not assigned';
  }
  
    const formatTime = (timeString) => {
      // Assuming timeString is in 'HH:MM:SS' format
      if (timeString === null) {
        return `no time set`;
      }
  
      const [hours, minutes] = timeString.split(':');
      const hoursInt = parseInt(hours, 10);
      const suffix = hoursInt >= 12 ? 'PM' : 'AM';
      const formattedHours = ((hoursInt + 11) % 12 + 1); // Convert 24hr to 12hr format
      return `${formattedHours}:${minutes} ${suffix}`;
    };
  
    const getEventDates = (eventDateId) => {
      const eventDateObj =  eventDates.filter( eventDate => eventDate.event_id === eventDateId)
      if (eventDateObj.length > 0) {
          return (
              <ul >
                    {eventDateObj.map(eventDate => (
                        <li key={eventDate.event_dates_id} className='event-dates-list' onClick={() => editEventDate(eventDate)}>
                            {`Date: `}{eventDate.date ? eventDate.date : 'No date set'} <br/>
                            {'From: '}{formatTime(eventDate.start_time)}{' to '}{formatTime(eventDate.end_time)}
                        </li>
                    ))}
              </ul>
          )
      }
      return eventDateObj ? (<>{eventDateObj.date}</>) : 'Date failed to load';
    }

    const toggleOpenState = (eventId) => {
      setOpenStates((prevOpenStates) => ({
        ...prevOpenStates,
        [eventId]: !prevOpenStates[eventId],
      }));
    };

    const handleYearChange = (event) => {
      setSelectedSchoolYear(event.target.value);
    };
    
  
    return (
      <div>
        <Button onClick={() => handleShowForm()} className="m-1">
          {showForm ? 'Show OBC Workshops List' : 'Add New Workshop'}
        </Button>
        <div>
          <Dropdown>
          <span className='fw-bold'>Select School Year</span>
          <select value={selectedSchoolYear} onChange={handleYearChange}>
              <option value="" disabled>Select School Year</option>
              {schoolYearList.map((year) => (
                <option key={year.id} value={year.id}>
                  {year.school_year}
                </option>
              ))}
            </select>
          </Dropdown>
      </div>  
        {showForm ? (
          <Form onSubmit={handleEventSubmit}>
  
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter event name"
                name="name"
                value={currentEventProgram.name}
                onChange={e => handleEventFormChange('name', e.target.value)}
              />
            </Form.Group>
  
            <Form.Group controlId="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter event description"
                name="description"
                value={currentEventProgram.description}
                onChange={e => handleEventFormChange('description', e.target.value)}
              />
            </Form.Group>
  
            <Form.Group controlId="location_id">
              <Form.Label>Location ID</Form.Label>
              <Form.Control
                as={'select'}
                name="location_id"
                value={currentEventProgram.location_id}
                onChange={e => handleEventFormChange('location_id', e.target.value)}
              >
                <option value={null}>{"Select Location"}</option>
              {locations.map( location => (
              <option key={location.location_id} value={location.location_id}>{location.name}</option>
              ))}
              </Form.Control>
            </Form.Group>
  
            <Form.Group controlId="coordinator_id">
              <Form.Label>Coordinator ID</Form.Label>
              <Form.Control
                as={'select'}
                name="coordinator_id"
                value={currentEventProgram.coordinator_id}
                onChange={e => handleEventFormChange('coordinator_id', e.target.value)}
              >
                <option value={''}>Select Coordinator</option>
                {userNames.map(userName => (
                  <option value={userName.user_id} key={userName.user_id}>{userName.first_name} {userName.last_name}</option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId='number_of_students'>
              <Form.Label>Number of Students</Form.Label>
              <Form.Control
              type={'number'}
              name='number_of_students'
              value={currentEventProgram.number_of_students}
              onChange={e => handleEventFormChange('number_of_students', e.target.value)}>

              </Form.Control>
            </Form.Group>

  
            <Form.Group controlId="obc_workshop_type_id">
              <Form.Label>Workshop Type</Form.Label>
              <Form.Control
                as={'select'}
                name="obc_workshop_type_id"
                value={currentEventProgram.obc_workshop_type_id}
                onChange={e => handleEventFormChange('obc_workshop_type_id', e.target.value)}
                required
              >
                <option value={""}>Select Workshop Type</option>
                {obcWorkshopTypes.map(eventType => (
                  <option key={eventType.type_id} value={eventType.type_id}>{eventType.name}</option>
                ))}
              </Form.Control>
              </Form.Group>
  
            <Button type="submit" className="m-1">Submit</Button>
            <Button variant="secondary" className="m-1" onClick={handleCancel}>Cancel</Button>
          </Form>
        ) : (
          <Table striped bordered hover>
        <thead>
          <tr>
            <th>Event Name</th>
            <th>Description</th>
            {/* <th>Location ID</th> */}
            {/* <th>Seats Info</th> */}
            <th>Coordinator ID</th>
            <th>Teachers</th>
            <th>Number of Students</th>
            <th>Workshop Type</th>
            <th>Dates Listed:</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {eventsPrograms.map((eventProgram) => (
            <tr key={eventProgram.event_id}>
              <td>{eventProgram.name}</td>
              <td>{eventProgram.description}</td>
              {/* <td>{getLocation(eventProgram.location_id)}</td> */}
              {/* <td>{'seats info'}</td> */}
              <td>{getCoordinatorId(eventProgram.coordinator_id)}</td>
              <td>{getTeachersForDate(eventProgram.event_id)}</td>
              <td>{eventProgram.number_of_students}</td>
              <td>{getEventType(eventProgram.obc_workshop_type_id)}</td>
              <td>
                <Button
                  onClick={() => toggleOpenState(eventProgram.event_id)}
                  aria-controls="example-collapse-text"
                  aria-expanded={openStates[eventProgram.event_id] || false}
                >
                  {'Expand to show dates'}
                </Button>
                <Collapse in={openStates[eventProgram.event_id] || false}>
                  <div>
                    <Button style={{margin: 5 +'px'}} onClick={() => 
                      handleNewEventDate(eventProgram.event_id)}>{'New Date'}</Button>
                    {getEventDates(eventProgram.event_id)}
                  </div>
                </Collapse>
              </td>
              <td>
                <Button onClick={() => editEventProgram(eventProgram)} className="m-1"><i className="bi bi-pencil"></i></Button>
                <Button variant="danger" onClick={() => deleteEventProgram(eventProgram.event_id)} className="m-1"><i className="bi bi-trash3"></i></Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
        )}
          <Modal show={showEventDateModal} onHide={() => handleCancel()}>
            <Modal.Header>{currentEventDate.event_dates_id ? 'Edit Event Date' : 'Create New Event Date'}</Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Select Date</Form.Label>
                  <Form.Control
                  type={'date'}
                  id='date'
                  name='date'
                  value={currentEventDate.date}
                  onChange={handleEventDateFormChange}>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Start Time</Form.Label>
                  <Form.Control
                  type={'time'}
                  id='start_time'
                  name='start_time'
                  value={currentEventDate.start_time}
                  onChange={handleEventDateFormChange}>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>End Time</Form.Label>
                  <Form.Control
                  id='end_time'
                  value={currentEventDate.end_time}
                  type={'time'}
                  name='end_time'
                  onChange={handleEventDateFormChange}>
                  </Form.Control>
                </Form.Group>
              <Form.Group controlId="teacherId">
                  <Form.Label>Teacher(s)</Form.Label>
                  <Select
                      isMulti
                      isSearchable={true}
                      options={teacherProfiles.map(profile => 
                        ({ value: profile.profile_id, label: (`${profile.first_name} ${profile.last_name}`) }))}
                      value={selectedTeachers}
                      onChange={handleTeacherChange}
                      classNamePrefix="select"
                  />
              </Form.Group>
  
              </Form>
              <Modal.Footer className='modal-footer-buttons'>
                {currentEventDate.event_dates_id ? 
                <Button variant='danger' onClick={() => {handleDeleteEventDate()}}>Delete</Button>
                 : 
                 ''}
                <Button  onClick={handleEventDateSubmit}>{currentEventDate.event_dates_id ? 'Update' : 'Create'}</Button>
                <Button variant='secondary' onClick={() => handleCancel()}>Cancel</Button>
             </Modal.Footer>
            </Modal.Body>
          </Modal>
          <Modal show={showDeletionError} onHide={() => {setShowDeletionError(false)}}>
            <Modal.Header>Unable to process Request</Modal.Header>
            <Modal.Body>Please be sure to delete all associated dates with the event before deleting the event</Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setShowDeletionError(false)}>Close</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
            <Modal.Header closeButton>{'Please Confirm!'}</Modal.Header>
            <Modal.Body>{confirmationMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant='danger' onClick={() => deleteEventDate(currentEventDate.event_dates_id)}>{'Delete'}</Button>
              <Button variant='secondary' onClick={() => setShowConfirmationModal(false)}>{'Cancel'}</Button>
            </Modal.Footer>
          </Modal>
  
          
      </div>
    );
  };

export default WorkshopTeachersOBC;
