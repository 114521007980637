import { useState, useEffect } from "react";
import NavbarComponent from "./Nav";
import AdjudicationEvent from "./UHSMTAfiles/adjudicationEvent";
import CoachingEvent from "./UHSMTAfiles/coachingEvent";
// import AwardEvent from "./UHSMTAfiles/awardShowsREFERENCEONLY";
import AwardsEventUHSMTA from "./UHSMTAfiles/AwardsEvent";
import WorkshopEvent from "./UHSMTAfiles/workshopEvent";
import InternEvent from "./UHSMTAfiles/internEvent";
import WinnersEvent from "./UHSMTAfiles/winnersEvent";
import SpecialPerformanceEvent from "./UHSMTAfiles/specialAwardsEvent";
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import { Container, Button, Fade, Form, Alert } from "react-bootstrap";
import { useAuth } from "./store/AuthContext";
import { useNavigate } from "react-router-dom";





const UHSMTAMainPage = () => {
    const [showAdjudication, setShowAdjudication] = useState(false);
    const {authState} = useAuth();
    const navigate = useNavigate();
    const [showCoaching, setShowCoaching] = useState(false);
    const [showAwards, setShowAwards] = useState(false);
    const [ showWorkshops, setShowWorkshops] = useState(false);
    const [showInternships, setShowInternships] = useState(false);
    const [ showSpecialPerformanceEvent, setShowSpecialPerformanceEvent ] = useState(false);
    const [showWinners, setShowWinners] = useState(false);
    const [showAwardsEventUHSMTA, setShowAwardsEventUHSMTA] = useState(false);

    useEffect(() => {
        if (!authState) {
            navigate('/login');
        }
    }, [authState, navigate]);



    const toggleButtonStyle = (isShown) => isShown ? "danger" : "primary";

    const componentConfigs = [
        { id: 'showAdjudication', label: 'Adjudication', Component: AdjudicationEvent },
        { id: 'showCoaching', label: 'Coaching', Component: CoachingEvent },
        { id: 'showWorkshops', label: 'Workshops', Component: WorkshopEvent },
        // { id: 'showAwards', label: 'Awards', Component: AwardEvent },
        { id: 'showWinners', label: 'Winners Event', Component: WinnersEvent },
        { id: 'showInternships', label: 'Internship', Component: InternEvent },
        { id: 'showSpecialPerformanceEvent', label: 'Special Performance Event', Component: SpecialPerformanceEvent },
        { id: 'showAwardsEventUHSMTA', label: 'Awards Event UHSMTA', Component: AwardsEventUHSMTA },




        // Add more configurations as needed
      ];
      

      const toggleShow = (setter) => {
        setter(prev => !prev);
      };

    return (
        <div>
            <NavbarComponent/>
            <div className="management-button-list">
            <DropdownButton id="dropdown-basic-button" title={"Event Types"}>
                    <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showAdjudication)} onClick={() => setShowAdjudication(!showAdjudication)}>Adjudication</Dropdown.Item>
                    <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showCoaching)} onClick={() => setShowCoaching(!showCoaching)}>Coaching</Dropdown.Item>
                    <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showWorkshops)} onClick={() => setShowWorkshops(!showWorkshops)}>Workshops</Dropdown.Item>
                    <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showSpecialPerformanceEvent)} onClick={() => setShowSpecialPerformanceEvent(!showSpecialPerformanceEvent)}>Special Performance Event</Dropdown.Item>
                    {/* <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showAwardsEventUHSMTA)} onClick={() => setShowAwardsEventUHSMTA(!showAwards)}>Award Shows</Dropdown.Item> */}
                    <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showWinners)} onClick={() => setShowWinners(!showWinners)}>Winners Event</Dropdown.Item>
                    <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showInternships)} onClick={() => setShowInternships(!showInternships)}>Interns</Dropdown.Item>
                    <Dropdown.Item href="#/action-1" variant={toggleButtonStyle(showAwardsEventUHSMTA)} onClick={() => setShowAwardsEventUHSMTA(!showAwardsEventUHSMTA)}>AwardsEventUHSMTA</Dropdown.Item>
            </DropdownButton>

        </div>
        {componentConfigs.map(({ id, label, Component }) => {
          const isVisible = eval(id); // Directly access the state based on id
          return isVisible ? (
            <Button key={id} variant="warning" onClick={() => toggleShow(eval(`set${id.charAt(0).toUpperCase() + id.slice(1)}`))}>
              Hide {label}
            </Button>
          ) : null;
        })}
        {/* Dynamically generated visibility toggle buttons */}

        {componentConfigs.map(({ id, Component }) => {
        const isVisible = eval(id);
        return isVisible ? <Component key={id} /> : null;
      })}
        </div>
    )
};

export default UHSMTAMainPage;
