import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Form, Button, Modal, Table, InputGroup, Alert, ModalDialog } from 'react-bootstrap';
import { useAuth } from "../store/AuthContext";
import { backendURL } from '../IPaddress';
import '../CSS/CRUDfiles.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ErrorModalMessage from '../store/ErrorMessage';




function NewAccountForm() {
  const [formData, setFormData] = useState({
    username: '', 
    password: '',
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    employee_id: '',
    type_id: '',
    drama_mentor: false,
    art_mentor: false,
    music_mentor: false
  });
  const [appUsers, setAppUsers] = useState([]);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userTypes, setUserTypes] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submittedData, setSubmittedData ] = useState({});
  const {authState} = useAuth();
  const [showForm, setShowForm] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const navigate = useNavigate();
  const [ searchTerm, setSearchTerm ] = useState('');
  const [updatePassForm, setUpdatePassForm ] = useState(false);
  const [teacherStatuses, setTeacherStatuses] = useState([]);
  const [ showTeacherStatuses, setShowTeacherStatuses] = useState();
  const [ errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (!authState) {
        navigate('/login');
    }
    // Dependency array includes authState to react to its changes
}, [authState, navigate]);

  useEffect (() => {
    fetchType_id();
    fetchAppUsers();
    fetchTeacherStatuses();
  },
  []);
  
  

const filteredUsers = searchTerm ?
appUsers.filter(
 user => `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm)
 ) : appUsers;

const fetchAppUsers = async () => {
  try {
    const response = await fetch(`${backendURL}/api/app-users`, {
      method: 'GET',
      credentials: 'include'
    });
    const data = await response.json();
    setAppUsers(data);
  } catch (error) {
    setErrorMessage('Error fetching users');

  }
};

const fetchTeacherStatuses = async () => {
  try {
    const response = await fetch(`${backendURL}/api/teacherstatuses`, {
      method: 'GET',
      credentials: 'include'
    });
    let data = await response.json();

    // Sort the data by teacher_status_id in ascending order
    data.sort((a, b) => a.teacher_status_id - b.teacher_status_id);

    setTeacherStatuses(data);
  } catch (error) {
    setErrorMessage('Error fetching teacher statuses');
  }
};


const fetchType_id = async () => {
  const url = `${backendURL}/api/usertypes`;
  const response = await fetch(url, {
      method: 'GET', 
      credentials: 'include',
  })
  if (response.ok) {
      const data = await response.json();
      setUserTypes(data);
  } else {
      setErrorMessage("the fetch at user type_id failed");
  }
}

const handleChange = (e) => {
  const { name, type, value, checked } = e.target;

  // Determine whether the input is a checkbox or not
  const inputValue = type === 'checkbox' ? checked : value;
  
  setFormData(prevFormData => ({
      ...prevFormData,
      [name]: inputValue
  }));

  // If either the password or confirmPassword fields are updated,
  // clear the password error message.
  if (name === 'password' || name === 'confirmPassword') {
      setPasswordError('');
  }
  // Assuming 'teacherTypeId' is the value of 'type_id' that corresponds to "Teacher"
  const teacherTypeId = '1'; // Replace 'theTypeIdForTeacher' with the actual ID for "Teacher"
  if (name === 'type_id' && value === teacherTypeId) {
    setShowTeacherStatuses(true);
  } else if (name === 'type_id') {
    setShowTeacherStatuses(false);
  }
};


  const handlePasswordChange = (e) => {
    handleChange(e); // This updates the state for the password or confirmPassword

    // Check if passwords match only if the confirmPassword has been entered.
    if (confirmPassword && e.target.name === 'password') {
      if (e.target.value !== confirmPassword) {
        setPasswordError('Passwords do not match');
      } else {
        setPasswordError('');
      }
    }
  };
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);

    // Check if passwords match only if the password has been entered.
    if (formData.password) {
      if (formData.password !== e.target.value) {
        setPasswordError('Passwords do not match');
      } else {
        setPasswordError('');
      }
    }
  };

  const deleteUser = async () => {

    try {
      await fetch(`${backendURL}/api/app-users/${userToDelete}`, {
        method: 'DELETE',
        credentials: 'include'
      });
      setShowModal(false);
      fetchAppUsers();
    } catch (error) {
      setErrorMessage('Error deleting user');
    }
  };

const editUser = (user) => {
  // Exclude the password field from the user object
  const { password, ...userWithoutPassword } = user;
  
  setEditForm(true);

  // Combine both state updates into a single call to setFormData
  setFormData(prevFormData => ({
    ...prevFormData,
    ...userWithoutPassword,
    password: '',
    confirmPassword: ''
  }));

  setShowForm(true);
};

const updatePassword = (user) => {
  // Assuming user contains the new password and confirmPassword

  setFormData({
    ...formData,
    user_id: user.user_id,
    password: '',
    confirmPassword: ''
  });


 setUpdatePassForm(true);
 setShowForm(true);
};

  const handleCancel = () => {
    setFormData({/*setting back to initial state */})
    setShowForm(false);
    setEditForm(false);
    setUpdatePassForm(false);
    setErrorMessage('');
    setConfirmPassword('');
  }
  const findTypeName = (e) => {
    const type = userTypes.find(t => t.type_id === e);
    return type ? type.type : `Unknown`;
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (passwordError.length > 0) {
      return setErrorMessage("Passwords do not match");
    }
  
    const preparedFormData = {
      ...formData,
      type_id: parseInt(formData.type_id, 10),
      phone_number: parseInt(formData.phone_number, 10),
      zip: parseInt(formData.zip, 10),
    };
  
    let endpoint = `${backendURL}/api/app-users`; // Default endpoint
    let method = 'POST'; // Default method
  
    // Adjust endpoint and method based on the form state
    if (updatePassForm) {
      endpoint = `${backendURL}/api/app-users/password/${formData.user_id}`;
      method = 'PUT';
    } else if (editForm) {
      endpoint = `${backendURL}/api/app-users/${formData.user_id}`;
      method = 'PUT';
    } else if (formData.type_id > 2) {
      endpoint = `${backendURL}/api/admin-users`;
    }
  
    try {
      const response = await fetch(endpoint, {
        method: method,
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify(preparedFormData),
      });
  
      if (response.ok) {
        // Reset form for all successful operations
        setFormData({
          username: '', 
          password: '',
          confirmPassword: '',
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          street: '',
          city: '',
          state: '',
          zip: '',
          employee_id: '',
          type_id: '', // Assuming this is the correct initial state for type_id
          drama_mentor: false,
          art_mentor: false,
          music_mentor: false
        });
        setConfirmPassword('');
        setShowForm(false);
        setUpdatePassForm(false);
        setEditForm(false);
        fetchAppUsers();
        setSubmitSuccess(true);
      } else {
        // Handle non-OK responses
        const errorData = await response.json();
        setErrorMessage(errorData.error || errorData.detail || "An error occurred");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrorMessage("Network error, please try again later.");
    }
  };
  

  const getTeacherStatus = (userTypeId) => {
    const statusObj = teacherStatuses.find(status => status.teacher_status_id === userTypeId)
    if (statusObj) {
      return `${statusObj.status}`
    }
  }


  return (
    <div>
      <Button onClick={() => setShowForm(!showForm)}>
        {showForm ? 'Show Users List' : 'Add New User'}
      </Button>
      {!showForm && (<Form.Control
        type="text"
        placeholder="Search by Name"
        onChange={handleSearchChange}
        value={searchTerm}
        className="my-3"
        />)}
    {showForm ? (
    <div className="center-form">
    <div>
      {updatePassForm ? <h2>Update Password</h2> : (!editForm ? <h2>Creating New Account</h2> : <h2>Updating Account</h2>)}
    </div>

      <Form onSubmit={handleSubmit} className="my-form">
      {!updatePassForm ? 
      <div>
        <Form.Group controlId="username">
          <Form.Label className="bold-label">Username</Form.Label>
          <Form.Control 
              type="text"
              placeholder="Enter a username"
              name="username"
              onChange={handleChange}
              value={formData.username}
              required
          />
        </Form.Group> 
        <Form.Group controlId="first_name">
          <Form.Label className="bold-label">First Name</Form.Label>
          <Form.Control 
              type="text"
              placeholder="Enter first name"
              name="first_name"
              onChange={handleChange}
              value={formData.first_name}
              required
          />
        </Form.Group>
        <Form.Group controlId="last_name">
          <Form.Label className="bold-label">Last Name</Form.Label>
          <Form.Control 
              type="text"
              placeholder="Enter last name"
              name="last_name"
              onChange={handleChange}
              value={formData.last_name}
              required
          />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label className="bold-label">Email</Form.Label>
          <Form.Control 
              type="text"
              placeholder="Enter Email"
              name="email"
              onChange={handleChange}
              autoComplete='email'
              value={formData.email}
              required
          />
        </Form.Group>
        <Form.Group controlId="phone_number">
          <Form.Label className="bold-label">Phone number</Form.Label>
          <Form.Control 
              type="text"
              placeholder="Enter Phone number"
              name="phone_number"
              onChange={handleChange}
              value={formData.phone_number}
              required
          />
        </Form.Group>
        <Form.Group controlId="street">
          <Form.Label className="bold-label">Street</Form.Label>
          <Form.Control 
              type="text"
              placeholder="Enter Street"
              name="street"
              onChange={handleChange}
              value={formData.street}
          />
        </Form.Group>
        <Form.Group controlId="city">
          <Form.Label className="bold-label">City</Form.Label>
          <Form.Control 
              type="text"
              placeholder="Enter City"
              name="city"
              onChange={handleChange}
              value={formData.city}
          />
        </Form.Group>
        <Form.Group controlId="state">
          <Form.Label className="bold-label">State</Form.Label>
          <Form.Control 
              type="text"
              placeholder="Enter State"
              name="state"
              onChange={handleChange}
              value={formData.state}
          />
        </Form.Group>
        <Form.Group controlId="zip">
          <Form.Label className="bold-label">Zip</Form.Label>
          <Form.Control 
              type="text"
              placeholder="Enter Zip"
              name="zip"
              onChange={handleChange}
              value={formData.zip}
          />
        </Form.Group>
        <Form.Group controlId="type_id">
          <Form.Label className="bold-label">User Type</Form.Label>
          <Form.Control 
              as="select"
              name="type_id"
              onChange={handleChange}
              value={formData.type_id}
              required
          >
            <option value="">Select User Type</option>
            {Array.isArray(userTypes) && userTypes.map(type_id => (
                <option key={type_id.type_id} value={type_id.type_id}>{type_id.type}</option>
            ))}
          </Form.Control>
        </Form.Group> 
        {showTeacherStatuses ? (
        <Form.Group>
          <Form.Label className="bold-label">Teacher Status</Form.Label>
          <Form.Control
          as={'select'}
          name='teacher_status_id'
          onChange={handleChange}
          value={formData.teacher_status_id}
          >
            <option value={''}>Select Teacher Status</option>
            {teacherStatuses.map(status => (
              <option key={status.teacher_status_id}
               value={status.teacher_status_id}
               >{status.status}</option>
            ))}
          </Form.Control>
        </Form.Group>) : ''}
        {!showTeacherStatuses ? <div>
          <Form.Group controlId="employee_id">
            <Form.Label className="bold-label">employee_id</Form.Label>
            <Form.Control
                type="text"
                placeholder="employee_id"
                name="employee_id"
                onChange={handleChange}
                value={formData.employee_id}
            />
          </Form.Group>
          <Form.Group controlId="drama_mentor">
          <Form.Label className="bold-label">Drama Mentor?</Form.Label>
          <Form.Check
              type="checkbox"
              label={`${formData.first_name} ${formData.last_name} is a drama mentor`}
              name="drama_mentor"
              onChange={handleChange}
              checked={formData.drama_mentor}
          />
          </Form.Group>
          <Form.Group controlId="art_mentor">
            <Form.Label className="bold-label">Art Mentor?</Form.Label>
            <Form.Check
                type="checkbox"
                label={`${formData.first_name} ${formData.last_name} is an art mentor`}
                name="art_mentor"
                onChange={handleChange}
                checked={formData.art_mentor}
            />
          </Form.Group>
          <Form.Group controlId="music_mentor">
            <Form.Label className="bold-label">Music Mentor?</Form.Label>
            <Form.Check
                type="checkbox"
                label={`${formData.first_name} ${formData.last_name} is a music mentor`}
                name="music_mentor"
                onChange={handleChange}
                checked={formData.music_mentor}
            />
          </Form.Group>
        </div> :''}
      </div> : <div></div>}
        {(!editForm || updatePassForm) && 
        <>
          <Form.Group controlId="password">
          <Form.Label className="bold-label">Password</Form.Label>
          <Form.Control 
              type="password"
              placeholder="Enter Password"
              name="password"
              onChange={handlePasswordChange}
              value={formData.password}
              required
          />
        </Form.Group>      
        <Form.Group controlId="confirmPassword">
          <Form.Label className="bold-label">Confirm Password</Form.Label>
          <Form.Control 
              type="password"
              placeholder="Confirm Password"
              name="confirmPassword"
              onChange={handleConfirmPasswordChange}
              value={confirmPassword}
              required
          />
          {passwordError && <div className="password-error">{passwordError}</div>}
        </Form.Group>
        </>}

        <Button type="submit" className="m-1">
                  Submit
        </Button>
        <Button variant="secondary" onClick={handleCancel} className="ml-2" >Cancel</Button>
        </Form>
            <Modal show={submitSuccess} onHide={() => setSubmitSuccess(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>User created!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Username: {submittedData.username} <br></br>
                    First and Last Name: {submittedData.first_name} {submittedData.last_name} <br></br>
                    Email: {submittedData.email} <br></br>
                    Staff Type: {findTypeName(submittedData.type_id)} 
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                      setSubmitSuccess(false)
                      setShowForm(false);
                      }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
    </div>) : (
      <div className="scrollable-table">      
      <Table striped bordered hover>
      <thead>
        <tr>
          <th>User info</th>
          <th>Contact Info</th>
          {/* <th>Phone</th> */}
          <th>Address</th>
          <th>Employee ID</th>
          <th>User Type</th>
          <th>Mentor Type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {filteredUsers.map((user) => (
          <tr key={user.user_id}>
            <td className='user-info-container'>
              <div className='user-info-list'><span className='user-info-content'>{'First: '}</span>{user.first_name}</div>
              <div className='user-info-list'><span className='user-info-content'>{'Last: '}</span>{user.last_name}</div>
              <div className='user-info-list'><span className='user-info-content'>{'User Name: '}</span>{user.username}</div>
            </td>
            <td>
              <div className='user-info-list'><span className='user-info-content'>{'Email: '}</span>{user.email}</div>
              <div className='user-info-list'><span className='user-info-content'>{'Email: '}</span>{user.phone_number}</div>
            </td>
            <td className='user-info-container'>
              <div className='user-info-list' ><span className='user-info-content'>{'Street: '}</span>{user.street}</div>
              <div className='user-info-list' ><span className='user-info-content'>{'City: '}</span>{user.city}</div>
              <div className='user-info-list' ><span className='user-info-content'>{'State: '}</span>{user.state}</div>
              <div className='user-info-list' ><span className='user-info-content'>{'Zip: '}</span>{user.zip}</div>
            </td> 
            <td>{user.employee_id}</td>
            <td>{findTypeName(user.type_id) === 'Teacher' ? `Teacher, ${getTeacherStatus(user.teacher_status_id)}` : `${findTypeName(user.type_id)}`}</td>
            <td>
              <div className='user-info-list' ><span className='user-info-content'>{'Drama: '}</span>{user.drama_mentor ? '\u2713' : ''}</div>
              <div className='user-info-list' ><span className='user-info-content'>{'Art: '}</span>{user.art_mentor ? '\u2713' : ''}</div>
              <div className='user-info-list' ><span className='user-info-content'>{'Music: '}</span>{user.music_mentor ? '\u2713' : ''}</div>              
            </td>
            <td>
              <Button onClick={() => editUser(user)} className="m-1"><i className="bi bi-pencil"></i></Button>
              <Button onClick={() => updatePassword(user)} className="m-1">Update Password</Button>
              <Button variant="danger" onClick={() => {
                setUserToDelete(user.user_id);
                setShowModal(true);
              }} className="m-1"><i className="bi bi-trash3"></i></Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table></div>
    )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={deleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ErrorModalMessage errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage('')} />
    </div>
  );
}

export default NewAccountForm;
