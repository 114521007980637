import React from 'react';
import { Navbar, Button, Nav } from 'react-bootstrap';
import { useAuth } from './store/AuthContext';
import { useUserData } from './store/UserDataContext';
import { useNavigate, NavLink } from 'react-router-dom';



function NavbarComponent() {
    const { logout } = useAuth();
    const { userData } = useUserData();
    const navigate = useNavigate();

    // console.log('user data: ', userData?.type_id);
    
    const handleLogout = async () => {
        await logout();        
        navigate('/login');
    };

    const ConditionalNavLink = () => {// user type 5 is for Admin and 2 is for guest. This will need to be updated later. 
        if (userData && (userData.type_id === 5 || userData.type_id === 2 )) {
            return (
                <div >
                    <Nav>
                        <NavLink
                            to="/management"
                            className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}
                        >
                            Management
                        </NavLink>
                        <NavLink
                        to='/reporting'
                        className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}
                        >
                            Reporting
                        </NavLink>
                    </Nav>
                </div>
            );
        }
        return null;
    };

    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand href="#home">UFOMT</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                <Nav>
                    <NavLink to="/main" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>
                        Main
                    </NavLink>
                    <NavLink to="/formsubmission" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>
                        Form Submission
                    </NavLink>
                    {ConditionalNavLink()}
                    <NavLink to="/ticket-office-main" className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>
                        Ticket Office Main
                    </NavLink>
                    <NavLink to={'/uhsmtaMain'} className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>
                        UHSMTA Main
                    </NavLink>
                </Nav>
                <Button variant="outline-dark" onClick={handleLogout}>Logout</Button>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default NavbarComponent;
