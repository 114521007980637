import React, { useEffect, useState, useSyncExternalStore } from "react";
import NavbarComponent from "../Nav";
import { useAuth } from "../store/AuthContext";
import { Navigate, useNavigate } from "react-router-dom";
import ErrorModalMessage from "../store/ErrorMessage";
import { backendURL } from "../IPaddress";
import { Button, Modal, Form, Table, Dropdown} from "react-bootstrap";
import Select from 'react-select';
import { useSchoolYear } from "../store/SchoolYearCalculator";


const WorkshopEvent = () => {
    const { authState } = useAuth();
    const navigate = useNavigate();
    const [ errorMessage, setErrorMessage] = useState(null);
    const [ eventPrograms, setEventPrograms ] = useState([]);
    const [ eventDates, setEventDates ] = useState([]);
    const [ eventTypes, setEventTypes ] = useState([]);
    // const [ classrooms, setClassrooms ] = useState([]);
    const [ adjudicationTypes, setAdjudicationTypes ] = useState([]);
    const [ currentProgram, setCurrentProgram ] = useState({
        event_id: null,
        event_template_id: null,
        name: '',
        description: '',
        location_id: null,
        coordinator_id: null,
        event_type_id: null,
        classroom_id: null,
        teacher_profile_id: null,
        judge_1_id: null,
        judge_2_id: null,
        school_id: null,
        district_id: null,
        students_in_cast: null,
        double_cast: false,
        written_report_submitted: false,
        is_one_on_one: null,
        number_of_students: null,
        number_of_teachers: null,
        number_of_events: null,
        adjudication_type_id: null,
        max_free_seats: null
    });
    const [ currentEventDate, setCurrentEventDate] = useState({
        event_dates_id: null,
        event_id: null,
        date: null,
        ticket_price: null,
        start_time: null,
        end_time: null,
        judge_attendance_date: null,
        date_adjudicated: null,
        rehearsal_begin_time: null,
        rehearsal_end_time: null,
        performance_begin_time: null,
        performance_end_time: null
    });
    const [ awardEvents, setAwardEvents ] = useState([]);
    const [ workshopModal, setWorkshopModal ] = useState(false);
    const [ schools, setSchools] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [ teachers, setTeachers ] = useState([]);
    const [ judges, setJudges ] = useState([]);
    const [programToDelete, setProgramToDelete] = useState({});
    const [eventDateToDelete, setEventDateToDelete] = useState({});
    const [eventDateSchoolToDelete, setEventDateSchoolToDelete] = useState({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [eventDateSchools, setEventDateSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState([]);
    const [selectedSchools, setSelectedSchools] = useState([]);
    const [schoolYearList, setSchoolYearList] = useState([]);
    const [selectedSchoolYear, setSelectedSchoolYear] = useState();
    const {currentYear, schoolYearListParent} = useSchoolYear();


    useEffect(() => {
        if(!authState){
            navigate('/login')
        }
    }, [authState, navigate]);
    
    useEffect(() => {
        if (currentYear && schoolYearListParent.length) {
          setSelectedSchoolYear(currentYear);
          setSchoolYearList(schoolYearListParent);
        }
    }, [currentYear, schoolYearListParent]);
      
    useEffect(() => {
        if (selectedSchoolYear) {
            fetchEventPrograms();
            fetchEventDates();
            fetchEventDateSchools();
        }
    }, [selectedSchoolYear]);

    useEffect(() => {
        fetchEventTypes();
        fetchAdjudicationTypes();
        // fetchClassrooms();
        fetchSchools();
        fetchDistricts();
        fetchProfiles();
        
        
    }, []);


    const fetchEventPrograms = async () => {
        const response = await fetch(`${backendURL}/api/events-programs/year/${selectedSchoolYear}`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok){
            const data = await response.json();
            setEventPrograms(data)
            filterWorkshopEvents(data);
        } else {
            setErrorMessage('Failed to retrieve event program information');
            return;
        }
    };
    const fetchEventDateSchools = async () => {
        const response = await fetch(`${backendURL}/api/event-date-schools/year/${selectedSchoolYear}`, {
            method: 'GET',
            credentials: 'include'
        });
        if (response.ok) {
            const data = await response.json();
            // console.log("fetched date schools: ", data)
            setEventDateSchools(data)
        } else{
            setErrorMessage('Error fetching the event dates and schools list.')
        }
    }
    const fetchEventDates = async () => {
        const response = await fetch(`${backendURL}/api/event-dates/year/${selectedSchoolYear}`, {
            method: 'GET',
            credentials: 'include'
        });
        if (response.ok){
            const data = await response.json();
            const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
      setEventDates(sortedData);
        } else {
            setErrorMessage('Failed to retrieve the event date information');
            return;
        };
    };
    const fetchEventTypes = async () => {
        const response = await fetch(`${backendURL}/api/event-types`, {
            method: 'GET',
            credentials: 'include'
        });
        if (response.ok) {
            const data = await response.json();
            setEventTypes(data);
        } else {
            setErrorMessage('Failed to retrieve the event type data.');
            return;
        }
    };
    const fetchAdjudicationTypes = async () => {
        const response = await fetch(`${backendURL}/api/adjudication-types`, {
            method: 'GET',
            credentials: 'include'
        })
        if (response.ok) {
            const data = await response.json();
            setAdjudicationTypes(data);
        }
    };
    // const fetchClassrooms = async () => {
    //     const response = await fetch(`${backendURL}/api/classrooms`, {
    //       method: 'GET',
    //       credentials: 'include'
    //     });
    //     const data = await response.json();
    //     setClassrooms(data);
    // };
    const fetchSchools = async () => {
    // setLoading(prev => ({ ...prev, schools: true }));
    const response = await fetch(`${backendURL}/api/schools`);
    
    if (!response.ok) {
        setErrorMessage('Unable to retrieve school information');
    };
    const data = await response.json();
    
    if (Array.isArray(data)) {
        setSchools(data);

    } else {
        setErrorMessage('Fetched Schools data is not an array:', data);
    }};
    const fetchDistricts = async () => {
        const url = `${backendURL}/api/districts`;
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          setDistricts(data);
        } else {
          setErrorMessage('Unable to retrieve districts');
        }
    };
    const fetchProfiles = async() => {
        const response = await fetch(`${backendURL}/api/profiles`, {
          method:'GET',
          credentials: 'include'
        });
        if (!response.ok) {
          setErrorMessage('Unable to fetch profiles.')
        }
        const data = await response.json();
        const teacherProfiles = data.filter(profile => profile.teacher_status === true);
        const judgeProfiles = data.filter(profile => profile.judge_status === true);
        setTeachers(teacherProfiles);
        setJudges(judgeProfiles);
    };
    
    const filterWorkshopEvents = (programs) => {
        const filteredCoaching = (programs.filter( program => program.event_type_id === 7));
        if (filteredCoaching){
            setAwardEvents(filteredCoaching);
        } else {
            setAwardEvents([]);
        }
    }

    const schoolOptions = schools.map(school_id => {
        const district = districts.find(d => d.district_id === school_id.district_id);
        const districtName = district ? district.name : 'Unknown District';
        
        return {
          label: `${school_id.name} (${districtName})`,
          value: school_id.school_id
        };
      });



    const handleProgramChange = (field, value, type = 'text') => {

        if(type === 'adjudication_type_id' || type==='students_in_cast'|| type === 'judge_1_id' || type === 'judge_2_id' || type === 'teacher_profile_id') {
            setCurrentProgram(prevState => ({
                ...prevState, 
                [field]:parseInt(value, 10) || 0,
                school_year_id: parseInt(selectedSchoolYear, 10),
            }));
        } else {
            setCurrentProgram(prevState => ({
                ...prevState,
                [field]: type === 'checkbox' ? value.checked : value,
                school_year_id: parseInt(selectedSchoolYear, 10),
            }));
        }
    };


    const handleEventDateChange = (field, value, type = 'text') => {
        setCurrentEventDate(prevState => ({
            ...prevState,
            [field]: type === 'checkbox' ? value.checked : value,
            school_year_id: parseInt(selectedSchoolYear, 10),
        }));
    };

    const handleSchoolChange = selectedOptions => {
        // Update the state with the new array of selected options
        setSelectedSchools(selectedOptions);
    
        // Build an array of school IDs from the selected options
        const schoolIds = selectedOptions.map(option => parseInt(option.value, 10)).filter(id => !isNaN(id));
    
        // Optional: Log warnings for any IDs that couldn't be parsed
        selectedOptions.forEach(option => {
            const id = parseInt(option.value, 10);
            if (isNaN(id)) {
                console.warn(`Warning: could not parse school_id value "${option.value}" as an integer`);
            }
        });
        // console.log('school ids: ', schoolIds)
    
        // Update the currentProgram state with the new list of school IDs
        setSelectedSchool(schoolIds);
    };
    
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        // need an if statement to handle selecting the same judge for both fields.

        if (selectedSchool.length === 0) {
            setErrorMessage("School selection cannot be empty.");
            return;
        }
    
        if (currentEventDate.date == null) {
            setErrorMessage("Date cannot be empty.");
            return;
        }
        

        // const schoolIds = selectedSchool.map(school => school.value)

        if (!currentProgram.event_id){
            await createWorkshop(currentProgram, currentEventDate, selectedSchool);
        };
        if (currentProgram.event_id){
            await updateWorkshop(currentProgram, currentEventDate, selectedSchool);
        }
        setCurrentProgram({
            event_id: null,
            event_template_id: null,
            name: '',
            description: '',
            location_id: null,
            coordinator_id: null,
            event_type_id: null,
            classroom_id: null,
            teacher_profile_id: null,
            judge_1_id: null,
            judge_2_id: null,
            school_id: null,
            district_id: null,
            students_in_cast: null,
            double_cast: false,
            written_report_submitted: false,
            is_one_on_one: null,
            number_of_students: null,
            number_of_teachers: null,
            number_of_events: null,
            adjudication_type_id: null,
            max_free_seats: null
        });
        setCurrentEventDate({
            event_dates_id: null,
            event_id: null,
            date: null,
            ticket_price: null,
            start_time: null,
            end_time: null,
            judge_attendance_date: null,
            date_adjudicated: null,
            rehearsal_begin_time: null,
            rehearsal_end_time: null,
            performance_begin_time: null,
            performance_end_time: null
        });
        fetchEventPrograms();
        fetchEventDates();
        fetchEventDateSchools();
    };

    const editEventProgram = async (programEvent, eventDateObj) => {
        const eventDateSchoolsObj = eventDateSchools.filter(
            eventDateSchool => eventDateSchool.event_date_id === eventDateObj.event_dates_id
        );
    
        // Log the linked schools for debugging
        // console.log('event date school obj: ', eventDateSchoolsObj);
    
        // Map the eventDateSchoolsObj to get an array of school objects based on the school_ids linked with the event date
        const listOfSchools = eventDateSchoolsObj.flatMap(eventDateSchool =>
            schoolOptions.filter(schoolOption => eventDateSchool.school_id.includes(schoolOption.value))
        );
    
        // If there is a corresponding school object, set it as selected
        if (listOfSchools.length) {
            setSelectedSchools(listOfSchools);
            setSelectedSchool(listOfSchools);
        } else {
            // Clear the selection if no schools are linked (fallback scenario)
            console.log("schools failed the length test")
            setSelectedSchools([]);
            setSelectedSchool([]);
        }
    
        // Set the current program and event date for editing
        setCurrentProgram({...programEvent});
        setCurrentEventDate(eventDateObj);
        setWorkshopModal(true);
    };
    
    const createWorkshop = async (programData, eventDateData, schoolIds) => {
        setCurrentEventDate(prevState => ({
            ...prevState,
            school_year_id: parseInt(selectedSchoolYear, 10),
        }));

        try {
            const programResponse = await fetch(`${backendURL}/api/events-programs`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(programData),
                credentials: 'include'
            });
    
            if (!programResponse.ok) {
                throw new Error('Failed to create program event');
            }
    
            const programDataEvent = await programResponse.json();
    
            if (programDataEvent && programDataEvent.event_id) {
                eventDateData.event_id = programDataEvent.event_id; // Set the event_id for the event date
    
                const dateResponse = await fetch(`${backendURL}/api/event-dates`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(eventDateData),
                    credentials: 'include'
                });
    
                if (!dateResponse.ok) {
                    throw new Error('Failed to create event date for the related program.');
                }
                const newDateResponse = await dateResponse.json();
    
                const payload = {
                    event_date_id: newDateResponse.event_dates_id,
                    school_id: schoolIds,  // Use 'school_id' as the key for the array of IDs
                    school_year_id: selectedSchoolYear
                };

    
                const eventDateSchoolResponse = await fetch(`${backendURL}/api/event-date-schools`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(payload),
                    credentials: 'include'
                });
                if (!eventDateSchoolResponse.ok) {
                    throw new Error('Failed to create event date school for the event and program.');
                }
    
                handleCancel();
                fetchEventPrograms();
                fetchEventDates();
                return { programData, newDateResponse }; // Return both data objects if needed
            } else {
                throw new Error('Missing event_id from the created events program.');
            }
        } catch (error) {
            setErrorMessage(error.message);
            console.error('Error:', error);
            return null; // Return null or appropriate value indicating failure
        }
    };
    
    

    const updateWorkshop = async (programData, eventDateData, schoolIds) => {
        try {
            const response = await fetch(`${backendURL}/api/events-programs/${programData.event_id}`, {
                method: 'PUT',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(programData)
            });
            const response2 = await fetch(`${backendURL}/api/event-dates/${eventDateData.event_dates_id}`, {
                method: 'PUT',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(eventDateData)
            });

            const payload = {
                event_date_id: eventDateData.event_dates_id,
                school_id: schoolIds,
                school_year_id: selectedSchoolYear
            }
            console.log("payload: ", payload)
            const response3 = await fetch(`${backendURL}/api/event-date-schools/${eventDateData.event_dates_id}`, {
                method: 'PUT',
                credentials: 'include',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(payload)
            });
            handleCancel();
            
        }
        catch {
            setErrorMessage("Something broke while trying to update the program and event date")
        }
    }

    const deleteWorkshop = async () => {

        try {
            await fetch(`${backendURL}/api/event-date-schools/${eventDateToDelete.event_dates_id}`, {
                method: 'DELETE',
                credentials: 'include'
            });    
            await fetch(`${backendURL}/api/event-dates/${eventDateToDelete.event_dates_id}`, {
                method: 'DELETE',
                credentials: 'include'
            });
            await fetch(`${backendURL}/api/events-programs/${programToDelete.event_id}`, {
                method: 'DELETE',
                credentials: 'include'
            });   
                  
            fetchEventPrograms();
            fetchEventDates();
            handleCancel();
        }
        catch {
            setErrorMessage('There was an error deleting the program event or event date.')
        }
    }


    const handleCancel = () => {
        setWorkshopModal(false);
        setCurrentProgram({
            event_id: null,
            event_template_id: null,
            name: '',
            description: '',
            location_id: null,
            coordinator_id: null,
            event_type_id: null,
            classroom_id: null,
            teacher_profile_id: null,
            judge_1_id: null,
            judge_2_id: null,
            school_id: null,
            district_id: null,
            students_in_cast: null,
            double_cast: false,
            written_report_submitted: false,
            is_one_on_one: null,
            number_of_students: null,
            number_of_teachers: null,
            number_of_events: null,
            adjudication_type_id: null,
            max_free_seats: null
        });
        setCurrentEventDate({
            event_dates_id: null,
            event_id: null,
            date: null,
            ticket_price: null,
            start_time: null,
            end_time: null,
            judge_attendance_date: null,
            date_adjudicated: null,
            rehearsal_begin_time: null,
            rehearsal_end_time: null,
            performance_begin_time: null,
            performance_end_time: null
        });
        setEventDateToDelete({});
        setProgramToDelete({});
        setSelectedSchools([]);
        setShowDeleteConfirmation(false);
    }

    const getJudgeName = (judgeId) => {
        const judgeObj = judges.find(profile => profile.profile_id === judgeId)
        if (judgeObj) {
            return (
                `${judgeObj.first_name} ${judgeObj.last_name}`
            )
        } else {
            return(
                <></>
            );
        }
    }
    const getSchoolName = (schoolId) => {
        const schoolObj = schoolOptions.find(school => school.value === schoolId[0])
        if (schoolObj) {
            return (
                `${schoolObj.label}`
            )
        } else {
            // console.log("school name didn't load")
            return(
                <></>
            );
        }
    }
    const getSchoolObj = (eventId) => { 
        try {const eventDateObj = getEventDateObj(eventId);

        if (eventDateObj){
        const listOfSchools = eventDateSchools.filter(
            eventDateSchool => eventDateSchool.event_date_id === eventDateObj.event_dates_id
        );
        // console.log("eventDateSchools: ", eventDateSchools)
        return (
            <>{listOfSchools.map(school => <li key={school.school_id}>{getSchoolName(school.school_id)}</li>)}</>
        )}
        }
        catch {
            console.error("failed to get the schoolObj")
        }
    }

    const getEventStartTime = (eventProgramId) => {
        const eventDateObj = eventDates.find(date => date.event_id === eventProgramId);
    
        // Check if the eventDateObj is found and end_time is not null
        if (eventDateObj && eventDateObj.start_time) {
            const timeString = eventDateObj.start_time; // 'HH:mm:ss'
            const [hours, minutes, seconds] = timeString.split(':');
            const date = new Date();
            date.setHours(parseInt(hours, 10), parseInt(minutes, 10), parseInt(seconds, 10));
    
            // Format to 12-hour time with AM/PM
            const formattedTime = date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            });
    
            return <>{formattedTime}</>;
        }
    
        // Return an empty fragment or a suitable placeholder if no valid end_time
        return <>No start time set</>;
    };
    
    const getEventEndTime = (eventProgramId) => {
        const eventDateObj = eventDates.find(date => date.event_id === eventProgramId);
    
        // Check if the eventDateObj is found and end_time is not null
        if (eventDateObj && eventDateObj.end_time) {
            const timeString = eventDateObj.end_time; // 'HH:mm:ss'
            const [hours, minutes, seconds] = timeString.split(':');
            const date = new Date();
            date.setHours(parseInt(hours, 10), parseInt(minutes, 10), parseInt(seconds, 10));
    
            // Format to 12-hour time with AM/PM
            const formattedTime = date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
            });
    
            return <>{formattedTime}</>;
        }
    
        // Return an empty fragment or a suitable placeholder if no valid end_time
        return <>No end time set</>;
    };
    

    const getEventDate = (eventProgramId) => {
        const eventDateObj = eventDates.find(date => date.event_id === eventProgramId);
        if (eventDateObj) {
            return (eventDateObj.date);
        };
    };
    const getEventDateObj = (eventProgramId) => {
        const eventDateObj = eventDates.find(date => date.event_id === eventProgramId);
        if (eventDateObj) {
            return (eventDateObj);
        };
    };
    const getEventSchoolObj = (eventDateId) => {
        const eventSchoolObj = getEventDateObj(eventDateId)

        const eventDateSchoolsObj = eventDateSchools.filter(
            eventDateSchool => eventDateSchool.event_date_id === eventSchoolObj.event_dates_id
        );
        if (eventDateSchoolsObj){
            return eventDateSchoolsObj;
        }
    }

    const handleYearChange = (event) => {
        setSelectedSchoolYear(event.target.value);
      };

    const AwardEventsComponent = () => {
        
        return (
            <div>
                <Button onClick={() => 
                    {setWorkshopModal(true)
                    setCurrentProgram(prevState => ({
                        ...prevState,
                        'event_type_id': 7 // D. UHSMTA Awards Show
                    }))}
                    }>
                Record Workshop    
                </Button>
                <div>
                    <Dropdown>
                        <span className='fw-bold'>Select School Year</span>
                    <select value={selectedSchoolYear} onChange={handleYearChange}>
                        <option value="" disabled>Select School Year</option>
                        {schoolYearList.map((year) => (
                            <option key={year.id} value={year.id}>
                            {year.school_year}
                            </option>
                        ))}
                        </select>
                    </Dropdown>
                </div>

                <h4>Workshops List</h4>
            <div>
    {awardEvents && awardEvents.length > 0 ? (
        <div className="scrollable-table">
            
            <Table striped bordered hover>
                <thead>
                    <tr>
                        {/* <th>Event Type</th> */}
                        {/* <th>Description</th> */}
                        <th>Schools</th>
                        <th>Date of Workshop</th>
                        <th>Number of Students</th>
                        <th>Number of Teachers</th>
                        <th>Begin Time</th>
                        <th>End Time</th> 
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {awardEvents.map(event => (
                        <tr key={event.event_id}>{/* Make sure each event has a unique `event_id` or similar identifier */}
                            <td>{getSchoolObj(event.event_id)}</td>
                            <td>{getEventDate(event.event_id)}</td>
                            <td>{event.number_of_students}</td>
                            <td>{event.number_of_teachers}</td>
                            <td>{getEventStartTime(event.event_id)}</td>
                            <td>{getEventEndTime(event.event_id)}</td>
                            <td>
                                <Button className="m-1" onClick={() => editEventProgram(event, getEventDateObj(event.event_id))}><i className="bi bi-pencil"></i></Button>
                                <Button variant="danger" className="m-1" onClick={() => {
                                    setProgramToDelete(event);
                                    setEventDateToDelete(getEventDateObj(event.event_id));
                                    setEventDateSchoolToDelete(getEventSchoolObj(event.event_id))
                                    setShowDeleteConfirmation(true);
                                    }}><i className="bi bi-trash3"></i></Button> 
                            </td>

                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    ) : 'No Workshop reports submitted.'}
</div>

            </div>
        )
    }

    return (
        <div>
            <div>
                <AwardEventsComponent/>
            </div>
            <Modal show={workshopModal} onHide={handleCancel}>
                <Modal.Header closeButton>
                <Modal.Title>{currentProgram.event_id ? 'Update Workshop' : 'Create Workshop'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {/* School */}
                        <Form.Group controlId="schoolId">
                            <Form.Label>School(s)</Form.Label>
                            <Select
                                isMulti
                                isSearchable={true}
                                options={schools.map(school => ({ value: school.school_id, label: school.name }))}
                                value={selectedSchools}
                                onChange={handleSchoolChange}
                                classNamePrefix="select"
                            />
                        </Form.Group>

                        {/* Number of students in cast */}
                        <Form.Group controlId="numberOfStudents">
                            <Form.Label>Number of Students</Form.Label>
                            <Form.Control
                            type="number"
                            value={currentProgram.number_of_students || ''}
                            onChange={e => handleProgramChange('number_of_students', e.target.value, 'students_in_cast')}
                            />
                        </Form.Group>
                        {/* Teacher's contact info */}
                        <Form.Group controlId="numberOfTeachers">
                            <Form.Label>Number of Teachers</Form.Label>
                            <Form.Control 
                            type="number"
                            value={currentProgram.number_of_teachers || ''}
                            onChange={e => handleProgramChange('number_of_teachers', e.target.value, 'number_of_teachers')}
                            >
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="eventDate">
                            <Form.Label>Date</Form.Label>
                            <Form.Control
                            type="date"
                            value={currentEventDate.date || ''}
                            onChange={e => handleEventDateChange('date', e.target.value)}
                            required
                            />
                        </Form.Group>
                        <Form.Group controlId="startTime">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control
                            type="time"
                            value={currentEventDate.start_time}
                            onChange={e => handleEventDateChange('start_time', e.target.value)}>
                            </Form.Control>
                        </Form.Group>
                            <Form.Group controlId="endTime">
                                <Form.Label>End Time</Form.Label>
                                <Form.Control
                                type="time"
                                value={currentEventDate.end_time}
                                onChange={e => handleEventDateChange('end_time', e.target.value)}>
                                </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSubmit}>{currentProgram.event_id ? 'Update' : 'Create'}</Button>
                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                    {/* {currentProgram.event_id ? <Button variant="danger">Delete</Button> : ''} */}
                </Modal.Footer>
                </Modal>
                <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
                    <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this award event for {getSchoolObj(programToDelete.event_id)}?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={deleteWorkshop}>
                        Delete
                    </Button>
                    </Modal.Footer>
                </Modal>
            <ErrorModalMessage errorMessage={errorMessage} clearErrorMessage={() => setErrorMessage('')}></ErrorModalMessage>
        </div>
    );
}

export default WorkshopEvent;
